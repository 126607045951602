import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

// Main set of 7 images
const imagesSet1 = [
  require('../assets/animations/redecorate/redecorate_001.webp'),
  require('../assets/animations/redecorate/redecorate_002.webp'),
  require('../assets/animations/redecorate/redecorate_003.webp'),
  require('../assets/animations/redecorate/redecorate_004.webp'),
  require('../assets/animations/redecorate/redecorate_005.webp'),
  require('../assets/animations/redecorate/redecorate_006.webp'),
  require('../assets/animations/redecorate/redecorate_007.webp') // Added 7th image
];

// Constants for the animation
const animationDuration1 = 15; // Adjusted for 7 images in total
const fadeOutDuration1 = 1;
const initialXPosition1 = '0%';
const finalXPosition1 = '-0%';
const imageScale1 = 1;

const MorphingImageRedecorate = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Cycle through the images in `imagesSet1`
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imagesSet1.length);
    }, 2000); // Display each image for 2 seconds before switching to the next

    return () => clearInterval(interval);
  }, []);

  const nextImageIndex = (currentImageIndex + 1) % imagesSet1.length;

  return (
    <div style={{ position: 'relative', width: '600px', height: '600px', margin: '0 auto', overflow: 'hidden' }}>
      {/* Square container */}
      <motion.div
        initial={{ x: initialXPosition1, scale: imageScale1 }}
        animate={{ x: finalXPosition1 }}
        transition={{ duration: animationDuration1, ease: 'linear' }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {imagesSet1.map((image, index) => (
          <motion.div
            key={index}
            initial={{ opacity: index === 0 ? 1 : 0 }}
            animate={{
              opacity: currentImageIndex === index ? 1 : 0,
            }}
            transition={{
              opacity: { duration: fadeOutDuration1 },
            }}
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        ))}
      </motion.div>
    </div>
  );
};

export default MorphingImageRedecorate;
