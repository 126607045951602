import React, { useContext, useState, useRef, useEffect } from "react";
import LogoArcseed from "../assets/logo ArcseedAI.webp";
import { Link, useNavigate } from "react-router-dom";
import {
  FaLinkedin,
  FaFacebook,
  FaDiscord,
  FaInstagram,
  FaTwitter,
  FaYoutube
} from "react-icons/fa";
import LightFooter from "./Footer.js";
import axios from "axios";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import { add_Back } from "../address.js";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { Context } from "../App.js";

//teste2

const Footer = (props) => {
  /////////////////////////// INCLUSAO ENVIO DE MENSAGEM PARA EMAIL ///////////////////////////////////////
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [warningMessage, setWarningMessage] = useContext(Context);
  const handleNameChange = (e) => {
    const value = e.target.value;
    if (!/^[a-zA-Zà-úÀ-Ú ]*$/.test(value)) {
      // setNameErrorMessage("O nome só pode conter letras e espaços.");
     setWarningMessage("The name can only contain letters and spaces.");
    } else {
      setName(value.slice(0, 60));
      setNameErrorMessage("");
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setPhone(value.slice(0, 15));
      setPhoneErrorMessage("");
    } else {
      setPhoneErrorMessage("O número de telefone só pode conter dígitos.");
    }
  };

  const verificarToken = () => {
    const token = Cookies.get("token");
    if (!token) {
      return false;
    }
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp >= currentTime;
    } catch (error) {
      return false;
    }
  };


  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9@._-]*$/.test(value)) {
    setEmail(e.target.value);
    }
  };

  const handleLinkClick = (path) => {
    if (path === "/CommunityGallery" || verificarToken()) {
      navigate(path);
    } else {
      navigate("/Login");
    }
  };

  const [showIconsFlex, setShowIconsFlex] = useState(false);
  useEffect(() => {
      if(blackPartRef.current !== null) {
        if(blackPartRef.current.clientHeight >= 655) {
          setShowIconsFlex(true);
        }
        else {
          setShowIconsFlex(false);
        }
    }
  }, [blackPartRef])

  const handleSubjectChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9à-úÀ-Ú .,?!]*$/.test(value)) {
      setSubject(value.slice(0, 30));
    }
  };
  
  const handleMessageChange = (e) => {
    const value = e.target.value;
    // Permitir apenas letras, números, espaços e pontuações básicas
    if (/^[a-zA-Z0-9à-úÀ-Ú .,?!]*$/.test(value)) {
      setMessage(value.slice(0, 200));
    }
  };

  /////////////////////////// INCLUSAO ENVIO DE MENSAGEM PARA EMAIL ///////////////////////////////////////
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {
      const mensagemTemplate = `
      Nome: ${DOMPurify.sanitize(name)}
      Email: ${DOMPurify.sanitize(email)}
      Telefone: ${DOMPurify.sanitize(phone)}
      Mensagem: ${DOMPurify.sanitize(message)}
    `;

      await axios.post(`${add_Back}/enviarEmail`, {
        assunto: `Contact Us - ${subject}`,
        mensagem: mensagemTemplate,
      });
      setWarningMessage("E-mail sent succesfully!")
    } catch (error) {
      console.error("Erro ao enviar e-mail:", error);
      setWarningMessage("Error while sending e-mail")
    } finally {
      setIsSubmitting(false);
    }
  };

  // const renderMenu

  /////////////////////////// INCLUSAO ENVIO DE MENSAGEM PARA EMAIL ///////////////////////////////////////

  const { t } = useTranslation();
  /////////////////////////// INCLUSAO ENVIO DE MENSAGEM PARA EMAIL ///////////////////////////////////////

  const linkTitles = {
    renderSection: {
      "Sketch 2 Render": {
        link: "/Sketch2Render"
      },
      "Canvas Alchemy": {
        link: "/CanvasAlchemy"
      },
      "Brainstorm Gen": {
        link: "/BrainstormGen"
      },
      "Re-render": {
        link: "/ReRender"
      },
      "Re-decorate (beta)": {
        link: "/Decorate"
      }
    },
    inStudioSection: {

    }
  }

  const blackPartRef = useRef(null);

  if (props.footerVersion === "default-footer") {
    return (
      <footer
        id="default-footer"
        className="flex flex-col lg:flex-row max-w-screen lg:overflow-y-hidden min-w-screen lg:h-screen mt-10"
      >
        <div
          id="white-part"
          className="flex flex-col bg-white lg:max-h-screen      justify-between text-black lg:px-3 px-[4vw] w-screen lg:w-[52vw] lg:h-auto"
        >
          <h2 className="text-black font-title font-bold text-3xl md:text-4xl mt-4 text-left md:text-left">
            ARCSEED AI
          </h2>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
            <div className="text-left">
              <h6 className="font-medium text-nowrap text-black font-title text-2xl">
                {t("RENDER")}
              </h6>
              <ul className="mb-2 text-nowrap">
              {Object.entries(linkTitles.renderSection).map(([index]) => (
                <li key={index} className="text-md">
                  <Link
                    to={linkTitles.renderSection[index].link}
                    className="group font-paragraph leading-6 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    {t(index)}
                    <span className="absolute block max-w-0 group-hover:max-w-full transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                      {t(index)}
                    </span>
                  </Link>
                </li>
              ))}
              </ul>
            </div>
            <div className="md:text-right text-left">
              <h6 className={`font-medium text-nowrap font-title text-black text-2xl
                ${t("IN STUDIO").length > "IN STUDIO".length ? "text-[130%]" : "text-2xl"}`}>
                {t("IN STUDIO")}
              </h6>
              <ul className="mb-4 flex flex-col text-nowrap ">
                <li className="text-md">
                  <Link
                    to="/InstallYourStudio"
                    className="group font-paragraph leading-6 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    {t("Install in your studio")}
                    <span className="absolute block max-w-0 left-0 md:right-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                      {t("Install in your studio")}
                    </span>
                  </Link>
                </li>
                <li className="text-md">
                  <Link
                    to="/MakeYourAi"
                    className="group font-paragraph leading-6 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    {t("Make your AI model")}
                    <span className="absolute block max-w-0 left-0 md:right-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                      {t("Make your AI model")}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="text-left">
              <h6 className="font-medium text-nowrap font-title text-black text-2xl">
                {t("MY ACCOUNT")}
              </h6>
              <ul className="text-nowrap">
                <li className="text-md">
                  <Link
                    to="/Register"
                    className="group font-paragraph leading-6 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    {t("Register")}
                    <span className="absolute block max-w-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                      {t("Register")}
                    </span>
                  </Link>
                </li>
                <li className="text-md">
                  <Link
                    to="/Login"
                    className="group font-paragraph leading-6 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    {t("Login")}
                    <span className="absolute block max-w-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                      {t("Login")}
                    </span>
                  </Link>
                </li>

                <li className="text-md">
                  <span
                    onClick={() => handleLinkClick("/PersonalGallery")}
                    className="group font-paragraph leading-6 text-2xl text-gray-600 transition duration-300 relative cursor-pointer">
                    {t("Personal Gallery")}
                    <span className="absolute block max-w-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                      {t("Personal Gallery")}
                    </span>
                  </span>
                </li>
                <li className="text-md">
                  <Link
                    to="/Dashboard/initial"
                    className="group font-paragraph leading-6 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    Dashboard
                    <span className="absolute block max-w-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                      Dashboard
                    </span>
                  </Link>
                </li>
                <li className="text-md">
                  <Link
                    to="/PlansPage/undefined"
                    className="group font-paragraph leading-6 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    {t("Upgrade Plan")}
                    <span className="absolute block max-w-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                      {t("Upgrade Plan")}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="md:text-right text-left">
              <h6 className="font-medium font-title text-black text-2xl">
                {t("COMMUNITY")}
              </h6>
              <ul className="flex flex-col text-nowrap">
                <li className="text-md">
                  <Link
                  to="https://www.facebook.com/profile.php?id=61552599852965&mibextid=ZbWKwL"
                    className="group font-paragraph leading-5 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    Facebook
                    <span className="absolute block max-w-0 left-0 md:right-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                      Facebook
                    </span>
                  </Link>
                </li>
                <li className="text-md">
                  <Link
                  to="https://discord.gg/quMPjP6cFr"
                    className="group font-paragraph leading-5 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    Discord
                    <span className="absolute block max-w-0 left-0 md:right-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                      Discord
                    </span>
                  </Link>
                </li>
                <li className="text-md">
                  <Link
                  to="https://www.instagram.com/arcseedai?igsh=MnY4eGlrdjYycW8x"
                    className="group font-paragraph leading-5 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    Instagram
                    <span className="absolute block max-w-0 left-0 md:right-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                      Instagram
                    </span>
                  </Link>
                </li>
                <li className="text-md">
                  <Link
                  to="https://x.com/Arcseedai?t=ZHkrUzfHVmidMFDEGFhMfw&s=08"
                    className="group font-paragraph leading-5 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    Twitter
                    <span className="absolute block max-w-0 left-0 md:right-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                     Twitter
                    </span>
                  </Link>
                </li>
                <li className="text-md">
                  <Link
                  to="https://br.linkedin.com/in/arcseed-ai-6335a3294"
                    className="group font-paragraph leading-5 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    Linkedin
                    <span className="absolute block max-w-0 left-0 md:right-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                     Linkedin
                    </span>
                  </Link>
                </li>

                <li className="text-md">
                  <Link
                  to="https://www.youtube.com/@arcseedai"
                    className="group font-paragraph leading-5 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    Youtube
                    <span className="absolute block max-w-0 left-0 md:right-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                     Youtube
                    </span>
                  </Link>
                </li>

              </ul>
            </div>
            <div className="text-left overflow-hidden">
              <h6 className="font-medium text-nowrap font-title text-black text-2xl">
                INFO
              </h6>
              <ul className="text-nowrap">
                <li className="text-md">
                  <a
                  target="_isblank"
                    href="http://doc.arcseedai.com/"
                    className="group font-paragraph leading-6 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    {t("Documentation")}
                    <span className="absolute block max-w-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5 bg-gray-600">
                      {t("Documentation")}
                    </span>
                  </a>
                </li>
                <li className="text-md">
                  <Link
                    className="group font-paragraph leading-6 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    Blog
                    <span
                      className="absolute block max-w-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5
                  bg-gray-600"
                    >
                      Blog
                    </span>
                  </Link>
                </li>
                <li className="text-md">
                  <Link
                    to="/Dashboard/FAQ"
                    className="group font-paragraph leading-6 text-2xl text-gray-600 transition duration-300 relative"
                  >
                    FAQ
                    <span
                      className="absolute block max-w-0 group-hover:max-w-full max-w- transition-all text-transparent duration-500 h-0.5
                  bg-gray-600"
                    >
                      FAQ
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
              <img
                className="md:w-40 h-auto overflow-hidden 
                lg:-ml-0 bg-transparent md:-mr-3 w-[70%] 
                md:justify-self-end justify-self-center m-[5%]"
                src={LogoArcseed}
                alt="/"
              />
          </div>
          <div className="text-left overflow-hidden hidden mb-0 lg:mb-3 lg:inline">
            <p className=" text-gray-600 font-paragraph text-md">
              ARCSEED AI - Apucarana
            </p>
            <p className="text-gray-600 mb-2 font-paragraph text-md">
              PR - Brazil
            </p>
            <p className="text-gray-600 font-paragraph text-md">
            CNPJ:52.455.369/0001-92
            </p>
          </div>
        </div>

        <div
          ref={blackPartRef}
          id="contact-forms"
          className="bg-black relative flex flex-col w-full lg:w-[52vw] h-screen lg:max-h-screen text-white lg:justify-center lg:items-center"
        >
          <h2 className="font-bold text-3xl md:text-4xl font-title 
          text-left lg:absolute lg:top-3 lg:left-4 lg:mt-0 lg:ml-0
          mt-3 ml-4 mb-10">
            {t("CONTACT ARCSEED")}:
          </h2>
          <form
            className="flex flex-col lg:mr-0 lg:pl-3 
            text-left lg:mt-[45px] h-full justify-center lg:h-auto lg:justify-start
            px-[4vw] "
            onSubmit={handleSubmit}
          >
            <div className="mb-2">
              <label htmlFor="name" className="block font-paragraph text-[170%] mb-[1%]">
                {t("Name")}:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={handleNameChange}
                className="w-full white-border-2 text-[170%] -mt-2 font-paragraph border-b-4 font-bold bg-transparent border-white focus:opacity-100 focus:border-b-4 opacity-70 transition ease-in-out duration-300 text-white"
                placeholder={t("Name")}
                required
              />
            </div>
            <div className="mb-2">
              <label htmlFor="email" className="block font-paragraph text-[170%] mb-[1%]">
                Email:
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                className="w-full white-border-2 text-[170%] -mt-2 font-paragraph border-b-4 font-bold bg-transparent border-white focus:opacity-100 focus:border-b-4 opacity-70 transition ease-in-out duration-300 text-white"
                placeholder="E-mail"
                required
              />
            </div>
            <div className="mb-2">
              <label htmlFor="phone" className="block font-paragraph text-[170%] mb-[1%]">
                {t("Mobile Phone Number")}:
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={phone}
                onChange={handlePhoneChange}
                className="w-full white-border-2 text-[170%] -mt-2 font-paragraph border-b-4 font-bold bg-transparent border-white focus:opacity-100 focus:border-b-4 opacity-70 transition ease-in-out duration-300 text-white"
                placeholder="+"
                required
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="subject"
                className="block font-paragraph text-[170%] mb-[1%]"
              >
                {t("Subject")}:
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={subject}
                onChange={handleSubjectChange}
                className="w-full white-border-2 text-[170%] -mt-2 font-paragraph border-b-4 font-bold bg-transparent border-white focus:opacity-100 focus:border-b-4 opacity-70 transition ease-in-out duration-300 text-white"
                placeholder={t("Subject")}
                required
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="message"
                className="block font-paragraph text-[170%] mb-[1%]"
              >
                {t("Message")}:
              </label>
              <textarea
                id="message"
                name="message"
                rows="2"
                value={message}
                onChange={handleMessageChange}
                className="w-full text-[170%] -mt-2 font-paragraph border-b-4 font-bold bg-transparent border-white focus:opacity-100 focus:border-b-4 opacity-70 transition ease-in-out duration-300 text-white"
                placeholder={`${t("Type the message here")}...`}
                required
                style={{ resize: 'none' }}
              />
              <p
                style={{
                  color: "gray",
                  fontSize: "0.75rem",
                  textAlign: "right",
                }}
              >
                {message.length}/200
              </p>
            </div>
            <button
              type="submit"
              className="bg-white font-title text-black hover:animate-pulse px-5 py-4 text-xl md:mx-48"
              disabled={isSubmitting}
              >
              {isSubmitting ? (`${t("SENDING")}...`) : (t("SUBMIT"))}
            </button>
            <div className="flex text-sm flex-col mt-2 md:flex-row lg:space-x-8 justify-center text-center items-center">
              <Link
                to="/Privacy"
                className="text-white mx-3 lg:mx-0 text-nowrap font-paragraph text-lg hover:underline"
              >
                {t("Cookie Policy")}
              </Link>
              <Link
                to="/Terms"
                className="text-white mx-3 lg:mx-0 text-nowrap font-paragraph text-lg   hover:underline"
              >
                {t("Terms & Conditions")}
              </Link>
              <Link
                to="/Privacy"
                className="text-white mx-3 lg:mx-0 text-nowrap font-paragraph text-lg   hover:underline"
              >
                {t("Privacy Policy")}
              </Link>
              <Link
                to="/PlansPage/BEGINNER"
                className="text-white mx-3 lg:mx-0 text-nowrap font-paragraph text-lg  hover:underline"
              >           
                {t("Plan Information")}
              </Link>
            </div>
            {showIconsFlex && (
            <div className="flex w-full justify-center mt-[2%]">
            <Link   
              to="https://www.facebook.com/profile.php?id=61552599852965&mibextid=ZbWKwL"
              className="mr-4 rounded-[100%] bg-white p-1 hover:animate-pulse" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={20} color='black' />
              </Link>
              <Link   
              to="https://www.instagram.com/arcseedai?igsh=MnY4eGlrdjYycW8x"
              className="mr-4 rounded-[100%] bg-white p-1 hover:animate-pulse" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={20} color='black' />
              </Link>
              <Link  
              to="https://discord.gg/quMPjP6cFr" 
              className="mr-4 rounded-[100%] bg-white p-1 hover:animate-pulse" target="_blank" rel="noopener noreferrer">
                <FaDiscord size={20} color='black' />
              </Link> 
              <Link  
              to="https://x.com/Arcseedai?t=ZHkrUzfHVmidMFDEGFhMfw&s=08"
              className="mr-4 rounded-[100%] bg-white p-1 hover:animate-pulse" target="_blank" rel="noopener noreferrer">
                <FaTwitter size={20} color='black' />
              </Link>

              {/* <Link   className="mr-4 rounded-[100%] bg-white p-1 hover:animate-pulse" target="_blank" rel="noopener noreferrer">
                <FaGithub size={20} color='black' />
              </Link> */}

              <Link   
              to="https://br.linkedin.com/in/arcseed-ai-6335a3294"
              className="mr-4 rounded-[100%] bg-white p-1 hover:animate-pulse" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={20} color='black' />
                </Link>

              <Link
              to="https://www.youtube.com/@arcseedai"
              className="rounded-[100%] bg-white p-1 hover:animate-pulse" 
              target="_blank" 
              rel="noopener noreferrer"
              >
                <FaYoutube size={20} color="black" />
                </Link>

              </div>
            )}
            </form>
              </div>

              <div className="bg-black text-white w-full max-w-screen lg:w-[40%] h-screen flex justify-center flex-col items-center">
                <div className="text-center">
                  <h1 className="font-title text-3xl">{t("E-MAIL US")}</h1>
                  <p className="font-paragraph text-2xl -mt-2 mb-28">
                    <a href="mailto:arcseed.notificar@gmail.com" className="text-white">
                    arcseed.notificar@gmail.com
                    </a>
                  </p>
                </div>
                <div className="text-center max-w-screen">
                  <h1 className="font-title text-3xl">WHATSAPP</h1>
                  <p className="font-paragraph text-2xl -mt-2">
                    <a href="tel:+553498428688" className="text-white">
                      +55 34 9842-8688
                    </a>
                  </p>
                </div>
              </div>
              </footer>
              );
              }

              if (props.footerVersion === "light-footer") {
                return <LightFooter />;
              }
              };

              Footer.defaultProps = {
                footerVersion: "default-footer",
              };
              export default Footer;
