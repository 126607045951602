import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

// Import images
const refineSmall = require('../../../../assets/animations/refine/refine_small.webp');
const refineBig = require('../../../../assets/animations/refine/refine_big.webp');

// Animation constants
const initialZoom = 1.2; // Starting zoom level
const zoomedInScale = 2.2; // Slightly zoomed-in view
const fadeDuration = 3; // Duration for fading animations
const moveDuration = 4; // Duration for movement animations
const waitDuration = 0.3; // Duration for wait points

// Define the start and duration for each animation phase
const movementStart1 = 1;
const movementTime1 = moveDuration;
const zoomInStart1 = movementTime1;
const zoomInTime1 = fadeDuration;
const zoomOutStart1 = zoomInStart1 + zoomInTime1;
const zoomOutTime1 = fadeDuration;

const movementStart2 = zoomOutStart1 + zoomOutTime1;
const movementTime2 = moveDuration;
const fadeInStart2 = movementStart2 + movementTime2;
const fadeInTime2 = fadeDuration;

const zoomInStart2 = fadeInStart2 + fadeInTime2;
const zoomInTime2 = fadeDuration;
const zoomOutStart2 = zoomInStart2 + zoomInTime2;
const zoomOutTime2 = fadeDuration;

const MorphingImagesRefining = () => {
  const [isSmallImageVisible, setIsSmallImageVisible] = useState(true);
  const [containerPositionX, setContainerPositionX] = useState('0%');
  const [containerScale, setContainerScale] = useState(initialZoom);

  const runAnimationSequence = async () => {
    // Delay for starting the first movement
    await new Promise((resolve) => setTimeout(resolve, movementStart1 * 1000));

    // Move to left corner
    setContainerPositionX('-35%');
    await new Promise((resolve) => setTimeout(resolve, movementTime1 * 1000));

    // Wait before zooming in
    await new Promise((resolve) => setTimeout(resolve, waitDuration * 1000));

    // Zoom in slightly and fade out
    setContainerScale(zoomedInScale);
    setTimeout(() => setIsSmallImageVisible(false), fadeDuration * 700);
    await new Promise((resolve) => setTimeout(resolve, zoomInTime1 * 2000));

    // Wait before zooming out
    await new Promise((resolve) => setTimeout(resolve, waitDuration * 100));

    // Zoom out
    setContainerScale(initialZoom);
    await new Promise((resolve) => setTimeout(resolve, zoomOutTime1 * 400));

    // Wait before moving to the right
    await new Promise((resolve) => setTimeout(resolve, waitDuration * 300));

    // Move to right offset
    setContainerPositionX('35%');
    await new Promise((resolve) => setTimeout(resolve, movementTime2 * 400));

    // Wait before fading in
    await new Promise((resolve) => setTimeout(resolve, waitDuration * 1000));

    // Fade back in
    setTimeout(() => setIsSmallImageVisible(true), fadeInTime2 * 1000);
    await new Promise((resolve) => setTimeout(resolve, fadeInTime2 * 1000));

    // Wait before zooming in again
    await new Promise((resolve) => setTimeout(resolve, waitDuration * 1000));

    // Zoom in and fade out again
    setContainerScale(zoomedInScale);
    setTimeout(() => setIsSmallImageVisible(false), zoomInTime2 * 6000);
    await new Promise((resolve) => setTimeout(resolve, zoomInTime2 * 1000));

    // Wait before returning to the center
    await new Promise((resolve) => setTimeout(resolve, waitDuration * 1000));

    // Return to center and reset
    setContainerPositionX('0%');
    setContainerScale(initialZoom);
    setIsSmallImageVisible(true);

    // Wait before starting the loop again
    setTimeout(runAnimationSequence, waitDuration * 1000);
  };

  useEffect(() => {
    runAnimationSequence();
  }, []);

  return (
    <div style={{ position: 'relative', width: '600px', height: '600px', overflow: 'hidden', margin: '0 auto' }}>
      {/* Container for movement and scaling */}
      <motion.div
        animate={{ scale: containerScale, x: containerPositionX }}
        transition={{ duration: moveDuration, ease: 'easeInOut', delay: movementStart1 }}
        style={{
          width: '1200px',
          height: '1200px',
          position: 'absolute',
          top: '-300px',
          left: '-300px',
          scale: initialZoom,
        }}
      >
        {/* Background (big) image */}
        <div
          style={{
            backgroundImage: `url(${refineBig})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />

        {/* Top (small) image with fade control */}
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: isSmallImageVisible ? 1 : 0 }}
          transition={{ duration: fadeDuration }}
          style={{
            backgroundImage: `url(${refineSmall})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      </motion.div>
    </div>
  );
};

export default MorphingImagesRefining;
