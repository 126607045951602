// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_gallery-setting__MOgko {\n    width: 100%;\n    margin-bottom: 40px;\n}\n.App_gallery-setting__MOgko h1 {\n    color: var(--dark-900);    \n}", "",{"version":3,"sources":["webpack://./src/components/PersonalGallery/App.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,sBAAsB;AAC1B","sourcesContent":[".gallery-setting {\n    width: 100%;\n    margin-bottom: 40px;\n}\n.gallery-setting h1 {\n    color: var(--dark-900);    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gallery-setting": "App_gallery-setting__MOgko"
};
export default ___CSS_LOADER_EXPORT___;
