export function geraPrompt(userChoices, user) {

  let prompt_default = `{
  "2": {
    "inputs": {
      "stop_at_clip_layer": -3,
      "clip": [
        "81",
        1
      ]
    },
    "class_type": "CLIPSetLastLayer",
    "_meta": {
      "title": "CLIP Set Last Layer"
    }
  },
  "6": {
    "inputs": {
      "width": 1024,
      "height": 1024,
      "batch_size": 1
    },
    "class_type": "EmptyLatentImage",
    "_meta": {
      "title": "Empty Latent Image"
    }
  },
  "20": {
    "inputs": {
      "text": "A modern bedroom, (night time:1.3), city",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "21": {
    "inputs": {
      "text": "blur, distortion, jiggly, low quality",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "22": {
    "inputs": {
      "seed": [
        "47",
        0
      ],
      "steps": 10,
      "cfg": 1.5,
      "sampler_name": "euler",
      "scheduler": "sgm_uniform",
      "denoise": [
        "38",
        0
      ],
      "model": [
        "81",
        0
      ],
      "positive": [
        "52",
        0
      ],
      "negative": [
        "21",
        0
      ],
      "latent_image": [
        "6",
        0
      ]
    },
    "class_type": "KSampler",
    "_meta": {
      "title": "KSampler"
    }
  },
  "23": {
    "inputs": {
      "samples": [
        "22",
        0
      ],
      "vae": [
        "81",
        2
      ]
    },
    "class_type": "VAEDecode",
    "_meta": {
      "title": "VAE Decode"
    }
  },
  "38": {
    "inputs": {
      "value": 1
    },
    "class_type": "easy float",
    "_meta": {
      "title": "Float"
    }
  },
  "47": {
    "inputs": {
      "seed": 3,
      "🎲 Manual Random Seed": null
    },
    "class_type": "easy seed",
    "_meta": {
      "title": "EasySeed"
    }
  },
  "52": {
    "inputs": {
      "conditioning_to": [
        "53",
        0
      ],
      "conditioning_from": [
        "20",
        0
      ]
    },
    "class_type": "ConditioningConcat",
    "_meta": {
      "title": "Conditioning (Concat)"
    }
  },
  "53": {
    "inputs": {
      "text": "",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "68": {
    "inputs": {
      "sharpen_radius": 1,
      "sigma": 0.4,
      "alpha": 2,
      "image": [
        "90",
        0
      ]
    },
    "class_type": "ImageSharpen",
    "_meta": {
      "title": "ImageSharpen"
    }
  },
  "80": {
    "inputs": {
      "filename_prefix": "BrainstormGen_pro_square_V0.2.3",
      "file_type": "JPEG",
      "images": [
        "68",
        0
      ]
    },
    "class_type": "SaveImageExtended",
    "_meta": {
      "title": "Save Image (Extended)"
    }
  },
  "81": {
    "inputs": {
      "ckpt_name": "Arcseed_V0.2.safetensors",
      "key_opt": "",
      "mode": "Auto"
    },
    "class_type": "CheckpointLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Checkpoint Loader (Inspire)"
    }
  },
  "82": {
    "inputs": {
      "model_name": "swift_srgan_2x.pth",
      "key_opt_u": "",
      "mode": "Auto"
    },
    "class_type": "UpscaleLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Upscale Loader (Inspire)"
    }
  },
  "83": {
    "inputs": {
      "control_net_name": "LoraModelScribble.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "85": {
    "inputs": {
      "control_net_name": "XLcontrol-lora-depth-rank256.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "86": {
    "inputs": {
      "control_net_name": "XLttplanetSDXLControlnet_v10Fp16.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "87": {
    "inputs": {
      "clip_name": "clipvis_ViT-H_1.5_.safetensors",
      "key_opt_cv": "",
      "mode": "Auto"
    },
    "class_type": "CLIPVisionLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Clip Loader (Inspire)"
    }
  },
  "89": {
    "inputs": {
      "key": 1
    },
    "class_type": "RetrieveBackendDataNumberKey //Inspire",
    "_meta": {
      "title": "Retrieve Backend Data [NumberKey] (Inspire)"
    }
  },
  "90": {
    "inputs": {
      "upscale_by": 1.5,
      "seed": 1,
      "steps": 8,
      "cfg": 1.5,
      "sampler_name": "euler",
      "scheduler": "sgm_uniform",
      "denoise": 0.48,
      "mode_type": "Linear",
      "tile_width": 1536,
      "tile_height": 1536,
      "mask_blur": 8,
      "tile_padding": 32,
      "seam_fix_mode": "None",
      "seam_fix_denoise": 1,
      "seam_fix_width": 64,
      "seam_fix_mask_blur": 8,
      "seam_fix_padding": 16,
      "force_uniform_tiles": true,
      "tiled_decode": false,
      "image": [
        "23",
        0
      ],
      "model": [
        "81",
        0
      ],
      "positive": [
        "52",
        0
      ],
      "negative": [
        "21",
        0
      ],
      "vae": [
        "81",
        2
      ],
      "upscale_model": [
        "82",
        0
      ]
    },
    "class_type": "UltimateSDUpscale",
    "_meta": {
      "title": "Ultimate SD Upscale"
    }
  },
  "94": {
    "inputs": {
      "seed": 127,
      "denoise_steps": 6,
      "ensemble_size": 3,
      "processing_resolution": 768,
      "scheduler": "LCMScheduler",
      "use_taesd_vae": false,
      "marigold_model": [
        "95",
        0
      ]
    },
    "class_type": "MarigoldDepthEstimation_v2",
    "_meta": {
      "title": "MarigoldDepthEstimation_v2"
    }
  },
  "95": {
    "inputs": {
      "key": 2
    },
    "class_type": "RetrieveBackendDataNumberKey //Inspire",
    "_meta": {
      "title": "Retrieve Backend Data [NumberKey] (Inspire)"
    }
  }
}`;

  prompt_default = JSON.parse(prompt_default);

  const { floor, walls, ceiling } = userChoices["materials"] || {};


  const modelos = userChoices["aiModels"] || '';
  let modeloFrase = "";

  switch (modelos) {
    case "DEFAULT":
      modeloFrase = " ";
      break;
    case "RENDER":
      modeloFrase = "A 3D render of";
      break;
    case "CINEMATIC":
      modeloFrase = "A Cinematic professional shot of";
      break;
    case "PHOTOGRAPHER":
      modeloFrase = "A Photograph of";
      break;
    case "ARCHVIZ":
      modeloFrase = "A Professional architectural rendering of";
      break;
    case "PRISTINE":
      modeloFrase = "A Pristine photograph of";
      break;
  }

  const anStyles = [
    "arabic", "art deco", "art nouveau", "asian fusion", "eclectic", "english",
    "industrial", "japandi", "oriental", "urban modern"
  ];
  const style = userChoices["style"] || '';
  
  // Se o estilo não for selecionado, styleFrase será uma string vazia.
  let styleFrase = '';
  if (style) {
    styleFrase = anStyles.includes(style) ? `an ${style}` : `a ${style}`;
  }


  let whereExterior = userChoices["whereExterior"] || '';
  let time = userChoices["time"] || '';
  let colorPalette = userChoices["colorPalette"] || [];
  let what = userChoices["what"] || '';
  let Style = styleFrase;
  let Modes = `${modeloFrase}` || '';
  let weather = userChoices["weather"] || '';

  if (what === "Square") {
    what = "a public plaza";
  }

  // Remove duplicatas da paleta de cores
  colorPalette = [...new Set(colorPalette)];

  // Construir a frase do chão
  let floorFrase = userChoices["floor"] ? `${userChoices["floor"]} floor` : '';

  // Construir a frase das paredes

  let wallsFrase = '';
if (Array.isArray(walls) && walls.length > 0) {
  if (walls.length === 1) {
    wallsFrase = `${walls[0]} walls`;
  } else if (walls.length === 2) {
    wallsFrase = `${walls[0]} and ${walls[1]} walls`;
  } else {
    wallsFrase = `${walls.slice(0, -1).join(', ')} and ${walls[walls.length - 1]} walls`;
  }
}

  // Construir a frase do teto
  let ceilingFrase = userChoices["ceiling"] ? `${userChoices["ceiling"]} ceiling` : '';

  // Construa o prompt apenas com os parâmetros fornecidos
  const promptParts = [
    Modes,
    Style,
    what ? `${what},` : '',  // Adiciona vírgula após what
    time ? `${time},` : '',    // Adiciona vírgula após time
    whereExterior ? `${whereExterior},` : '',
    weather
  ].filter(Boolean).map(part => part.trim()); // Remove valores falsos e trim

  // Junta os elementos em uma única string
  const promptText = promptParts.join(' ').replace(/\s{2,}/g, ' ').replace(/,\s*,/g, ',').replace(/,\s*$/, '');

  // Atualiza o texto no nó 20
  prompt_default["20"]["inputs"]["text"] = promptText.trim();
  prompt_default["53"]["inputs"]["text"] = [...colorPalette, floorFrase, wallsFrase, ceilingFrase].filter(Boolean).join(', ').replace(/,\s*,/g, ',').replace(/,\s*$/, '');

  prompt_default["22"]["inputs"]["seed"] = `${userChoices["seed"]}`;
  const aiImaginationMap = {
    5: 3.0,
    4: 2.0,
    3: 1.5,
    2: 1.25,
    1: 1.0
  };

// Recupera a escolha do usuário para AI Imagination Range
const userAIImaginationChoice = userChoices["AIImmaginationRange"];

// Define o valor de cfg de acordo com a escolha do usuário
const selectedAIImaginationValue = aiImaginationMap[userAIImaginationChoice];

// Aplica o valor ao prompt_default para cfg
prompt_default["22"]["inputs"]["cfg"] = selectedAIImaginationValue;

  prompt_default["80"]["inputs"]["filename_prefix"] = `${user}`;

  const prompt = prompt_default;
  //console.log(prompt);
  return prompt;
}
