import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io"; // Ícone de voltar
import { useTranslation } from "react-i18next";
import NewPopUpComponent from "../Generic/NewPopUpComponent";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa"; // Ícone de check
import { add_Back } from "../../address";
import "./index.css";
import "./App.css";

export default function ConfirmEmail() {
  const { t } = useTranslation();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [timer, setTimer] = useState(60); // Estado do temporizador
  const navigate = useNavigate();

  const email = decodeURIComponent(
    new URLSearchParams(window.location.search).get("email")
  );

  // Redireciona para login se não houver email
  useEffect(() => {
    if (!email) {
      navigate("/Login");
    }
  }, [email, navigate]);

  // Função para lidar com o clique de reenviar o e-mail
  const handleResendClick = async () => {
    setIsResendDisabled(true);
    try {
      const response = await fetch(`${add_Back}/verificarEmail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }), // Reenvia o e-mail
      });
      if (!response.ok) {
        throw new Error("Erro ao reenviar o e-mail de verificação.");
      }
      console.log("E-mail de confirmação reenviado!");
    } catch (error) {
      console.error(error.message);
      setIsResendDisabled(false);
    }
  };

  // useEffect para gerenciar o temporizador
  useEffect(() => {
    let interval;
    if (isResendDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendDisabled(false); // Habilita o botão após o tempo
      setTimer(60); // Reseta o temporizador para 60 segundos
    }

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar
  }, [isResendDisabled, timer]);

  return (
    <section className="max-h-screen h-screen bg-black flex flex-col justify-center relative">
      <NewPopUpComponent isClosable={true} />
      <Link className="absolute top-[4%] left-[4%]" to="/">
        <button className="rounded-full bg-gray-900 text-white p-2">
          <IoIosArrowBack size={24} />
        </button>
      </Link>

      <div className="flex items-center justify-center text-center">
        <div className="relative flex flex-col md:w-[45%] lg:w-[30%] break-words shadow-lg bg-zinc-100 border-0 p-6">
          {isConfirmed ? (
            <div className="flex flex-col items-center justify-center text-center">
              <FaCheckCircle className="mb-4" size={50} />
              <p className="font-paragraph text-[150%] mt-4">
                {t("Email confirmed! Redirecting...")}
              </p>
            </div>
          ) : (
            <div className="">
              <div className="text-center mb-6">
                <h2 className="text-blueGray-400 font-bold text-[150%] font-title">
                  {t("Check Your Email")}
                </h2>
                <p className="font-paragraph text-[110%] text-blueGray-600">
                  {t(
                    "We've sent you a confirmation link. Please check your email."
                  )}
                </p>
              </div>

              <div className="text-center">
                <button
                  className={`${
                    isResendDisabled
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-slate-300 hover:bg-opacity-100"
                  } font-title bg-opacity-70 text-black text-[100%] font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 w-full ease-linear transition-all duration-150`}
                  onClick={handleResendClick}
                  disabled={isResendDisabled}
                >
                  {isResendDisabled
                    ? t("Resend in {{timer}}s", { timer })// Mostra o tempo restante
                    : t("Resend Confirmation Email")}
                </button>
              </div>

              <div className="flex justify-between pt-6 relative">
                <Link to="/Login">
                  <p className="font-paragraph hover:underline text-[130%] text-black mx-2">
                    {t("Back to Login")}
                  </p>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
