export const offensiveWords = [
  "sexy",
  "sex",
  "vulgar",
  "whore",
  "bitch",
  "prostitute",
  "horny",
  "penis",
  "dick",
  "cock",
  "BBC",
  "bbc",
  "BbC",
  "Bbc",
  "vulva",
  "ass",
  "pussy",
  "anal",
  "vagine",
  "naked",
  "sexual",
  "penetration",
  "shit",
  "puke",
  "erect",
  "cunt",
  "butt",
  "tits",
  "breast",
  "boobs",
  "nipples",
  "bukkake",
  "gore",
  "mame",
  "kill",
  "assassinate",
  "stab",
  "fuck",
  "suck",
  "cum",
  "slut",
  "rape",
  "rapist",
  "sexo",
  "puta",
  "vagabunda",
  "prostituta",
  "vadia",
  "excitada",
  "pênis",
  "rola",
  "pau",
  "pica",
  "vulva",
  "cú ",
  "boceta",
  "buceta",
  "anal",
  "vagina",
  "pelada",
  "penetração",
  "caralho",
  "caraio",
  "merda",
  "bosta",
  "diarréia",
  "diarreia",
  "vomito",
  "ereto",
  "cunt",
  "bunda",
  "tetas",
  "peitos",
  "seios",
  "carnificina",
  "mmutilação",
  "matar",
  "estuprar",
  "assassinar",
  "esfaquear",
  "estuprador",
  "foder",
  "fuder",
  "arrombar",
  "arrombado",
  "arrombada",
  "chupar",
  "gozar",
  "gozada",
  "bucetinha",
  "bucetona",
  "meter",


  // Add more offensive words here
];