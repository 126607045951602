import React, { useEffect } from "react";
import FirstScreen from "./components/IndexComponents/FirstScreen.jsx";
import UncolouredImages from "./components/IndexComponents/UncolouredImages.jsx";
import GreetingsScreen from "./components/IndexComponents/GreetingsScreen.jsx";
import CarrosselSolutions from "./components/IndexComponents/Solutions/RenderSlides.jsx";
import Footer from "./components/Footer.jsx";
import { animateScroll as scroll } from "react-scroll";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NewPopUpComponent from "./components/Generic/NewPopUpComponent.jsx";

export default function IndexPage() {
  let { gotofooter } = useParams();

  const { i18n } = useTranslation();
  
  useEffect(() => {
    i18n.init({ ns: "indexPage" })
  }, [])
  
  // NOVO 
   useEffect(() => {
  

    if (gotofooter === "footer") {
      scroll.scrollToBottom();
    }

  
  }, [gotofooter]);

  return (
    <div className="max-w-[100vw] overflow-x-hidden">
      <NewPopUpComponent
      isClosable={true}/>
      <div className="max-h-[200vh]">
      <section 
      className="block box-content max-h-[100vh]">
      <FirstScreen />
      </section>
      <section 
      className="hidden h-screen lg:inline "
      id="uncolored-images">
        <UncolouredImages />
      </section>
      </div>
      <div className="h-screen">
      <GreetingsScreen />
      </div>
      <section 
      className="h-screen"
      id="solutions">
        <CarrosselSolutions />
      </section>
      <section id="default-footer">
        <Footer className={gotofooter === "footer" ? "mobile-footer" : ""} />
      </section>
    </div>
  );
}
