import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const imagesSet1 = [
  require('../../../../assets/animations/skt2render/skt2render_001.webp'),
  require('../../../../assets/animations/skt2render/skt2render_002.webp')
];

const imagesSet2 = [
  require('../../../../assets/animations/skt2render/skt2render_003.webp'),
  require('../../../../assets/animations/skt2render/skt2render_004.webp')
];

const imagesSet3 = [
  require('../../../../assets/animations/skt2render/skt2render_005.webp'),
  require('../../../../assets/animations/skt2render/skt2render_006.webp')
];

const singleImage = require('../../../../assets/animations/skt2render/skt2render_single.webp');

// Constants for animation timings
const animationDuration1 = 6;
const fadeOutDuration1 = 3;
const initialZoom1 = 1.2;
const initialYPosition1 = '-15%';
const finalYPosition1 = '5%';
const fadeOutStartTime1 = 1;
const bottomFadeOutStartTime1 = 5;
const bottomFadeOutDuration1 = 0.5;

// Constants for the other sets
const animationDuration2 = 6;
const fadeOutDuration2 = 3;
const initialZoom2 = 0.8;
const initialXPosition2 = '-10%';
const finalXPosition2 = '8%';
const fadeOutStartTime2 = 1;
const bottomFadeOutStartTime2 = 5;
const bottomFadeOutDuration2 = 0.5;
const set2StartDelay = 5000;

const animationDuration3 = 6;
const fadeOutDuration3 = 3;
const initialZoom3 = 0.8;
const initialYPosition3 = '0%';
const finalYPosition3 = '0%';
const fadeOutStartTime3 = 1;
const bottomFadeOutStartTime3 = 5;
const bottomFadeOutDuration3 = 0.5;
const set3StartDelay = 10000;
const finalZoom3 = 0.89;

const animationDurationSingle = 5;
const fadeOutDurationSingle = 2;
const initialZoomSingle = 1.2;
const initialYPositionSingle = '0%';
const finalYPositionSingle = '0%';
const fadeOutStartTimeSingle = 1;
const bottomFadeOutStartTimeSingle = 3;
const bottomFadeOutDurationSingle = 0.5;
const singleImageStartDelay = 15000;

const Sketch2Render = () => {
  const [currentImageSet1, setCurrentImageSet1] = useState(0);
  const [currentImageSet2, setCurrentImageSet2] = useState(0);
  const [currentImageSet3, setCurrentImageSet3] = useState(0);
  const [startSet2Animation, setStartSet2Animation] = useState(false);
  const [startSet3Animation, setStartSet3Animation] = useState(false);
  const [startSingleImageAnimation, setStartSingleImageAnimation] = useState(false);
  const [loopKey, setLoopKey] = useState(0);

  // Reset the animations on each loop restart
  useEffect(() => {
    setStartSet2Animation(false);
    setStartSet3Animation(false);
    setStartSingleImageAnimation(false);

    const interval1 = setInterval(() => {
      setCurrentImageSet1((prev) => (prev + 1) % imagesSet1.length);
    }, (animationDuration1 + fadeOutDuration1) * 1000);

    const set2Timer = setTimeout(() => setStartSet2Animation(true), set2StartDelay);
    const set3Timer = setTimeout(() => setStartSet3Animation(true), set3StartDelay);
    const singleImageTimer = setTimeout(() => setStartSingleImageAnimation(true), singleImageStartDelay);

    return () => {
      clearInterval(interval1);
      clearTimeout(set2Timer);
      clearTimeout(set3Timer);
      clearTimeout(singleImageTimer);
    };
  }, [loopKey]);

  const handleSingleImageFadeOutComplete = () => {
    setLoopKey((prev) => prev + 1); // Increment loopKey to reset all animations
  };

  const nextImageSet1 = (currentImageSet1 + 1) % imagesSet1.length;
  const nextImageSet2 = (currentImageSet2 + 1) % imagesSet2.length;
  const nextImageSet3 = (currentImageSet3 + 1) % imagesSet3.length;

  return (
    <div style={{ position: 'relative', width: '600px', height: '600px', margin: '0 auto', overflow: 'hidden' }}>
      {/* White overlay for initial fade effect */}
      <motion.div
        key={`whiteOverlay-${loopKey}`}
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#ffffff',
          zIndex: 3, // Above all images
        }}
      />

      {/* Single image layer with animation, positioned below other sets */}
      {startSingleImageAnimation && (
        <motion.div
          key={`singleImage-${loopKey}`}
          initial={{ opacity: 1, scale: initialZoomSingle, y: initialYPositionSingle }}
          animate={{ opacity: 0, y: finalYPositionSingle, scale: initialZoomSingle }}
          transition={{
            duration: animationDurationSingle,
            opacity: { delay: bottomFadeOutStartTimeSingle, duration: bottomFadeOutDurationSingle }
          }}
          onAnimationComplete={handleSingleImageFadeOutComplete}
          style={{
            backgroundImage: `url(${singleImage})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            width: '1200px',
            height: '800px',
            position: 'absolute',
            top: '-100px',
            left: '-300px',
            zIndex: -1,
          }}
        />
      )}

      {/* Third set of images */}
      {startSet3Animation && (
        <>
          <motion.div
            key={`${nextImageSet3}-${loopKey}`}
            initial={{ opacity: 1, scale: initialZoom3, y: initialYPosition3 }}
            animate={{ opacity: 0, y: finalYPosition3, scale: finalZoom3 }}
            transition={{
              duration: animationDuration3,
              opacity: { delay: bottomFadeOutStartTime3, duration: bottomFadeOutDuration3 }
            }}
            style={{
              backgroundImage: `url(${imagesSet3[nextImageSet3]})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              width: '1200px',
              height: '800px',
              position: 'absolute',
              top: '-100px',
              left: '-300px',
              zIndex: 0,
            }}
          />

          <motion.div
            key={`${currentImageSet3}-${loopKey}`}
            initial={{ opacity: 1, scale: initialZoom3, y: initialYPosition3 }}
            animate={{ opacity: 0, y: finalYPosition3, scale: finalZoom3 }}
            transition={{
              duration: animationDuration3,
              opacity: { delay: fadeOutStartTime3, duration: fadeOutDuration3 }
            }}
            style={{
              backgroundImage: `url(${imagesSet3[currentImageSet3]})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              width: '1200px',
              height: '800px',
              position: 'absolute',
              top: '-100px',
              left: '-300px',
              zIndex: 0,
            }}
          />
        </>
      )}

      {/* Second set of images */}
      {startSet2Animation && (
        <>
          <motion.div
            key={`${nextImageSet2}-${loopKey}`}
            initial={{ opacity: 1, scale: initialZoom2, x: initialXPosition2 }}
            animate={{ opacity: 0, x: finalXPosition2, scale: initialZoom2 }}
            transition={{
              duration: animationDuration2,
              opacity: { delay: bottomFadeOutStartTime2, duration: bottomFadeOutDuration2 }
            }}
            style={{
              backgroundImage: `url(${imagesSet2[nextImageSet2]})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              width: '1200px',
              height: '800px',
              position: 'absolute',
              top: '-100px',
              left: '-300px',
              zIndex: 1,
            }}
          />

          <motion.div
            key={`${currentImageSet2}-${loopKey}`}
            initial={{ opacity: 1, scale: initialZoom2, x: initialXPosition2 }}
            animate={{ opacity: 0, x: finalXPosition2, scale: initialZoom2 }}
            transition={{
              duration: animationDuration2,
              opacity: { delay: fadeOutStartTime2, duration: fadeOutDuration2 }
            }}
            style={{
              backgroundImage: `url(${imagesSet2[currentImageSet2]})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              width: '1200px',
              height: '800px',
              position: 'absolute',
              top: '-100px',
              left: '-300px',
              zIndex: 1,
            }}
          />
        </>
      )}

      {/* First set of images */}
      <motion.div
        key={`${nextImageSet1}-${loopKey}`}
        initial={{ opacity: 1, scale: initialZoom1, y: initialYPosition1 }}
        animate={{ opacity: 0, y: finalYPosition1, scale: initialZoom1 }}
        transition={{
          duration: animationDuration1,
          opacity: { delay: bottomFadeOutStartTime1, duration: bottomFadeOutDuration1 }
        }}
        style={{
          backgroundImage: `url(${imagesSet1[nextImageSet1]})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          width: '1200px',
          height: '800px',
          position: 'absolute',
          top: '-100px',
          left: '-300px',
          zIndex: 2,
        }}
      />

      <motion.div
        key={`${currentImageSet1}-${loopKey}`}
        initial={{ opacity: 1, scale: initialZoom1, y: initialYPosition1 }}
        animate={{ opacity: 0, y: finalYPosition1, scale: initialZoom1 }}
        transition={{
          duration: animationDuration1,
          opacity: { delay: fadeOutStartTime1, duration: fadeOutDuration1 }
        }}
        style={{
          backgroundImage: `url(${imagesSet1[currentImageSet1]})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          width: '1200px',
          height: '800px',
          position: 'absolute',
          top: '-100px',
          left: '-300px',
          zIndex: 2,
        }}
      />
    </div>
  );
};

export default Sketch2Render;
