import { React, useState } from "react";
import { useEffect } from "react";
import ImageUploader from "../components/RenderComponents/ImageUploader";
import ImageCropper from "../components/RenderComponents/ImageCropper";
import AIModels from "../components/RenderComponents/AIModels";
import Seed from "../components/RenderComponents/Seed";
import Type from "../components/RenderComponents/Type";
// import Floors from "../compone             nts/RenderComponents/Floors";
// import Where from "../components/RenderComponents/WhereExterior";
// import What from "../components/RenderComponents/WhatExterior";
// import Weather from "../components/RenderComponents/Weather";
// import Time from "../components/RenderComponents/Time";
// import Style from "../components/RenderComponents/Style";
import GenericRenderComponent from "../components/RenderComponents/GenericRenderComponent.jsx";
import weatherJSON from "../components/RenderComponents/renderParamJSONConfig/weather.json";
import whereExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whereExteriors.json";
import timeExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeExteriors.json";
import timeInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/timeInteriors.json";
import styleInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleInteriors.json";
import styleExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/styleExteriors.json";
import whatInteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whatInteriors.json";
import whatExteriorsJSON from "../components/RenderComponents/renderParamJSONConfig/whatExteriors.json";
import ColorPalette from "../components/RenderComponents/ColorPalette";
import Materials from "../components/RenderComponents/Materials";
import Visibility from "../components/RenderComponents/Visibility";
import RenderButton from "../components/RenderComponents/RenderButton";
import Navbar, {navbarHeight} from "../components/Navbar";
import AIImmaginationRange from "../components/RenderComponents/AIImmaginationRange";
import OriginalResemblance from "../components/RenderComponents/OriginalResemblance.jsx";
import Footer from "../components/Footer";
import Originality from "../components/RenderComponents/Originality.jsx";
import Cookies from "js-cookie";
import WallpaperGen from "../assets/wallpaperRenderPage.webp";
import { useTranslation } from "react-i18next";
// import WhatInteriors from "../components/RenderComponents/WhatInterior";
// import WhatExteriors from "../components/RenderComponents/WhatExterior";
import { jwtDecode } from "jwt-decode";
import Precision from "../components/RenderComponents/Precision.jsx";
import ShapeStrength from "../components/RenderComponents/ShapeStrength.jsx";
//import { geraPrompt } from '../apiComfy/Upscale/ReRender.js';
import { geraPrompt as geraPromptLandscapePro } from "../apiComfy/ReRender/ReRender_Pro_Landscape.js";
import { geraPrompt as geraPromptSquarePro } from "../apiComfy/ReRender/ReRender_Pro_Square.js";
import { geraPrompt as geraPromptPortraitPro } from "../apiComfy/ReRender/ReRender_Pro_Portrait.js";

import { geraPrompt as geraPromptLandscapeStandart } from "../apiComfy/ReRender/ReRender_Standart_Landscape.js";
import { geraPrompt as geraPromptSquareStandart } from "../apiComfy/ReRender/ReRender_Standart_Square.js";
import { geraPrompt as geraPromptPortraitStandart } from "../apiComfy/ReRender/ReRender_Standart_Portrait.js";
import ShapeGuidance from "../components/RenderComponents/ShapeGuidance.jsx";
import Accordion from "../components/RenderComponents/Accordion.jsx";
import NewPopUpComponent from "../components/Generic/NewPopUpComponent.jsx";
import { offensiveWords } from "../badwords.js";
import { handleTranslateClick } from "../components/Generic/translationUtils.js";
import InfoBox from "../components/InfoBox.jsx";
import { add_Back } from "../address.js";
import PromptToggleOrMessage from "../components/RenderComponents/PromptToggleOrMensage.jsx";

export default function ReRenderPage() {
  const standardColors = {
    active: "bg-[#fffe80] text-black",
    inactive: "bg-[#ffffb3] text-black",
    hover: "hover:bg-[#fffe80] hover:text-black",
    valor: 2,
  };

  const professionalColors = {
    active: "bg-[#fcac7f] text-black",
    inactive: "bg-[#fdd7bc] text-black",
    hover: "hover:bg-[#fcac7f] hover:text-black",
    valor: 3,
  };

  const [isPromptEnabled, setIsPromptEnabled] = useState(false);
  const [promptInput, setPromptInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [previousTranslatedText, setPreviousTranslatedText] = useState("");
  const [currentPrompt, setCurrentPrompt] = useState("");  // This will store the latest input before translation
  const [isTranslating, setIsTranslating] = useState(false); // Handle translation state


  // Function to handle the input change
  const handleInputChange = (e) => {
    let inputValue = e.target.value;  // Changed from const to let to allow reassignment
  
    // Check for offensive words
    let containsOffensiveWord = false;
    offensiveWords.forEach((word) => {
      const regex = new RegExp(`\\b${word}\\b`, "gi"); // Regex to detect whole words only
      if (regex.test(inputValue)) {
        containsOffensiveWord = true;
        inputValue = inputValue.replace(regex, "");  // Remove the offensive word
      }
    });
  
    // Update the input field and show error if necessary
    if (containsOffensiveWord) {
      setErrorMessage("Your input contains offensive words and has been modified.");
    } else {
      setErrorMessage("");  // Clear error message if no offensive word found
    }
  
    // Update the promptInput state
    setCurrentPrompt(inputValue);  // Always update currentPrompt as user types
    setPromptInput(inputValue);  // Set the promptInput
  
    // Update userChoices to include the prompt input
    setUserChoices((prevChoices) => ({
      ...prevChoices,
      prompt: inputValue,  // Store the prompt input in userChoices
    }));
  };

// Function to handle translation when the button is clicked
const handleTranslateClick = async () => {
  // Disable the button and change the text to "Translating..."
  setIsTranslating(true);

  try {
    const response = await fetch(`${add_Back}/translate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text: promptInput }),  // Send the current prompt text
    });
    
    const data = await response.json();
    
    if (response.ok) {
      // Update the prompt input with the translated text
      setPromptInput(data.translatedText);
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        prompt: data.translatedText,
      }));
    } else {
      console.error('Translation failed:', data.message);
    }
  } catch (error) {
    console.error('Error during translation:', error);
  } finally {
    // Re-enable the button and reset the text after 2 seconds
    setTimeout(() => {
      setIsTranslating(false);
    }, 2000); // 2-second delay
  }
};

// Add the new state variables for the prompt and toggle
const handlePromptToggle = () => {
  const newIsPromptEnabled = !isPromptEnabled;

  setIsPromptEnabled(newIsPromptEnabled);

  if (newIsPromptEnabled) {
    setUserChoices({
      ...userChoices,
      aiModels: null, 
      type: "PROMPT",  // Set type to PROMPT
      what: null,
      time: null,
      style: "Modern",
      colorPalette: null,
      materials: null,
      whereExterior: null,
      weather: null,
      AIImmaginationRange: "3",
      OriginalityRange: "0.9",
      ShapeGuidanceRange: "2",
      ShapeStrengthRange: "1",
      OriginalResemblanceRange: "2",
    });
  } else {
    // Reset the user choices if the toggle is turned off
    setUserChoices({
      ...userChoices,
      type: null,  // Reset the type to null or the default
      aiModels: "default",
      what: null,
      time: null,
      style: "Modern",
      colorPalette: null,
      materials: null,
      whereExterior: null,
      weather: null,
      // Restore `InputDepthRange` for exteriors only, null for interiors
      InputDepthRange: userChoices.type === "EXTERIORS" ? 
      (userChoices.precision === "STANDARD" ? "1" : "2") : null,
      AIImmaginationRange: "3",
      OriginalityRange: "0.9",
      ShapeGuidanceRange: "2",
      ShapeStrengthRange: "1",
      OriginalResemblanceRange: "2",

    });
  }
};



  // Função para verificar se o token está presente e válido
  const token = Cookies.get("token");
  function verificarToken() {

    if (!token) {
      // Token não encontrado no localStorage
      return false;
    }

    // Decodificar o token para verificar sua validade
    try {
      const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
      const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

      // Verificar se o token expirou
      if (decodedToken.exp < currentTime) {
        // Token expirado
        return false;
      }

      // Token válido
      return true;
    } catch (error) {
      //console.error('Erro ao decodificar o token:', error);
      return false; // Token inválido
    }
  }

  

  // Função para redirecionar para a página de login
  function redirectToLogin() {
    window.location.href = "/Login"; // Redireciona para a página de login
  }

  // Verificar o token ao carregar a página
  if (!verificarToken()) {
    redirectToLogin(); // Redireciona para a página de login se o token não for válido
  }

  const [userChoices, setUserChoices] = useState({
    imageUploader: null,
    imageCropper: null,
    aiModels: null,
    seed: null,
    type: null,
    whereExterior: null,
    what: null,
    weather: null,
    time: null,
    style: "Modern",
    colorPalette: null,
    materials: null,
    effects: null,
    visibility: "PUBLIC",
    precision: "STANDARD",
    AIImmaginationRange: "3",
    OriginalityRange: "0.9",
    ShapeGuidanceRange: "2",
    ShapeStrengthRange: "1",
    OriginalResemblanceRange: "2",
  });

  const { t } = useTranslation();

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // Verifica o tamanho da tela e define o estado isSmallScreen
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Defina a largura limite para telas pequenas
    };

    // Executa a verificação no carregamento e quando o tamanho da tela muda
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    // Remove o event listener ao desmontar o componente
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const updateUserChoices = (category, choice) => {
    setUserChoices((prevChoices) => ({
      ...prevChoices,
      [category]: choice,
    }));
  };
  const decodedToken = jwtDecode(token);
  const user = decodedToken.clienteId; 

  
    // Move handleRender outside of convertToJson
    const handleRender = () => {

      let finalConfig;

      const nomeImage = `${user}_${Date.now()}.png`;
      sessionStorage.setItem("nomeImage", nomeImage);
    
      if (isPromptEnabled && promptInput) {
        // Fetch the appropriate `geraPrompt` structure based on precision and image cropper
        let geraPrompt;
    
        if (userChoices.precision === "PROFESSIONAL") {
          if (userChoices.imageCropper === "landscape") {
            geraPrompt = geraPromptLandscapePro(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "portrait") {
            geraPrompt = geraPromptPortraitPro(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "square") {
            geraPrompt = geraPromptSquarePro(userChoices, user, nomeImage);
          }
        } else {
          if (userChoices.imageCropper === "landscape") {
            geraPrompt = geraPromptLandscapeStandart(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "portrait") {
            geraPrompt = geraPromptPortraitStandart(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "square") {
            geraPrompt = geraPromptSquareStandart(userChoices, user, nomeImage);
          }
        }
    
        // Copy the geraPrompt structure into finalConfig
        finalConfig = { ...geraPrompt };
    
        // Modify the text in node 20 with the prompt input from the user
        finalConfig["20"]["inputs"]["text"] = promptInput.trim(); // Replace the prompt with user input
      } else {
        // Otherwise, continue using the existing logic to generate the full prompt
        let geraPrompt;
    
        if (userChoices.precision === "PROFESSIONAL") {
          if (userChoices.imageCropper === "landscape") {
            geraPrompt = geraPromptLandscapePro(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "portrait") {
            geraPrompt = geraPromptPortraitPro(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "square") {
            geraPrompt = geraPromptSquarePro(userChoices, user, nomeImage);
          }
        } else {
          if (userChoices.imageCropper === "landscape") {
            geraPrompt = geraPromptLandscapeStandart(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "portrait") {
            geraPrompt = geraPromptPortraitStandart(userChoices, user, nomeImage);
          } else if (userChoices.imageCropper === "square") {
            geraPrompt = geraPromptSquareStandart(userChoices, user, nomeImage);
          }
        }
    
        // Assign `geraPrompt` to `finalConfig`
        finalConfig = geraPrompt;
      }
    
      // Store the final configuration in sessionStorage (whether it's a normal prompt or a custom prompt)
      const saida = finalConfig;

      sessionStorage.setItem("GeraPrompt", JSON.stringify(saida));

    };
    
    
  
  // Keep convertToJson as is
  const convertToJson = () => {
    // Some logic...
  };

  

  const [moedas, setMoedas] = useState(() => {
    const storedMoedas = localStorage.getItem("moedas_total");
    return storedMoedas ? parseInt(storedMoedas, 10) : 0;
  });

  const handleMoedasUpdate = (newMoedas) => {
    setMoedas(newMoedas);
    localStorage.setItem("moedas_total", newMoedas);
  };

  useEffect(() => {
    /* Função responsável pelo título das páginas */
    document.title = "Re-Render - Arcseed AI";
    return () => {
      document.title = "Arcseed AI"; // Define o título padrão ao desmontar o componente
    };
  }, []);

  const [openAccordion, setOpenAccordion] = useState(null);
  const [openAccordion1, setOpenAccordion1] = useState(null);

  const handleAccordionToggle = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? null : accordionId);
  };

  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.init({
      ns: ["renderComponents", "Footers"]
    });
  }, [i18n]);

  useEffect(() => {
    setOpenCustomization(false);
    setopenFinetuning(false);
  }, [userChoices.type]);

  const handleAccordionToggle1 = (accordionId) => {
    setOpenAccordion1(openAccordion1 === accordionId ? null : accordionId);
  };

  const [openCustomization, setOpenCustomization] = useState(false);
  const [openFinetuning, setopenFinetuning] = useState(false);

  const [isInterior, setIsInterior] = useState(null);

  const renderWallpaperImages = () => {
    const items = [];

    for (let i = 0; i <= Math.ceil(window.innerWidth / 1255) + 3; i++) {
      items.push(
        <img
          alt="Wallpaper not found"
          src={WallpaperGen}
          className="w-auto h-[500px] opacity-60"
        />
      );
    }

    return (
    window.innerWidth < 1024 ? 
      (
        <div className="absolute top-0 left-0 flex flex-col max-h-[700px] overflow-hidden">
          <div className="flex">{items}</div>
          <div className="flex">{items}</div>
        </div>
      )
      :
      ( 
        <div className="absolute top-0 left-0 flex flex-nowrap">{items}</div>
      )
    )
  };

  useEffect(() => {
    sessionStorage.removeItem("GeraPrompt");
    sessionStorage.removeItem("croppedImage");
    sessionStorage.removeItem("nomeImage");
    sessionStorage.removeItem("imageUploader");
    sessionStorage.removeItem("imageUploader2");
  }, [])

  const handleTypeChoose = () => {
    if (userChoices.type !== isInterior) {
      setUserChoices((prevChoices) => ({
        ...prevChoices,
        what: null,
        whatInterior: null,
        whereExterior: null,
        time: null,
        weather: null,
        style: "Modern",
        colorPalette: null,
        materials: null,
        effects: null,
        visibility: "PUBLIC",
        precision: "STANDARD",
        AIImmaginationRange: "3",
        OriginalityRange: "0.9",
        ShapeGuidanceRange: "2",
        ShapeStrengthRange: "1",
        OriginalResemblanceRange: "2",
      }));
      setIsInterior(userChoices.type);
    }

    if (userChoices.type === "INTERIORS") {
      return (
        <div
          className={`ease-in-out transition-all duration-300
        ${userChoices.type === "INTERIORS" ? "opacity-100" : "opacity-0"}`}
        >
          <GenericRenderComponent
          updateUserChoices={updateUserChoices}
          renderParamJSONConfig={whatInteriorsJSON}
          isOpen={true}
          />
          {/* <WhatInteriors
            isOpen={openAccordion1 === "style"}
            onToggle={() => handleAccordionToggle1("style")}
            updateUserChoices={updateUserChoices}
          /> */}
          <div className="w-full justify-center items-center flex">
            <h1
              onClick={() => setOpenCustomization(!openCustomization)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("CUSTOMIZATION")}
            </h1>
          </div>
          <Accordion isOpen={openCustomization}>
            {window.innerWidth < 1024 ? 
            (
              <div className="flex flex-col w-full h-[700px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {renderWallpaperImages()}
              <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={styleInteriorsJSON}
                isOpen={(openAccordion === "styleInteriors")}
                onToggle={() => handleAccordionToggle("styleInteriors")}
                />
                </div>
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeInteriorsJSON}
                isOpen={(openAccordion === "timeInteriors")}
                onToggle={() => handleAccordionToggle("timeInteriors")}
                />
            </div>
            )
            :
            (
            <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {renderWallpaperImages()}
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={styleInteriorsJSON}
                isOpen={(openAccordion === "styleInteriors")}
                onToggle={() => handleAccordionToggle("styleInteriors")}
                />
                {/* <Style
                  isOpen={openAccordion1 === "style"}
                  onToggle={() => handleAccordionToggle1("style")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
              <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeInteriorsJSON}
                isOpen={(openAccordion1 === "timeInteriors")}
                onToggle={() => handleAccordionToggle1("timeInteriors")}
                />
                {/* <Time
                  isOpen={openAccordion === "time"}
                  onToggle={() => handleAccordionToggle("time")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
            </div>
            )}
            <ColorPalette updateUserChoices={updateUserChoices} />
            <Materials updateUserChoices={updateUserChoices} />
          </Accordion>
          <div className="w-full flex justify-center items-center">
            <h1
              onClick={() => setopenFinetuning(!openFinetuning)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("Fine-tuning")}
            </h1>
          </div>
          <Accordion
          isOpen={openFinetuning}>
      <div className="w-screen lg:px-[10vw] flex flex-col lg:flex-row justify-center">
        <div className="lg:w-[50%] lg:flex-col lg:flex lg:justify-center">
        <div className="my-4">
          <AIImmaginationRange
          initialValue={userChoices.AIImmaginationRange}
            updateUserChoices={updateUserChoices} />
        </div>
        <ShapeGuidance
          updateUserChoices={updateUserChoices} />
        </div>
      <div className="lg:w-[50%] lg:flex-col lg:flex lg:justify-center">
        <div className="my-4">
        <Originality 
        initialValue={"0.9"}
        updateUserChoices={updateUserChoices}/>
        </div>
        <ShapeStrength updateUserChoices={updateUserChoices}/>
      </div>
      </div>
      </Accordion>
      <Visibility updateUserChoices={updateUserChoices} />
      <Precision updateUserChoices={updateUserChoices} 
        standardColors={standardColors}
        professionalColors={professionalColors}/>
      <RenderButton
            onRenderButtonClick={handleRender}  // Reference the function here
            userChoices={userChoices}
            onMoedasUpdate={handleMoedasUpdate}
            isPromptEnabled={isPromptEnabled}
          />
        </div>
      );
    } 

    else if (userChoices.type === "PROMPT") {  // Check if Prompt Toggle is enabled
      return (
        <div>
    
          {/* Accordions for Prompt Toggle, similar to INTERIORS */}
          <Accordion isOpen={openCustomization}>
            {window.innerWidth < 1024 ? (
              <div className="flex w-full h-[700px] bg-[#f3f4f6] flex-col max-w-screen justify-center relative items-center">
                {renderWallpaperImages()}
                <div className="mb-2">
                  <GenericRenderComponent
                    updateUserChoices={updateUserChoices}
                    renderParamJSONConfig={timeInteriorsJSON}  // Adjust based on Prompt logic
                    isOpen={openAccordion === "time"}
                    onToggle={() => handleAccordionToggle("time")}
                  />
                </div>
                <div>
                  <GenericRenderComponent
                    updateUserChoices={updateUserChoices}
                    renderParamJSONConfig={styleInteriorsJSON}  // Adjust based on Prompt logic
                    isOpen={openAccordion === "style"}
                    onToggle={() => handleAccordionToggle("style")}
                  />
                </div>
              </div>
            ) : (
              <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
                {renderWallpaperImages()}
                <div className="w-1/2">
                  <GenericRenderComponent
                    updateUserChoices={updateUserChoices}
                    renderParamJSONConfig={timeInteriorsJSON}
                    isOpen={openAccordion === "time"}
                    onToggle={() => handleAccordionToggle("time")}
                  />
                </div>
                <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
                <div className="w-1/2">
                  <GenericRenderComponent
                    updateUserChoices={updateUserChoices}
                    renderParamJSONConfig={styleInteriorsJSON}
                    isOpen={openAccordion1 === "style"}
                    onToggle={() => handleAccordionToggle1("style")}
                  />
                </div>
              </div>
            )}
            <ColorPalette updateUserChoices={updateUserChoices} />
            <Materials updateUserChoices={updateUserChoices} />
          </Accordion>
    
          {/* Fine-tuning section */}
          <div className="flex flex-col justify-center items-center">
            <h1
              onClick={() => setopenFinetuning(!openFinetuning)}
              className={`
              font-title cursor-pointer transition-all ease-in-out 
              duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
              border-solid border-black leading-[99%]`}
            >
              {t("Fine-tuning")}
            </h1>
          </div>
          <Accordion
          isOpen={openFinetuning}>
      <div className="w-screen lg:px-[10vw] flex flex-col lg:flex-row justify-center">
        <div className="lg:w-[50%] lg:flex-col lg:flex lg:justify-center">
        <div className="my-4">
          <AIImmaginationRange
          initialValue={userChoices.AIImmaginationRange}
            updateUserChoices={updateUserChoices} />
        </div>
        <ShapeGuidance
          updateUserChoices={updateUserChoices} />
        </div>
      <div className="lg:w-[50%] lg:flex-col lg:flex lg:justify-center">
        <div className="my-4">
        <Originality 
        initialValue={"0.9"}
        updateUserChoices={updateUserChoices}/>
        </div>
        <ShapeStrength updateUserChoices={updateUserChoices}/>
      </div>
      </div>
      </Accordion>
    
          <Visibility updateUserChoices={updateUserChoices} />
          <Precision
            updateUserChoices={updateUserChoices}
            standardColors={standardColors}
            professionalColors={professionalColors}
          />
    
          {/* Render Button */}
          <RenderButton
            onRenderButtonClick={handleRender}  // Reference the function here
            userChoices={userChoices}
            onMoedasUpdate={handleMoedasUpdate}
            isPromptEnabled={isPromptEnabled}
          />
        </div>
      );
    }
    
    
    
    else if (userChoices.type === "EXTERIORS") {
      return (
        <div
          className={`ease-in-out transition-opacity duration-300
        ${userChoices.type === "EXTERIORS" ? "opacity-100" : "opacity-0"}`}
        >
          {/* <Floors updateUserChoices={updateUserChoices} /> */}
          {/* <WhatExteriors updateUserChoices={updateUserChoices} /> */}
          <GenericRenderComponent
          updateUserChoices={updateUserChoices}
          renderParamJSONConfig={whatExteriorsJSON}
          isOpen={true}
          />
          <div className="flex w-full justify-center items-center">
            <h1
              onClick={() => setOpenCustomization(!openCustomization)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("CUSTOMIZATION")}
            </h1>
          </div>
          <Accordion isOpen={openCustomization}>
            {window.innerWidth < 1024 ? 
            (
              <div className="flex flex-col w-full h-[700px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {renderWallpaperImages()}
              <div className="mb-2">
              <GenericRenderComponent
              updateUserChoices={updateUserChoices}
              renderParamJSONConfig={styleExteriorsJSON}
              isOpen={openAccordion === "styleExteriors"}
              onToggle={() => handleAccordionToggle("styleExteriors")}
              />
              </div>
                {/* <Style
                  isOpen={openAccordion1 === "style"}
                  onToggle={() => handleAccordionToggle1("style")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={whereExteriorsJSON}
                isOpen={openAccordion === "whereExteriors"}
                onToggle={() => handleAccordionToggle("whereExteriors")}
                />
                </div>
                {/* <Where
                  isOpen={openAccordion1 === "where"}
                  onToggle={() => handleAccordionToggle1("where")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeExteriorsJSON}
                isOpen={openAccordion === "timeExteriors"}
                onToggle={() => handleAccordionToggle("timeExteriors")}
                />
                </div>
                {/* <Time
                  isOpen={openAccordion === "timeExteriors"}
                  onToggle={() => handleAccordionToggle("timeExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={weatherJSON}
                isOpen={openAccordion === "weatherExteriors"}
                onToggle={() => handleAccordionToggle("weatherExteriors")}
                />
                {/* <Weather
                  isOpen={openAccordion === "weatherExteriors"}
                  onToggle={() => handleAccordionToggle("weatherExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
            </div>
            )
            :
            (
            <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
              {renderWallpaperImages()}
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
              <div className="mb-2">
              <GenericRenderComponent
              updateUserChoices={updateUserChoices}
              renderParamJSONConfig={styleExteriorsJSON}
              isOpen={openAccordion === "styleExteriors"}
              onToggle={() => handleAccordionToggle("styleExteriors")}
              />
              </div>
                {/* <Style
                  isOpen={openAccordion1 === "style"}
                  onToggle={() => handleAccordionToggle1("style")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={whereExteriorsJSON}
                isOpen={openAccordion === "whereExteriors"}
                onToggle={() => handleAccordionToggle("whereExteriors")}
                />
                {/* <Where
                  isOpen={openAccordion1 === "where"}
                  onToggle={() => handleAccordionToggle1("where")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
              <div className="h-[300px] left-1/2 z-20 translate-x-1/2 absolute w-[0.0625rem] bg-black hidden lg:inline" />
              <div className="flex flex-col h-[100%] justify-center w-[50vw]">
                <div className="mb-2">
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={timeExteriorsJSON}
                isOpen={openAccordion1 === "timeExteriors"}
                onToggle={() => handleAccordionToggle1("timeExteriors")}
                />
                </div>
                {/* <Time
                  isOpen={openAccordion === "timeExteriors"}
                  onToggle={() => handleAccordionToggle("timeExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
                <GenericRenderComponent
                updateUserChoices={updateUserChoices}
                renderParamJSONConfig={weatherJSON}
                isOpen={openAccordion1 === "weatherExteriors"}
                onToggle={() => handleAccordionToggle1("weatherExteriors")}
                />
                {/* <Weather
                  isOpen={openAccordion === "weatherExteriors"}
                  onToggle={() => handleAccordionToggle("weatherExteriors")}
                  updateUserChoices={updateUserChoices}
                /> */}
              </div>
            </div>
            )}
            <ColorPalette updateUserChoices={updateUserChoices} />
            <Materials updateUserChoices={updateUserChoices} />
          </Accordion>
          <div className="flex w-full justify-center items-center">
            <h1
              onClick={() => setopenFinetuning(!openFinetuning)}
              className={`
          font-title cursor-pointer transition-all ease-in-out 
          duration-500 relative font-bold flex flex-col text-2xl border-b-[1px] 
          border-solid border-black leading-[99%]`}
            >
              {t("Fine-tuning")}
            </h1>
          </div>
          <Accordion
          isOpen={openFinetuning}>
      <div className="w-screen lg:px-[10vw] flex flex-col lg:flex-row justify-center">
        <div className="lg:w-1/2 lg:flex lg:flex-col lg:justify-center lg:items-center">
        <div className="my-4">
          <AIImmaginationRange
          initialValue={userChoices.AIImmaginationRange}
            updateUserChoices={updateUserChoices} />
        </div>
          <ShapeGuidance
            updateUserChoices={updateUserChoices} />
        </div>
      <div className="lg:w-1/2 lg:flex lg:flex-col lg:justify-center lg:items-center">
        <div className="my-4">
        <Originality 
        initialValue={"0.9"}
        updateUserChoices={updateUserChoices}/>
        </div>
        <ShapeStrength updateUserChoices={updateUserChoices}/>
      </div>
      </div>
      <div className="lg:flex lg:justify-center lg:w-full mt-4">
        <OriginalResemblance 
        initialValue={"3"}
        updateUserChoices={updateUserChoices}/>
      </div>
      </Accordion>
      <Visibility updateUserChoices={updateUserChoices} />
      <Precision updateUserChoices={updateUserChoices}
        standardColors={standardColors}
        professionalColors={professionalColors} />
      <RenderButton
            onRenderButtonClick={handleRender}  // Reference the function here
            userChoices={userChoices}
            onMoedasUpdate={handleMoedasUpdate}
            isPromptEnabled={isPromptEnabled}
          />
        </div>
      );
    } else if (userChoices.type === null) {
      return (
        <div className="flex w-full max-w-screen my-2 justify-center">
          <p className="font-paragraph px-6 lg:px-0 text-2xl">
            {t(
              "Tip: select the ambient type to access the other inputs!"
            )}
          </p>
        </div>
      );
    }
  };

  return (
    <div className="overflow-hidden">
      <NewPopUpComponent
      isClosable={true}/>
      <Navbar
        title="RE-RENDER"
        userChoices={userChoices}
        description="Upload a render you did or any image to make it look better, this is perfect for old renders with bad quality, they will look stunning now! if you are an advanced user click here for the advanced toolset or turn the advanced toolset on your settings page here ,there all the generative pages will be in one place for fast editing and access."
        showPoints={true}
        moedas={moedas}
      />
      <div 
      style={{
        marginTop: navbarHeight
      }}>
        <ImageUploader
          updateUserChoices={updateUserChoices}
          subtitle={
            "You can take a screenshot (PrtScr no teclado) and paste it here with Ctrl+v, to crop it below."
          }
          title={"Drop the image or screenshot here"}
        />
      </div>
      <ImageCropper
        image={sessionStorage.getItem("imageUploader")}
        updateUserChoices={updateUserChoices}
      />

      {/* Encapsulate Prompt in a light gray box with fixed width and animation */}
<div 
  className={`p-10  mt-10 max-w-4xl mx-auto transition-all duration-500 ${isPromptEnabled ? 'bg-gray-100' : 'bg-black'}`}
> 
  {/* Prompt Field */}
  <div className="flex flex-col justify-center items-center">
    <label 
      htmlFor="promptInput" 
      className={`font-bold font-title flex items-center text-3xl transition-all duration-500 ${isPromptEnabled ? 'text-black' : 'text-white'}`}
    >
      PROMPT
      <div className="w-[1px] h-[1px] relative">
        <InfoBox
          customClass="absolute top-1/2 -translate-y-1/2 left-2"
          text={t("Prompts give you more control over the AI, They should be in English and have a 120 characters limit.")} 
        />
      </div>
    </label>

    <input
      id="promptInput"
      type="text"
      maxLength={120}
      value={promptInput}
      onChange={handleInputChange}
      disabled={!isPromptEnabled}  // Disable when the toggle is off
      className={`border-b-4 transition-all duration-500 ${isPromptEnabled ? 'border-black text-black bg-transparent' : 'border-white text-white bg-black'} mt-5 p-2 focus:outline-none w-full`}
      placeholder={t("Enter your Prompt here...")}
    />

    {/* Add a Translate Button */}
    <button
  onClick={handleTranslateClick}
  className={`bg-gray-500 hover:bg-gray-700 text-white font-bold mt-9 py-2 px-4 ${!isPromptEnabled || promptInput === previousTranslatedText || isTranslating ? 'opacity-50 cursor-not-allowed' : ''}`}
  disabled={!isPromptEnabled || promptInput === previousTranslatedText || isTranslating}  // Disable based on conditions
  aria-label={t("Translate prompt to English")}
>
{isTranslating ? t("TRANSLATING...") : t("TRANSLATE TO ENGLISH")}
</button>


    {/* Error message */}
    {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
  </div>

  {/* Centered Toggle with Bigger Size */}
  <div className="mt-10 flex flex-col justify-center items-center">
    <label 
      htmlFor="promptToggle" 
      className={`block text-md mb-5 font-bold transition-all duration-500 ${isPromptEnabled ? 'text-black' : 'text-white'}`}
    >
      {t("CLICK TO TURN THE PROMPT TOOL ON!")}<br /> {t("The Prompt has to be in english, click TRANSLATE TO ENGLISH if you need a translation.")}
    </label>

    {/* Use the PromptToggleOrMessage function here */}
    <PromptToggleOrMessage 
      isPromptEnabled={isPromptEnabled} 
      handlePromptToggle={handlePromptToggle} 
      t={t} 
   />
  </div>
</div>
  
      {/* Other Options Section */}
      <div className={`mt-[${navbarHeight}px]`}></div>
      <div className="flex lg:flex-row flex-col w-full py-8 items-center justify-center max-w-screen">
        <div 
          className="w-1/3"
          style={{
            opacity: isPromptEnabled ? 0 : 1,
            transition: 'opacity 0.5s ease-in-out',
            visibility: isPromptEnabled ? 'hidden' : 'visible',
          }}
        >
          <AIModels verticalSet={isSmallScreen} updateUserChoices={updateUserChoices} />
        </div>
  
        <div className="w-[0.06rem] h-[19rem] bg-black hidden lg:inline"></div>
  
        <div className="w-1/3">
          <Seed updateUserChoices={updateUserChoices} />
        </div>
  
        <div className="w-[0.06rem] h-[19rem] bg-black hidden lg:inline"></div>
  
        <div 
          className="w-1/3"
          style={{
            opacity: isPromptEnabled ? 0 : 1,
            transition: 'opacity 0.5s ease-in-out',
            visibility: isPromptEnabled ? 'hidden' : 'visible',
          }}
        >
          <Type
            userChoice={userChoices.type}
            verticalSet={isSmallScreen}
            className="mt-8"
            updateUserChoices={updateUserChoices}
          />
        </div>
      </div>
  
      {handleTypeChoose()}
      <Footer footerVersion="light-footer" renderTitle="Sketch 2 Render" />
    </div>
  );
  
}