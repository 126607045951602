import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import Cookies from "js-cookie";
import { add_Back } from "../../address";
import { jwtDecode } from "jwt-decode";
const stripePromise = loadStripe(
  "pk_live_51PiHIWRuWqLo33c7oSRNcbB0NIl0pHEFfLX5ZWVbRt1Xq1sfRpXL8JWPqqH5HgOYQsHZoqVG7JsiedC1yBniiQO800PBeyCFKz"
);

const MoedasExtra = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isRequesting, setIsRequesting] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [requestSent, setRequestSent] = useState(false); // Estado para controlar a requisição

  const verificarToken = () => {
    const token = Cookies.get("token");
    if (!token) {
      return false;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const redirectToLogin = () => {
    window.location.href = "/Login";
  };

  if (!verificarToken()) {
    redirectToLogin();
  }
  let planId, price, moedas, clienteId;
  let requestBody;
  const token = Cookies.get("token");

  try {
    // Obtém a string de consulta codificada em Base64 da URL
    const searchParams = new URLSearchParams(location.search);
    const encodedParams = searchParams.get("data");
    if (encodedParams) {
      // Decodifica a string Base64
      const decodedString = atob(encodedParams);

      // Converte a string decodificada em parâmetros de consulta
      const params = new URLSearchParams(decodedString);

      // Obtém os valores dos parâmetros
      planId = params.get("plan");
      price = params.get("price");
      moedas = params.get("moedas");

      // Decodifica o token JWT e obtém o clienteId
     
      const decodedToken = jwtDecode(token);
      clienteId = decodedToken.clienteId;

      // Cria o objeto requestBody com os valores
      requestBody = {
        price: price,
        planId: planId,
        moedas: moedas,
        clienteId: clienteId,
      };
    } else {
      navigate("/");
    }
  } catch (error) {
    console.error("Erro ao processar os parâmetros da URL:", error);
    navigate("/"); // Redireciona para a página inicial em caso de erro
  }

  useEffect(() => {
    if (!planId || !price || !moedas || !clienteId) {
      navigate("/");
    }
  }, [planId, price, moedas, clienteId, navigate]);

  sessionStorage.setItem("planId", planId);
  const fetchClientSecret = useCallback(async () => {
    if (requestSent) return; // Evita que a requisição seja feita mais de uma vez

    setIsRequesting(true);
    setRequestSent(true); // Marca que a requisição foi enviada
    try {
      const response = await fetch(`${add_Back}/moedasExtra`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      console.log("Resposta da requisição:", data);

      if (data.error === "Alterou o plan id.") {
        alert("Opção de moedas indisponível para este plano.");
        window.location.href = "/Dashboard/initial";
        return;
      }

      if (data.error === "Plano inválido.") {
        alert("Plano inválido.");
        window.location.href = "/Dashboard/initial";
        return;
      }

      if (data.error === "Preço inválido para o plano.") {
        alert("Preço inválido para o plano.");
        window.location.href = "/Dashboard/initial";
        return;
      }

      if (data.error === "Preço inválido.") {
        alert("Preço inválido.");
        window.location.href = "/Dashboard/initial";
        return;
      }

      if (data.error) {
        alert("Erro ao processar pagamento.");
        window.location.href = "/Dashboard/initial";
        return;
      }

      if (data.clientSecret) {
        setClientSecret(data.clientSecret);
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
    } finally {
      setIsRequesting(false); // Marca que a requisição terminou
    }
  }, [token, requestBody, requestSent]);

  useEffect(() => {
    if (requestBody && !requestSent) {
      fetchClientSecret();
    }
  }, [fetchClientSecret, requestBody, requestSent]);

  return (
    <div className="App">
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default MoedasExtra;
