import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const images = Array.from({ length: 5 }, (_, i) =>
  require(`../../../../assets/animations/bsg/bsg_${String(i + 1).padStart(3, '0')}.webp`)
);

const animationDuration = 6; // Total time for the movement/scale animation
const fadeOutStartTime = 1; // Time to start the fade-out within the animation duration
const fadeOutDuration = 2; // Duration of the fade-out effect

const animations = [
  { x: ['-10%', '10%'], y: '0%', scale: [1, 1] },               // Horizontal movement for Image 1
  { x: '0%', y: '0%', scale: [0.9, 1.1] },                       // Zoom for Image 2
  { x: '0%', y: ['10%', '-10%'], scale: [1, 1] },                // Vertical movement for Image 3
  { x: ['10%', '-10%'], y: '0%', scale: [1, 1] },                // Horizontal movement for Image 4
  { x: '0%', y: '0%', scale: [1.1, 1.0] },                         // Zoom out for Image 5
];

const MovingImageBgen = () => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, animationDuration * 800); // Corrected timing interval to use only `animationDuration`

    return () => clearInterval(interval);
  }, []);

  const nextImage = (currentImage + 1) % images.length;

  return (
    <div style={{ position: 'relative', minWidth: '600px', minHeight: '600px', margin: '0 auto', overflow: 'hidden' }}>
      {/* Bottom layer (nextImage), animating immediately */}
      <motion.div
        key={nextImage}
        initial={{ opacity: 1 }}
        animate={{
          x: Array.isArray(animations[nextImage].x) ? animations[nextImage].x[1] : animations[nextImage].x,
          y: Array.isArray(animations[nextImage].y) ? animations[nextImage].y[1] : animations[nextImage].y,
          scale: Array.isArray(animations[nextImage].scale) ? animations[nextImage].scale[1] : animations[nextImage].scale,
        }}
        transition={{
          x: { duration: animationDuration },
          y: { duration: animationDuration },
          scale: { duration: animationDuration },
        }}
        style={{
          backgroundImage: `url(${images[nextImage]})`,
          backgroundSize: 'cover',
          width: '1200px',
          height: '800px',
          position: 'absolute',
          top: '-100px',
          left: '-300px',
        }}
      />

      {/* Top layer (currentImage) with movement and delayed fade-out */}
      <motion.div
        key={currentImage}
        initial={{ opacity: 1 }}
        animate={{
          opacity: 0,
          x: Array.isArray(animations[currentImage].x) ? animations[currentImage].x[1] : animations[currentImage].x,
          y: Array.isArray(animations[currentImage].y) ? animations[currentImage].y[1] : animations[currentImage].y,
          scale: Array.isArray(animations[currentImage].scale) ? animations[currentImage].scale[1] : animations[currentImage].scale,
        }}
        transition={{
          x: { duration: animationDuration },
          y: { duration: animationDuration },
          scale: { duration: animationDuration },
          opacity: { delay: fadeOutStartTime, duration: fadeOutDuration },
        }}
        style={{
          backgroundImage: `url(${images[currentImage]})`,
          backgroundSize: 'cover',
          width: '1200px',
          height: '800px',
          position: 'absolute',
          top: '-100px',
          left: '-300px',
        }}
      />
    </div>
  );
};

export default MovingImageBgen;