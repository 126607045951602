import { useState } from "react";
import { FaArrowDown } from "react-icons/fa";
import { Link } from "react-scroll";
import { add_Front } from "../../address";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { Trans, useTranslation } from "react-i18next";

export default function PlanInfoComponents({ param }) {
  const planContents = {
    BEGINNER: {
      price: "0",
      benefits: (
        <ul>
          <li>
            <Trans i18nKey="Up to 20 renders per month*">
              Up to
              <span className="text-green-700"> 20</span> renders per month*
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Standard rendering*¹">
              Standard rendering*¹ 
            </Trans>
          </li>
          <li>
            <Trans i18nKey="50MB of storage">
              <span className="text-green-700">50MB</span> of storage
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Single login">
              Single login
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Brainstorm Gen">
              Brainstorm Gen
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Sketch 2 Render">
              Sketch 2 Render
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Canvas Alchemy">
              Canvas Alchemy
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Re-render">
              Re-render
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Re-decorate (beta)">
              Re-decorate (beta)
            </Trans>
          </li>
        </ul>
      ),
      observation: (
        <p>
          <Trans i18nKey="10 extra renders per registered guest">
            <span className="text-green-700">10 </span>
            extra renders per registered guest
          </Trans>
        </p>
      ),
    },
    ENTHUSIAST: {
      price: "46.90",
      benefits: (
        <ul>
          <li>
            <Trans i18nKey="Access to features under development">
              Access to features under development
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Up to 300 renders per month*">
              Up to
              <span className="text-green-700"> 300</span> renders per month*
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Standard rendering*¹">
              Standard rendering*¹
            </Trans>
          </li>
          <li>
            <Trans i18nKey="PRO rendering*¹">
              PRO rendering*¹
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Refinements*²">
              Refinements*²
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Private images*³">
              Private images*³
            </Trans>
          </li>
          <li>
            <Trans i18nKey="500MB of storage">
              <span className="text-green-700">500</span>MB of storage
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Advanced renderer*⁴">
              Advanced renderer*⁴
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Priority support">
              Priority support
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Single login">
              Single login
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Purchase extra credit">
              Purchase extra credit
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Brainstorm Gen">
              Brainstorm Gen
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Sketch 2 Render">
              Sketch 2 Render
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Canvas Alchemy">
              Canvas Alchemy
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Re-render">
              Re-render
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Re-decorate (beta)">
              Re-decorate (beta)
            </Trans>
          </li>
        </ul>
      ),
      observation: (
        <p>
          <Trans i18nKey="R$15.00 for 100 extra render points">
            <span className="text-green-700">R$15.00 </span>
            for <span className="text-green-700">100</span> extra render points
          </Trans>
        </p>
      ),
    },
    PROFESSIONAL: {
      price: "98.90",
      benefits: (
        <ul>
          <li>
          <li>
            <Trans i18nKey="Access to features under development">
              Access to features under development
            </Trans>
          </li>
            <Trans i18nKey="Up to 1000 renders per month*">
              Up to
              <span className="text-green-700"> 1000</span> renders per month*
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Standard rendering*¹">
              Standard rendering*¹
            </Trans>
          </li>
          <li>
            <Trans i18nKey="PRO rendering*¹">
              PRO rendering*¹
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Refinements*²">
              Refinements*²
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Private images*³">
              Private images*³
            </Trans>
          </li>
          <li>
            <Trans i18nKey="1GB of storage">
              <span className="text-green-700">1</span>GB of storage
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Advanced renderer*⁴">
              Advanced renderer*⁴
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Priority support">
              Priority support
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Single login">
              Single login
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Purchase extra credit">
              Purchase extra credit
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Brainstorm Gen">
              Brainstorm Gen
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Sketch 2 Render">
              Sketch 2 Render
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Canvas Alchemy">
              Canvas Alchemy
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Re-render">
              Re-render
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Re-decorate (beta)">
              Re-decorate (beta)
            </Trans>
          </li>
        </ul>
      ),
      observation: (
        <p>
          <Trans i18nKey="R$30.00 for 300 extra render points">
            <span className="text-green-700">R$30.00 </span>
            for <span className="text-green-700">300</span> extra render points
          </Trans>
        </p>
      ),
    },
    ENTERPRISE: {
      price: "298.90",
      benefits: (
        <ul>
          <li>
            <Trans i18nKey="Access to features under development">
              Access to features under development
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Up to 4000 renders per month*">
              Up to
              <span className="text-green-700"> 4000</span> renders per month*
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Standard rendering*¹">
              Standard rendering*¹
            </Trans>
          </li>
          <li>
            <Trans i18nKey="PRO rendering*¹">
              PRO rendering*¹
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Refinements*²">
              Refinements*²
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Private images*³">
              Private images*³
            </Trans>
          </li>
          <li>
            <Trans i18nKey="2GB of storage">
              <span className="text-green-700">2</span>GB of storage
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Advanced renderer*⁴">
              Advanced renderer*⁴
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Priority support">
              Priority support
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Additional logins available*⁵">
              Additional logins available*⁵
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Purchase extra credit">
              Purchase extra credit
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Brainstorm Gen">
              Brainstorm Gen
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Sketch 2 Render">
              Sketch 2 Render
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Canvas Alchemy">
              Canvas Alchemy
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Re-render">
              Re-render
            </Trans>
          </li>
          <li>
            <Trans i18nKey="Re-decorate (beta)">
              Re-decorate (beta)
            </Trans>
          </li>
        </ul>
      ),
      observation: (
        <p>
          <Trans i18nKey="R$75.00 for 1000 extra render points">
            <span className="text-green-700">R$75.00 </span>
            for <span className="text-green-700">1000</span> extra render points
          </Trans>
        </p>
      ),
    },
  }

  const planIds = {
    BEGINNER: 1,
    ENTHUSIAST: 2,
    PROFESSIONAL: 3,
    ENTERPRISE: 4,
  };

  const { t } = useTranslation();

  const handleCheckout = (planId) => {
    const storedPlanId = localStorage.getItem("id_plan");

    function verificarToken() {
      const token = Cookies.get("token");
      if (!token) {
        // Token não encontrado no localStorage
        return false;
      }

      // Decodificar o token para verificar sua validade
      try {
        const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
        const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

        // Verificar se o token expirou
        if (decodedToken.exp < currentTime) {
          // Token expirado
          return false;
        }

        // Token válido
        return true;
      } catch (error) {
        //console.error('Erro ao decodificar o token:', error);
        return false; // Token inválido
      }
    }

    // Função para redirecionar para a página de login
    function redirectToLogin() {
      window.location.href = "/Login"; // Redireciona para a página de login
    }

    // Verificar o token ao carregar a página
    if (!verificarToken()) {
      redirectToLogin(); // Redireciona para a página de login se o token não for válido
    }

    if (storedPlanId && parseInt(storedPlanId) === planId) {
      alert("Você já está no plano selecionado.");
      return;
    }

    if (parseInt(storedPlanId) !== 1) {
      const confirmPlanChange = window.confirm(
        "Ao selecionar este plano, ele será alterado automaticamente ao final da assinatura atual. Deseja continuar?"
      );
      if (!confirmPlanChange) {
        return;
      }
    }

    const confirmSelection = window.confirm(
      "Tem certeza de que deseja escolher este plano?"
    );
    if (!confirmSelection) {
      return;
    }
      // Cria os parâmetros a serem codificados
      const params = new URLSearchParams({
        planId: planId,
      });

      // Codifica os parâmetros em Base64
      const encodedParams = btoa(params.toString());

      // Cria a URL com os parâmetros codificados
      const url = `${add_Front}/Return?data=${encodedParams}`;

      // Redireciona para a URL gerada
      window.location.href = url;

  };

  const [userChoice, setUserChoice] = useState(t(param));

  return (
    <div
      className="flex lg:flex-row flex-col justify-center items-center w-full 
    max-w-screen lg:h-screen bg-slate-100 relative py-[15%] lg:py-0"
    >
      {Object.entries(planContents).map(([index]) => (
        <div
          key={t(index)}
          onMouseEnter={() => setUserChoice(t(index))}
          onMouseLeave={() => setUserChoice(param)}
          className="lg:h-[80vh] lg:w-[23vw] h-[60vh] w-[80vw]
          bg-white border border-solid my-[7%] lg:my-0 
        border-gray-300 p-7 mx-2 flex flex-col relative mt-[15%]"
        >
          {t(index) === t(param) && t(param) !== "undefined" ? (
            <div
              className="absolute top-0 right-0 
            bg-green-700 h-fit py-3 font-title text-white font-bold w-full
            -translate-y-[100%] text-[90%] animate-fade"
            >
              {t("YOUR CHOICE")}
            </div>
          ) : (
            index === "PROFESSIONAL" && (
              <div
                className="absolute top-0 right-0 
            bg-green-700 h-fit py-3 font-title text-white font-bold w-full
            -translate-y-[100%] text-[90%] animate-fade"
              >
                {t("BEST COST BENEFIT")}
              </div>
            )
          )}
          <div
            className={`font-title lg:text-[1.6vw] text-[170%] transition-colors ease-in-out duration-300
            ${userChoice === index || (param === "undefined" && index === "PROFISSIONAL") ? "text-green-700" : "text-gray-500"}`}
          >
            <h1
              className="mb-6 text-[100%]
            "
            >
              {t(index)}
            </h1>
            <div className="flex px-2 items-start justify-center">
              <p className="text-start mr-1">R$</p>
              <p className="lg:text-[3vw] text-[200%] leading-8">
                {planContents[index].price}
              </p>
              <Trans
              i18nKey={"Month"}>
              <p 
              className="lg:text-[0.8vw] text-[65%] self-end">/Month</p>
              </Trans>
            </div>
          </div>
          <div className="bg-gray-500 w-[100%] h-0.5 lg:mb-4 lg:mt-6 mt-6" />
          <div
            className="flex-col flex justify-start font-title text-[78%] lg:text-[97%] text-gray-500
          tracking-widest lg:leading-[1.6] leading-5 text-start  overflow-auto custom-scrollbar"
          >
            {planContents[index].benefits}
          </div>
          <div className="bg-gray-500 w-[100%] h-0.5 lg:my-4 mb-4" />
          <p className="font-title text-[70%] text-gray-500 tracking-widest leading-[1.6]">
            {planContents[index].observation}
          </p>
          <div className="w-[100%] justify-center">
            <div>
              {(localStorage.getItem("plano") !== null) ? (
                ((t(localStorage.getItem("plano").toUpperCase()) !== t(index) && 
                t(index.toUpperCase()) !== t("BEGINNER"))) && (
                <Trans
                i18nKey={"Choose"}>
                <button
                  onClick={() => handleCheckout(planIds[index])}
                  className="font-title text-[130%] p-3 cursor-pointer 
                bg-gray-500 text-white w-fit mt-2 transition-colors 
                ease-in-out duration-300 hover:bg-green-700"
                >
                  Choose
                </button>
                </Trans>
                ))
                :
                (
                  <Trans
                  i18nKey={"Choose"}>
                  <button
                  onClick={() => handleCheckout(planIds[index])}
                  className="font-title text-[130%] p-3 cursor-pointer 
                bg-gray-500 text-white w-fit mt-2 transition-colors 
                ease-in-out duration-300 hover:bg-green-700"
                >
                  Choose
                </button>
                </Trans>
                )}
            </div>
          </div>
          {(index === "PROFISSIONAL" || index === param) && (
            <div className="absolute bottom-0 w-full h-[1%] bg-green-700 right-0 animate-fade animate-once" />
          )}
        </div>
      ))}
      <div className="absolute bottom-0 w-full flex justify-center">
        <Link to="info-container" smooth={true} offset={0} duration={750}>
          <FaArrowDown
            size={40}
            color="green"
            className="opacity-70 cursor-pointer mb-1"
          />
        </Link>
      </div>
    </div>
  );
}