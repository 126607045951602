import React, { useState, useContext, useEffect } from "react";
import { imageDefault } from '../../assets/imageCarregando.js';
import { imagepreta } from '../../assets/imagempreta.js';
import Cookies from 'js-cookie';
import notAvailable from "../../assets/notAvailable.jpg";
import { add_Back } from '../../address.js';
import { IoMdClose } from "react-icons/io";
import Accordion from "./Accordion";
import { FaDownload } from "react-icons/fa6";
import { jwtDecode } from "jwt-decode";

// Importar  "Upscale"
import { geraPrompt as geraPrompt1024x1024 } from '../../apiComfy/Upscale/Upscale_1024x1024.js';
import { geraPrompt as geraPrompt1536x1536 } from '../../apiComfy/Upscale/Upscale_1536x1536.js';

import { geraPrompt as geraPrompt1024x1344 } from '../../apiComfy/Upscale/Upscale_1024x1344.js';
import { geraPrompt as geraPrompt1344x1024 } from '../../apiComfy/Upscale/Upscale_1344x1024.js';

import { geraPrompt as geraPrompt1024x1536 } from '../../apiComfy/Upscale/Upscale_1024x1536.js';
import { geraPrompt as geraPrompt1536x1024 } from '../../apiComfy/Upscale/Upscale_1536x1024.js';

import { geraPrompt as geraPrompt1224x816 } from '../../apiComfy/Upscale/Upscale_1224x816.js';
import { geraPrompt as geraPrompt816x1244 } from '../../apiComfy/Upscale/Upscale_816x1224.js';

import { geraPrompt as geraPrompt1224x1840 } from '../../apiComfy/Upscale/Upscale_1224x1840.js';
import { geraPrompt as geraPrompt1840x1224 } from '../../apiComfy/Upscale/Upscale_1840x1224.js';

import { geraPrompt as geraPrompt1336x1752 } from '../../apiComfy/Upscale/Upscale_1336x1752.js';
import { geraPrompt as geraPrompt1752x1336 } from '../../apiComfy/Upscale/Upscale_1752x1336.js';

// Importar  "Canvas"
import { geraPrompt as geraPromptCanvas1024x1024 } from '../../apiComfy/Upscale/Upscale_Canvas_1024x1024.js';
import { geraPrompt as geraPromptCanvas1536x1536 } from '../../apiComfy/Upscale/Upscale_Canvas_1536x1536.js';

import { geraPrompt as geraPromptCanvas1024x1344 } from '../../apiComfy/Upscale/Upscale_Canvas_1024x1344.js';
import { geraPrompt as geraPromptCanvas1344x1024 } from '../../apiComfy/Upscale/Upscale_Canvas_1344x1024.js';

import { geraPrompt as geraPromptCanvas1024x1536 } from '../../apiComfy/Upscale/Upscale_Canvas_1024x1536.js';
import { geraPrompt as geraPromptCanvas1536x1024 } from '../../apiComfy/Upscale/Upscale_Canvas_1536x1024.js';

import { geraPrompt as geraPromptCanvas1224x816 } from '../../apiComfy/Upscale/Upscale_Canvas_1224x816.js';
import { geraPrompt as geraPromptCanvas816x1224 } from '../../apiComfy/Upscale/Upscale_Canvas_816x1224.js';

import { geraPrompt as geraPromptCanvas1224x1840 } from '../../apiComfy/Upscale/Upscale_Canvas_1224x1840.js';
import { geraPrompt as geraPromptCanvas1840x1224 } from '../../apiComfy/Upscale/Upscale_Canvas_1840x1224.js';

import { geraPrompt as geraPromptCanvas1336x1752 } from '../../apiComfy/Upscale/Upscale_Canvas_1336x1752.js';
import { geraPrompt as geraPromptCanvas1752x1336 } from '../../apiComfy/Upscale/Upscale_Canvas_1752x1336.js';
import { useTranslation } from "react-i18next";
import { Context } from "../../App.js";



let url = window.location.href;
console.log("URLZINHA: ", url);

//import { geraPrompt } from '../'
// import { geraPrompt as geraPromptPortrait } from '../apiComfy/Brain/Brain_Portrait_Pro.js';
// import { geraPrompt as geraPromptSquare } from '../apiComfy/Brain/Brain_Square_Pro.js';

//---------------------------------------------------------------------------
class ImageDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: null
    };
  }

  componentDidMount() {
    // Aqui você irá extrair a URL da imagem do JSON
    const { imageBase64 } = this.props;
    // Configurar a URL da imagem para exibir no componente
    this.setState({ imageUrl: `data:image/png;base64,${imageBase64}` });
  }

  render() {
    const { imageUrl } = this.state;
    return (
      <div>
        {imageUrl && <img src={imageUrl} alt="Imagem" />}
        {!imageUrl && <p>Carregando...</p>}
      </div>
    );
  }
}
//--------------------------------------------------------------------------

async function fetchGeraImagemComfy(options) {
  try {
    const response = await fetch(`${add_Back}/ia/ia2`, options);
    const data = await response.json();
    console.log('Data fetch gera imagemcomfy:', data);

    // Verifica se a imagem foi gerada com sucesso
    if (data && data.image) {
      localStorage.setItem("moedas_total", data.moedas || 0);
      return { "erro": 0, "msg": data.image, "moedas": data.moedas || 0 };
    } else if (data.msg) {
      console.warn('Erro retornado pelo servidor:', data.msg);
      return { "erro": 1, "msg": data.msg };
    } else {
      console.warn('Resposta inesperada do servidor:', data);
      return { "erro": 1, "msg": "Resposta inesperada do servidor" };
    }
  } catch (error) {
    console.error('Erro na requisição:', error);
    return { "erro": 1, "msg": 'Erro de conexão ou resposta inválida.' };
  }
}

//---------------------------------------------------------------------------

function RenderButton({ onRenderButtonClick, userChoices, onMoedasUpdate }) {
  const [isRendering, setIsRendering] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const [imageURL, setImageURL] = useState(imagepreta);
  const [imageUpscale, setImageUpscale] = useState(imagepreta);
  const [alerta, setAlerta] = useState(null);
  const [upscale, setUpscale] = useState(false);
  const [moedas, setMoedas] = useState(0);
  const [isUpscaling, setIsUpscaling] = useState(false);
  const [currentImageSrc, setCurrentImageSrc] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  //---------------------------------------------------------------------------
  const handleHovering = () => {
    setIsHovering(!isHovering);
  };

  const handleDownloadClick = (image) => {
    const token = Cookies.get("token");
    const decodedToken = jwtDecode(token);
    const nick_name = decodedToken.nick_name;


    const link = document.createElement("a");
    link.href = currentImageSrc;
    link.download = `${nick_name}_${Date.now()}.jpg`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const handleAccordionOpen = async () => {
    if (isRendering) {
      return;
    }
    console.log("a");
    setImageUpscale(imagepreta);
    onRenderButtonClick();
    console.log("b")
    setIsRendering(true); // Inicia o processo de renderização

    setImageURL(imageDefault); // fica girandinho preto

    let nomeImage = sessionStorage.nomeImage; // colcoa na session
    //sessionStorage.removeItem('nomeImage'); // tira da session

    let nomeImage2 = sessionStorage.nomeImage2; // colcoa na session
    //sessionStorage.removeItem('nomeImage2'); // tira da session
    //console.log('Nome da imagem no render button: ',nomeImage);

    let GerapromptSaida;

    try {
      // setAlerta("JSONFormatting")
      GerapromptSaida = JSON.parse(sessionStorage.GeraPrompt); // colcoa na session
      //sessionStorage.removeItem('GeraPrompt'); // tira da session
    }
    catch (error) {
      setIsRendering(false)
      setAlerta(null)
      // setAlerta("AIOffError");
      // setIsRendering(false);
      // return;
    }

    //-------------------------------INICIO VALIDAÇÃO DE COMPONENTES -------------------------------------
    // condição de imageUploader extra para que pegue tambem na Brainstorm Gen
    if (
      userChoices.imageUploader !== undefined && (
        userChoices.imageUploader === null ||
        sessionStorage.getItem("imageUploader") === null
      )
    ) {
      // Se houver ImageUploader mas nenhuma imagem carregada, exibir mensagem e interromper o processo
      setAlerta("imageNull");
      setIsRendering(false); // Inicia o processo de renderização
      return; // Sair da função se não houver imagens carregadas no sessionStorage
    }

    // Verificando se o segundo campo do imageUploader é nulo e existe
    if (userChoices.imageUploader2 !== undefined && (
      userChoices.imageUploader2 === null ||
      sessionStorage.getItem("imageUploader2") === null
    )) {
      setAlerta("imageNull");
      setIsRendering(false); // Inicia o processo de renderização
      return; // Sair da função se não houver imagens carregadas no sessionStorage
    }

    // Verifique se imageCropper é nulo e existe
    if (userChoices.imageCropper !== undefined &&
      userChoices.imageCropper === null) {
      setAlerta("cropperNull");
      setIsRendering(false); // Inicia o processo de renderização
      return;
    }

    // Verifique se type é nulo e se existe
    if (userChoices.type !== undefined &&
      userChoices.type === null) {
      setAlerta("typeNull");
      setIsRendering(false); // Inicia o processo de renderização
      return; // Sair da função se type for nulo
    }

    if (userChoices.what !== undefined &&
      userChoices.what === null) {
      setAlerta("whatNull");
      setIsRendering(false);
      return;
    }

    // Verifique se userChoices.aiModels não é nulo
    if (userChoices.aiModels !== undefined &&
      userChoices.aiModels === null) {
      setAlerta("modelNull");
      setIsRendering(false); // Inicia o processo de renderização
      return; // Sair da função se a semente for nula
    }

    // Verifique se userChoices.aiModels não é nulo
    if (userChoices.visibility !== undefined &&
      userChoices.visibility === null) {
      setAlerta("visibilityNull")
      setIsRendering(false); // Inicia o processo de renderização
      return; // Sair da função se a semente for nula
    }

    //-------------------------------------FIM VALIDAÇÃO DE COMPONENTES -------------------------------------

    setAlerta("renderState");
    setIsRendering(true);
    url = window.location.href;
    const parts = url.split("/");
    let afterSlash = parts.slice(3).join("/");
    if (userChoices.precision === "PROFESSIONAL") {
      afterSlash = afterSlash + "Pro";
    }

    const token = Cookies.get("token");
    const decodedToken = jwtDecode(token);
    const user = decodedToken.clienteId;

    let JsonBody = {
      user: user,
      page: afterSlash,
      userchoices: userChoices,

    };
    if (userChoices.imageUploader && sessionStorage.getItem("croppedImage")) {
      if (nomeImage) {
        JsonBody.imageName = nomeImage;
        let image64 = sessionStorage.croppedImage;
        // sessionStorage.removeItem('croppedImage'); // tira da session
        image64 = image64.replace(/^data:image\/\w+;base64,/, '');
        JsonBody.image = image64;
      }
    }
    if (userChoices.imageUploader2 && sessionStorage.getItem("imageUploader2")) {
      if (nomeImage2) {
        JsonBody.imageName2 = nomeImage2;
        let image642 = sessionStorage.imageUploader2;
        image642 = image642.replace(/^data:image\/\w+;base64,/, '');
        JsonBody.image2 = image642;
      }
    }
    //const jsonBody = {};
    console.log('Json: ', JsonBody);

    const optionFetchGeraImagemComfy = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(JsonBody)
    };
    
    // Chamando a função de fetch e tratando a resposta
    console.log("Opções para fetch:", optionFetchGeraImagemComfy);
    
    const response = await fetchGeraImagemComfy(optionFetchGeraImagemComfy);
    if (response.erro) {
      console.warn('Erro ao gerar imagem, mostrando imagem preta e alerta de erro.');
      setImageURL(imagepreta);
      setAlerta("serverError");
    } else {
      setImageURL(response.msg);
      onMoedasUpdate(response.moedas);
      setWarningMessage(null);
      setAlerta(null);
    }
    setIsRendering(false);
    
    console.log('Resposta final do fetch:', response);
  };
  //---------------------------------------------------------------------------
  isOpen ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";
  //---------------------------------------------------------------------------

  const [warningMessage, setWarningMessage] = useContext(Context);

  function handleAlertMessage(alerta) {
    switch (alerta) {
      case "JSONFormatting":
        setWarningMessage("Formatting AI input...");
        setAlerta(null)
        break;
      case "serverError":
        setWarningMessage("Server under maintenance");
        setAlerta(null)
        break;
      case "cropperNull":
        setWarningMessage("Select a resolution in image crop to render");
        setAlerta(null)
        break;
      case "seedNull":
        setWarningMessage("Specify a seed to render");
        setAlerta(null)
        break;
      case "modelNull":
        setWarningMessage("Choose a model to render");
        setAlerta(null)
        break;
      case "renderError":
        setWarningMessage("Error while rendering");
        setAlerta(null)
        break;
      case "upscaleError":
        setWarningMessage("Error while upscaling");
        setAlerta(null)
        break;
      case "typeNull":
        setWarningMessage("Specify a type to render");
        setAlerta(null)
        break;
      case "imageNull":
        setWarningMessage("Upload an image to render");
        setAlerta(null)
        break;
      case "visibilityNull":
        setWarningMessage("Select a visibility mode to render");
        setAlerta(null)
        break;
      case "renderState":
        setWarningMessage("Rendering...");
        setAlerta(null)
        break;
      case "upscaleState":
        setWarningMessage("Upscaling...");
        setAlerta(null)
        break;
      case "imageNull":
        setWarningMessage("Upload an image to render!");
        setAlerta(null)
        break;
      case "whatNull":
        setWarningMessage("You need to select a \"What\" to render!");
        setAlerta(null)
        break;
    }
  }

  //---------------------------------------------------------------------------
  //-------------------------------UPSCALE-------------------------------------
  //---------------------------------------------------------------------------
  const handleUpscaleClick = async () => {
    if (isUpscaling) {
      return;
    }
    console.log("refine")
    setIsUpscaling(false);
    const token = Cookies.get("token");
    const decodedToken = jwtDecode(token);
    const user = decodedToken.clienteId;
    console.log("sou o usuario: ", user);

    console.log(url); // Exibe a URL completa

    const canvasAlchemyParam = url.includes('CanvasAlchemy');
    console.log(canvasAlchemyParam);

    let geraPrompt;
    const nomeImage = `${user}_${Date.now()}.jpg`;
    //const nomeImage = sessionStorage.getItem("nomeImage");
    console.log('Nome da imagem:', nomeImage);

    setAlerta("upscaleState");

    function getBase64ImageDimensions(base64String) {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = function () {
          const dimensions = {
            width: this.naturalWidth,
            height: this.naturalHeight
          };
          resolve(dimensions);
        };

        img.onerror = function () {
          reject(new Error("Erro ao carregar a imagem Base64."));
          setAlerta("upscaleError");
          setIsUpscaling(false);
        };

        // Adiciona o prefixo se não estiver presente
        if (!base64String.startsWith("data:image")) {
          base64String = "data:image/jpeg;base64," + base64String;
        }

        img.src = base64String;
      });
    }

    getBase64ImageDimensions(imageURL)
      .then(dimensions => {
        console.log("Dimensões da imagem:", dimensions.width, "x", dimensions.height);
        return {
          "width": dimensions.width,
          "height": dimensions.height
        };
        // Faça o que for necessário com as dimensões
      })
      .catch(error => {
        console.error(error.message);
        setAlerta("upscaleError");
        setIsUpscaling(true);
      });

    // Obtém as dimensões da imagem
    const dimensions = await getBase64ImageDimensions(imageURL);

    if (dimensions.width && dimensions.height) {
      const { width, height } = dimensions;
      const key = `${width}x${height}`;
      console.log("PARAMETROS:", key);

      // Mapeamento das dimensões para funções correspondentes
      const promptFunctions = {
        "1024x1024": { default: geraPrompt1024x1024, canvas: geraPromptCanvas1024x1024 },
        "1536x1536": { default: geraPrompt1536x1536, canvas: geraPromptCanvas1536x1536 },
        "1024x1344": { default: geraPrompt1024x1344, canvas: geraPromptCanvas1024x1344 },
        "1344x1024": { default: geraPrompt1344x1024, canvas: geraPromptCanvas1344x1024 },
        "1024x1536": { default: geraPrompt1024x1536, canvas: geraPromptCanvas1024x1536 },
        "1536x1024": { default: geraPrompt1536x1024, canvas: geraPromptCanvas1536x1024 },
        "1224x816": { default: geraPrompt1224x816, canvas: geraPromptCanvas1224x816 },
        "816x1224": { default: geraPrompt816x1244, canvas: geraPromptCanvas816x1224 },
        "1224x1840": { default: geraPrompt1224x1840, canvas: geraPromptCanvas1224x1840 },
        "1840x1224": { default: geraPrompt1840x1224, canvas: geraPromptCanvas1840x1224 },
        "1752x1336": { default: geraPrompt1752x1336, canvas: geraPromptCanvas1752x1336 },
        "1336x1752": { default: geraPrompt1336x1752, canvas: geraPromptCanvas1336x1752 },

      };

      if (promptFunctions[key]) {
        const geraPrompt = canvasAlchemyParam ? promptFunctions[key].canvas : promptFunctions[key].default;
        const upscaleResult = geraPrompt(userChoices, user, nomeImage);
        // setImageUpscale(upscaleResult);
        console.log("PROMPT DO UPSCALE", upscaleResult);
        console.log(userChoices);


        url = window.location.href;
        const parts = url.split("/");
        let afterSlash = parts.slice(3).join("/");
        afterSlash = afterSlash + "Upscale";

        let JsonBodyUpscale = {
          user: user,
          page: afterSlash,
          userchoices: userChoices,
          image: imageURL,
          imageName: nomeImage,
          upscale: true,
        };
        try {
          const optionFetchGeraImagemComfy = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(JsonBodyUpscale)
          };
          console.log("oi log2", optionFetchGeraImagemComfy);
          const response = await fetchGeraImagemComfy(optionFetchGeraImagemComfy);

          if (response.erro) {
            setImageUpscale(imagepreta);
            setAlerta("upscaleError");
            setIsUpscaling(false);
          } else {
            setImageUpscale(response.msg);
            setWarningMessage(null);
            onMoedasUpdate(response.moedas);
            setIsUpscaling(false);
          }

        } catch (error) {
          console.error('Erro ao realizar fetch:', error);
          setAlerta("upscaleError");
          setIsUpscaling(false);
          // Trate o erro conforme necessário
        }
      } else {
        console.error("Dimensões da imagem não suportadas.");
        setAlerta("upscaleError");
        setIsUpscaling(false);
      }
    } else {
      console.error("Não foi possível extrair as dimensões da imagem.");
      setAlerta("upscaleError");
      setIsUpscaling(false);
    }

  };
  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------

  const { t } = useTranslation();

  return (
    <section className={`my-4 w-full max-w-screen`}>
      <div className="flex flex-col relative w-full h-fit max-w-screen justify-center items-center max-w-screen">
        <span
          className="absolute h-[0.06rem] w-[85%] bottom-[49%] max-w-screen
        bg-black">
        </span>
        <div
          id="black-triangle"
          className="w-10 h-10 z-20 bg-black rotate-45"
        />
      </div>
      <div
        className='border-transparent -mt-4 w-full max-w-screen'>
        <div
          className={`
                  ${alerta === "renderState" && ("bg-green-200 opacity-70 animate-pulse")}
                  `}
          onClick={handleAlertMessage(alerta)}>
          <button
            className={`bg-transparent font-title w-full 
          max-w-screen py-12 m-0 lg:text-[12rem] text-[18vw] max-w-screen 
          tracking-[0.2em] text-center ease-in-out duration-500 transition-colors
          hover:bg-green-200`}
            onClick={handleAccordionOpen}>
            Render
          </button>
        </div>
        <Accordion
          isOpen={(imageURL === imagepreta || imageURL === imageDefault) ? false : true}>
          <div className="flex flex-col justify-center items-center">
            <span className="h-[0.1rem] w-[85%] bottom-[49%] max-w-screen text-transparent duration-500 mt-1 bg-black" />
            <div className='flex flex-col justify-center items-center my-3 mx-[0.06rem] w-full'>

              {/* Imagem Original */}
              <div className='cursor-pointer size-[35%] mb-4' alt="">
                {(imageURL === imagepreta || imageURL === false) ?
                  <img
                    src={notAvailable}
                    className={`w-full h-full z-40 cursor-pointer`}
                    onClick={() => {
                      setIsopen(!isOpen);
                      setCurrentImageSrc(`data:image/png;base64,${imageURL}`)
                    }} />
                  :
                  <img
                    src={`data:image/png;base64,${imageURL}`}
                    alt="Rendered Image"
                    className="z-40 rounded-md"
                    onClick={() => {
                      setIsopen(!isOpen);
                      setCurrentImageSrc(`data:image/png;base64,${imageURL}`);
                    }}
                  />}
              </div>

              {/* Botão Upscale */}
              <button
                className={`sm:text-[3.8vw] md:text-[2vw] lg:text-[1.8vw] border-2 border-black border-solid p-2 mt-2 ease-in-out duration-300 transition-colors bg-[#80d5ff] bg-opacity-70 text-black hover:bg-[#80d5ff] hover:bg-opacity-100 hover:text-black hover:cursor-pointer font-title
                ${alerta === "upscaleState" ? `bg-[#80d5ff] animate-pulse` : `hover:bg-blue-200`}  `}
                onClick={handleUpscaleClick}>
                REFINE (5 RP)
              </button>
              {/* Imagem Upscale */}
              <div className='cursor-pointer size-[40%] mb-4' alt="">
                <Accordion isOpen={(imageUpscale === imagepreta || imageUpscale === imageDefault) ? false : true}>
                  {(imageUpscale === imagepreta || imageUpscale === false) ?
                    <img
                      src={notAvailable}
                      className={`w-full h-full z-40 cursor-pointer`}
                    />
                    :
                    <img
                      src={`data:image/png;base64,${imageUpscale}`}
                      alt="Upscale Image"
                      className="z-40 rounded-md"
                      onClick={() => {
                        setIsopen(!isOpen);
                        setCurrentImageSrc(`data:image/png;base64,${imageUpscale}`)
                      }}
                    />}
                </Accordion>
              </div>
            </div>
          </div>
        </Accordion>
      </div>
      <div
        className={`fixed top-0 z-[9000] left-0 w-[100vw] h-[100vw] bg-black opacity-60
      ${isOpen ? `` : `hidden`}`}>
        {isOpen && (
          <IoMdClose
            size={30}
            color="white"
            className="absolute top-2 left-2 z-50 cursor-pointer"
            onClick={() => setIsopen(!isOpen)} />
        )}
      </div>
      <div
        className={`fixed z-[9999] size-[90%] w-auto translate-x-1/2 right-1/2 top-1/2 -translate-y-1/2 ${isOpen ? "animate-fade animate-duration-500" : "hidden"
          }`}
      >
        <div className="flex flex-col z-[9999] lg:flex-row items-center justify-center bg-white py-4 px-4 rounded-lg">
          <img
            src={currentImageSrc}
            alt="Rendered Image"
            className="max-h-[80vh] max-w-[80vw] object-contain"
          />
          <div className="flex flex-col items-center justify-center lg:w-[300px] h-full lg:ml-4">
            <FaDownload
              size={28}
              onClick={() => handleDownloadClick(imageURL)}
              className="cursor-pointer w-full mt-3"
            />
            <p
              className={`font-title mt-3 ${isHovering
                ? "animate-fade-up animate-duration-500"
                : "animate-fade animate-alternate-reverse animate-duration-500"
                }`}
            >
              DOWNLOAD NOW!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
//---------------------------------------------------------------------------
export default RenderButton;