import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

// Create an array of image paths
const images = Array.from({ length: 14 }, (_, i) => 
  require(`../../../../assets/animations/canvas_animation/canvas_${String(i + 1).padStart(3, '0')}.webp`)
);

const MorphingImageCanvas = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [nextImage, setNextImage] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
      setNextImage((prev) => (prev + 1) % images.length);
    }, 1000); // Adjust interval as desired
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ position: 'relative', width: '600px', height: '600px', margin: '0 auto' }}>
      <motion.div
        key={currentImage}
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }} // Set duration to overlap with the next image
        style={{
          backgroundImage: `url(${images[currentImage]})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <motion.div
        key={nextImage}
        initial={{ opacity: 0.0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.0 }}
        style={{
          backgroundImage: `url(${images[nextImage]})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
    </div>
  );
};

export default MorphingImageCanvas;
