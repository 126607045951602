import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import InfoBox from "../InfoBox";

function ImageUploader({ updateUserChoices, paramName, title, 
  infoText, pasteEnable, subtitle, bgColor, enableHover }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [oldResolution, setOldResolution] = useState(null);
  const [newResolution, setNewResolution] = useState(null);
  const { i18n } = useTranslation();
  const onDrop = useCallback((acceptedFiles) => {
    handleImageFiles(acceptedFiles);
  }, []);

  const handleImageFiles = (files) => {
    const file = files[0];

    
    if (file && file instanceof File) {
      const reader = new FileReader();
      reader.onload = async () => {
        const base64Image = reader.result;


        // Calculate original file size
        const originalFileSizeKB = file.size / 1024;
        console.log("Tamanho do arquivo original (KB):", originalFileSizeKB);

        setOldResolution(null);
        setNewResolution(null);

        // Create a temporary image to get the original resolution
        const tempImg = new Image();
        tempImg.onload = async () => {
          setOldResolution({ width: tempImg.width, height: tempImg.height });
          const resizedImage = await resizeImage(base64Image, 800, 800); // Redimensionar para 800x800
          const compressedImage = await compressImage(resizedImage, 250); // Comprimir para no máximo 300KB
          
          setSelectedImage(compressedImage);

          // Create a temporary image to get the new resolution
          const newTempImg = new Image();
          newTempImg.onload = () => {
            setNewResolution({ width: newTempImg.width, height: newTempImg.height });
            // Call the function to handle image upload (replace with your implementation)
            updateUserChoices(paramName, compressedImage);
            sessionStorage.setItem(paramName, compressedImage);

            // Calculate compressed file size
            const compressedFileSizeKB = compressedImage.length / 1024;
            console.log("Tamanho do arquivo após compressão (KB):", compressedFileSizeKB);
          };
          newTempImg.src = compressedImage;
        };
        tempImg.src = base64Image;
      };
      reader.readAsDataURL(file);
    } else {
      setErrorMessage("Tipo de arquivo inválido."); // Generic error message
    }
  };

  const handlePaste = useCallback((event) => {
    if(!pasteEnable) return;
    const items = event.clipboardData.items;
    let files = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file") {
        const file = items[i].getAsFile();
        files.push(file);
      }
    }
    handleImageFiles(files);
  }, []);

  useEffect(() => {
    if(!pasteEnable) return;
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [handlePaste]);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, fileRejections } = useDropzone({
    accept: "image/*", // Only accept image files
    maxFiles: 1, // Allow only one image selection
    validator: (file) => {
      if (!file.type.startsWith("image/")) {
        return {
          code: "file-type",
          message: "Apenas arquivos de imagem são permitidos!", // Translated error message
        };
      }
      if (file.size > 10 * 1024 * 1024) { // Check if the file size exceeds 10MB
        return {
          code: "file-too-large",
          message: "O tamanho do arquivo não pode exceder 10MB!", // Translated error message
        };
      }
      return null;
    },
    onDrop,
  });

  // Handle file rejections
  useEffect(() => {
    if (fileRejections.length > 0) {
      const { errors } = fileRejections[0];
      const message = errors.map(e => e.message).join(", ");
      setErrorMessage(message);
    }
  }, [fileRejections, i18n]);

  // Function to resize image using canvas
  const resizeImage = (base64Image, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = (error) => {
              reject(error);
            };
            reader.readAsDataURL(blob);
          },
          "image/jpeg",
          0.8 // Quality parameter for JPEG (0.8 means 80% quality)
        );
      };
      img.onerror = (error) => reject(error);
      img.src = base64Image;
    });
  };

  // Function to compress image until it is less than maxSizeKB
  const compressImage = (base64Image, maxSizeKB) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        const compress = (quality) => {
          return new Promise((resolveCompress) => {
            canvas.toBlob(
              (blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  resolveCompress(reader.result);
                };
                reader.readAsDataURL(blob);
              },
              "image/jpeg",
              quality
            );
          });
        };

        const reduceQuality = async () => {
          let quality = 0.8;
          let result = await compress(quality);
          while (result.length / 1024 > maxSizeKB && quality > 0.1) {
            quality -= 0.1;
            result = await compress(quality);
          }
          resolve(result);
        };

        reduceQuality().catch(reject);
      };
      img.onerror = (error) => reject(error);
      img.src = base64Image;
    });
  };

  // useEffect to clear error message after a while (optional)
  useEffect(() => {
    const timeoutId = setTimeout(() => setErrorMessage(null), 3000);
    return () => clearTimeout(timeoutId);
  }, [errorMessage]);

  return (
    <div className={`py-4 text-[#255255255] lg:w-auto`}>
      <div className="relative" {...getRootProps({ className: `dropzone w-[80vw] lg:w-[60vw]` })}>
        <input {...getInputProps()} />
        <div className={`text-center`}>
          {isDragActive ? (
            <p className={`dropzone-content h-52 text-white font-title 
              font-bold text-lg lg:text-3xl flex justify-center items-center
              animate-fade animate-once animate-duration-300 ${bgColor}`}>
              {i18n.t("Drop your photo here")}
            </p>
          ) : (
            <div className={`text-center animate-fade animate-once animate-duration-300
              ${bgColor}`}>
              <div className="relative w-full h-52 flex flex-col items-center justify-center z-10 p-5 lg:p-8">
              <div className="h-[98%] w-[99.4%] border-solid border-2 border-white absolute
              left-1/2 -translate-x-1/2"/>
              <div className="lg:hidden absolute top-[4%] right-[2%]">
              <div 
              className="z-30 p-10">
              <InfoBox 
              enableHover={enableHover}
              text={infoText} />
              </div>
              </div>
                <div className="flex">
                  <h1 className="text-white font-title font-bold text-lg lg:text-3xl mb-2 lg:mb-4">
                    {i18n.t(title)}
                  </h1>
                  <div className="w-[1px] h-[1px] relative place-self-start">
                    {infoText !== undefined && (
                      <div className="absolute left-[4%] top-0.5 hidden lg:inline z-30">
                        <InfoBox 
                        enableHover={enableHover}
                        text={infoText} />
                      </div>
                    )}
                  </div>    
                </div>
                <p className="text-white lg:inline hidden font-paragraph text-center lg:px-24 text-lg lg:text-xl">
                  {i18n.t(
                    subtitle
                  )}
                </p>
                <p className="text-white inline lg:hidden text-xl font-paragraph">
                  {i18n.t("Apenas clique aqui!")}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {oldResolution && newResolution && (
        <div className="resolution-info">
            {/* <p>{i18n.t("Resolução original")}: {oldResolution.width}x{oldResolution.height}</p>
            <p>{i18n.t("Nova resolução")}: {newResolution.width}x{newResolution.height}</p> */}
        </div>
      )}
    </div>
  );
}

ImageUploader.defaultProps = {
  paramName: "imageUploader",
  title: "DRAG AND DROP YOUR FILES HERE",
  pasteEnable: true,
  subtitle: "You can take a screenshot or copy and paste your image file here.",
  bgColor: "bg-[black]"
};

export default ImageUploader;