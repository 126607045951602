// PromptToggleOrMessage.jsx
import React from "react";

const PromptToggleOrMessage = ({ isPromptEnabled, handlePromptToggle, t }) => {
  const storedPlanId = localStorage.getItem("id_plan");

  if (storedPlanId === "1") { // Usuário free
    return (
      <div className="text-red-600 font-bold text-2xl mt-4">
        {t("THIS TOOL IS FOR PREMIUM USERS ONLY")}, 
        <a href="https://www.arcseedai.com/PlansPage/ENTHUSIAST" target="_blank" className="text-white hover:text-gray-300">
          {t(" CLICK HERE ")}
        </a>
        {t(" TO UNLOCK IT.")}
      </div>
    );
  } else {
    return (
      <input
        id="promptToggle"
        type="checkbox"
        className="w-8 h-8 border-4 border-black mt-5 rounded"
        checked={isPromptEnabled}
        onChange={(e) => handlePromptToggle(e)}
      />
    );
  }
};

export default PromptToggleOrMessage;
