// import { React, useState } from "react";
// import { useEffect } from "react";
// import ImageUploader from "../components/RenderComponents/ImageUploader";
// import ImageCropper from "../components/RenderComponents/ImageCropper";
// import AIModels from "../components/RenderComponents/AIModels";
// import Seed from "../components/RenderComponents/Seed";
// import WhatExterior from "../components/RenderComponents/WhatInterior";
// import WallpaperGen from "../assets/wallpaperRenderPage.webp";
// import Visibility from "../components/RenderComponents/Visibility";
// import Navbar from "../components/Navbar";
// import RenderButton from "../components/RenderComponents/RenderButton";
// import Footer from "../components/Footer";
// import { geraPrompt } from "../apiComfy/geraPrompt_FloorPlanner";
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
// import Precision from "../components/RenderComponents/Precision";

// export default function FloorPlannerPage() {
//   const standardColors = {
//     active: "bg-[#fffe80] text-black",
//     inactive: "bg-[#ffffb3] text-black",
//     hover: "bg-[#fffe80]",
//   };

//   const professionalColors = {
//     active: "bg-[#fcac7f] text-black",
//     inactive: "bg-[#fdd7bc] text-black",
//     hover: "bg-[#fcac7f]",
//   };
//   const token = Cookies.get("token");
//   // Função para verificar se o token está presente e válido
//   function verificarToken() {

//     if (!token) {
//       // Token não encontrado no localStorage
//       return false;
//     }

//     // Decodificar o token para verificar sua validade
//     try {
//       const decodedToken = jwtDecode(token); // Use jwtDecode em vez de jwt_decode
//       const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos

//       // Verificar se o token expirou
//       if (decodedToken.exp < currentTime) {
//         // Token expirado
//         return false;
//       }

//       // Token válido
//       return true;
//     } catch (error) {
//       //console.error('Erro ao decodificar o token:', error);
//       return false; // Token inválido
//     }
//   }

//   // Função para redirecionar para a página de login
//   function redirectToLogin() {
//     window.location.href = "/Login"; // Redireciona para a página de login
//   }

//   // Verificar o token ao carregar a página
//   if (!verificarToken()) {
//     redirectToLogin(); // Redireciona para a página de login se o token não for válido
//   }

//   const [userChoices, setUserChoices] = useState({
//     imageUploader: null,
//     imageCropper: null,
//     aiModels: null,
//     seed: null,
//     what: null,
//     style: null,
//     effects: null,
//     visibility: null,
//     precision: null,
//   });

//   const updateUserChoices = (category, choice) => {
//     setUserChoices((prevChoices) => ({
//       ...prevChoices,
//       [category]: choice,
//     }));
//   };

//   const decodedToken = jwtDecode(token);
//   const user = decodedToken.clienteId; 
  
//   const convertToJson = () => {
//     //const user = Cookies.get("clientId"); //pega o user da sessão

//     const nomeImage = `${user}_${Date.now()}.png`;

//     sessionStorage.setItem("nomeImage", nomeImage);

//     const saida = geraPrompt(userChoices, user, nomeImage);
//     sessionStorage.setItem("GeraPrompt", JSON.stringify(saida));
//     //console.log(userChoices);
//   };

//   useEffect(() => {
//     /* Função responsável pelo título das páginas */

//     document.title = "Floor Planner - Arcseed AI";
//     return () => {
//       document.title = "Arcseed AI"; // Define o título padrão ao desmontar o componente
//     };
//   }, []);

//   const [openAccordion, setOpenAccordion] = useState(null);

//   const handleAccordionToggle = (accordionId) => {
//     setOpenAccordion(openAccordion === accordionId ? null : accordionId);
//   };

//   return (
//     <div className="overflow-hidden">
//       <Navbar
//         title="FLOOR PLANNER"
//         userChoices={userChoices}
//         description="Create amazing floor plans here, just upload, drag and drop or paste your screen or a sketch on the box below! if you are an advanced user click here for the advanced toolset or turn the advanced toolset on your settings page here ,there all the generative pages will be in one place for fast editing and access."
//         showPoints={true}
//       />
//       <div className="pt-[118px]">
//         <ImageUploader updateUserChoices={updateUserChoices} />
//       </div>
//       <ImageCropper
//         image={sessionStorage.getItem("imageUploader")}
//         updateUserChoices={updateUserChoices}
//       />
//       <div className="flex w-full py-8 flex-col lg:flex-row items-center justify-center max-w-screen">
//         <div className="w-1/2">
//           <AIModels verticalSet={false} updateUserChoices={updateUserChoices} />
//         </div>
//         <div className="w-[0.06rem] h-[19rem] bg-black hidden lg:inline"></div>
//         <div className="w-1/2">
//           <Seed
//             className="md:mt-8 sm:mt-8"
//             updateUserChoices={updateUserChoices}
//           />
//         </div>
//       </div>
//       <WhatExterior updateUserChoices={updateUserChoices} />
//       <div className="flex w-full h-[500px] bg-[#f3f4f6] max-w-screen justify-center relative items-center">
//         <img
//           alt="Wallpaper not found"
//           src={WallpaperGen}
//           className="w-full h-full absolute top-0 left-0 opacity-40"
//         />
//         <Style
//           isOpen={openAccordion === "style"}
//           onToggle={() => handleAccordionToggle("style")}
//           updateUserChoices={updateUserChoices}
//         />
//       </div>
//       <Visibility updateUserChoices={updateUserChoices} />
//       <Precision
//         updateUserChoices={updateUserChoices}
//         standardColors={standardColors}
//         professionalColors={professionalColors}
//       />
//       <RenderButton
//         onRenderButtonClick={convertToJson}
//         userChoices={userChoices}
//       />
//       <Footer />
//     </div>
//   );
// }
