export function geraPrompt(userChoices, user, nomeImage1, nomeImage2 = null) {


  let prompt_default = `{
  "2": {
    "inputs": {
      "stop_at_clip_layer": -2,
      "clip": [
        "413",
        1
      ]
    },
    "class_type": "CLIPSetLastLayer",
    "_meta": {
      "title": "CLIP Set Last Layer"
    }
  },
  "6": {
    "inputs": {
      "width": 1224,
      "height": 816,
      "batch_size": 1
    },
    "class_type": "EmptyLatentImage",
    "_meta": {
      "title": "Empty Latent Image"
    }
  },
  "20": {
    "inputs": {
      "text": "a pristine photograph of a modern bedroom",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "22": {
    "inputs": {
      "seed": [
        "47",
        0
      ],
      "steps": 8,
      "cfg": 1.5,
      "sampler_name": "euler",
      "scheduler": "sgm_uniform",
      "denoise": [
        "38",
        0
      ],
      "model": [
        "375",
        0
      ],
      "positive": [
        "102",
        0
      ],
      "negative": [
        "102",
        1
      ],
      "latent_image": [
        "113",
        0
      ]
    },
    "class_type": "KSampler",
    "_meta": {
      "title": "KSampler"
    }
  },
  "23": {
    "inputs": {
      "samples": [
        "22",
        0
      ],
      "vae": [
        "413",
        2
      ]
    },
    "class_type": "VAEDecode",
    "_meta": {
      "title": "VAE Decode"
    }
  },
  "38": {
    "inputs": {
      "value": 0.6
    },
    "class_type": "easy float",
    "_meta": {
      "title": "Denoise"
    }
  },
  "47": {
    "inputs": {
      "seed": 26,
      "🎲 Manual Random Seed": null
    },
    "class_type": "easy seed",
    "_meta": {
      "title": "EasySeed"
    }
  },
  "52": {
    "inputs": {
      "conditioning_to": [
        "53",
        0
      ],
      "conditioning_from": [
        "20",
        0
      ]
    },
    "class_type": "ConditioningConcat",
    "_meta": {
      "title": "Conditioning (Concat)"
    }
  },
  "53": {
    "inputs": {
      "text": "",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "74": {
    "inputs": {
      "text": "blur, distortion, jiggly, low quality",
      "clip": [
        "2",
        0
      ]
    },
    "class_type": "CLIPTextEncode",
    "_meta": {
      "title": "CLIP Text Encode (Prompt)"
    }
  },
  "88": {
    "inputs": {
      "strength": 0.6,
      "start_percent": 0,
      "end_percent": 0.5,
      "positive": [
        "52",
        0
      ],
      "negative": [
        "74",
        0
      ],
      "control_net": [
        "416",
        0
      ],
      "image": [
        "421",
        0
      ]
    },
    "class_type": "ControlNetApplyAdvanced",
    "_meta": {
      "title": "Apply ControlNet (Advanced)"
    }
  },
  "102": {
    "inputs": {
      "strength": 0.8,
      "start_percent": 0,
      "end_percent": 0.8,
      "positive": [
        "88",
        0
      ],
      "negative": [
        "88",
        1
      ],
      "control_net": [
        "415",
        0
      ],
      "image": [
        "104",
        0
      ]
    },
    "class_type": "ControlNetApplyAdvanced",
    "_meta": {
      "title": "Apply ControlNet (Advanced)"
    }
  },
  "104": {
    "inputs": {
      "guassian_sigma": 2.6,
      "intensity_threshold": 4,
      "resolution": 1024,
      "image": [
        "372",
        0
      ]
    },
    "class_type": "LineartStandardPreprocessor",
    "_meta": {
      "title": "Standard Lineart"
  }
  },
  "113": {
    "inputs": {
      "pixels": [
        "372",
        0
      ],
      "vae": [
        "413",
        2
      ]
    },
    "class_type": "VAEEncode",
    "_meta": {
      "title": "VAE Encode"
    }
  },
  "358": {
    "inputs": {
      "image": "1224x816.jpg",
      "upload": "image"
    },
    "class_type": "LoadImage",
    "_meta": {
      "title": "Load Image"
    }
  },
  "369": {
    "inputs": {
      "image": "p4.jpg",
      "upload": "image"
    },
    "class_type": "LoadImage",
    "_meta": {
      "title": "Load Image"
    }
  },
  "372": {
    "inputs": {
      "upscale_method": "nearest-exact",
      "width": 1224,
      "height": 816,
      "crop": "disabled",
      "image": [
        "358",
        0
      ]
    },
    "class_type": "ImageScale",
    "_meta": {
      "title": "Upscale Image"
    }
  },
  "375": {
    "inputs": {
      "weight": 1,
      "weight_type": "strong style transfer",
      "combine_embeds": "concat",
      "start_at": 0,
      "end_at": 0.8,
      "embeds_scaling": "V only",
      "model": [
        "413",
        0
      ],
      "ipadapter": [
        "419",
        0
      ],
      "image": [
        "369",
        0
      ],
      "clip_vision": [
        "418",
        0
      ]
    },
    "class_type": "IPAdapterAdvanced",
    "_meta": {
      "title": "IPAdapter Advanced"
    }
  },
  "407": {
    "inputs": {
      "filename_prefix": "Canvas_img2img_landscape_standard_V0.2.2.3",
      "file_type": "JPEG",
      "images": [
        "23",
        0
      ]
    },
    "class_type": "SaveImageExtended",
    "_meta": {
      "title": "Save Image (Extended)"
    }
  },
  "413": {
    "inputs": {
      "ckpt_name": "Arcseed_V0.2.safetensors",
      "key_opt": "",
      "mode": "Auto"
    },
    "class_type": "CheckpointLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Checkpoint Loader (Inspire)"
    }
  },
  "414": {
    "inputs": {
      "model_name": "swift_srgan_2x.pth",
      "key_opt_u": "",
      "mode": "Auto"
    },
    "class_type": "UpscaleLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Upscale Loader (Inspire)"
    }
  },
  "415": {
    "inputs": {
      "control_net_name": "LoraModelScribble.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "416": {
    "inputs": {
      "control_net_name": "LoraModelDepth.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "417": {
    "inputs": {
      "control_net_name": "ttplanetSDXLControlnet_v20Fp16.safetensors",
      "key_opt_cn": "",
      "mode": "Auto"
    },
    "class_type": "ControlnetLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared CN Loader (Inspire)"
    }
  },
  "418": {
    "inputs": {
      "clip_name": "clipvis_ViT-H_1.5_.safetensors",
      "key_opt_cv": "",
      "mode": "Auto"
    },
    "class_type": "CLIPVisionLoaderSimpleShared //Inspire",
    "_meta": {
      "title": "Shared Clip Loader (Inspire)"
    }
  },
  "419": {
    "inputs": {
      "key": 1
    },
    "class_type": "RetrieveBackendDataNumberKey //Inspire",
    "_meta": {
      "title": "Retrieve Backend Data [NumberKey] (Inspire)"
    }
  },
  "421": {
    "inputs": {
      "seed": 127,
      "denoise_steps": 6,
      "ensemble_size": 3,
      "processing_resolution": 768,
      "scheduler": "LCMScheduler",
      "use_taesd_vae": false,
      "marigold_model": [
        "422",
        0
      ],
      "image": [
        "358",
        0
      ]
    },
    "class_type": "MarigoldDepthEstimation_v2",
    "_meta": {
      "title": "MarigoldDepthEstimation_v2"
    }
  },
  "422": {
    "inputs": {
      "key": 2
    },
    "class_type": "RetrieveBackendDataNumberKey //Inspire",
    "_meta": {
      "title": "Retrieve Backend Data [NumberKey] (Inspire)"
    }
  }
}`;

prompt_default = JSON.parse(prompt_default);
  
const modelos = userChoices["aiModels"] || '';
let modeloFrase = "";

switch (modelos) {
  case "DEFAULT":
    modeloFrase = " ";
    break;
  case "RENDER":
    modeloFrase = "A 3D render of";
    break;
  case "CINEMATIC":
    modeloFrase = "A Cinematic professional shot of";
    break;
  case "PHOTOGRAPHER":
    modeloFrase = "A Photograph of";
    break;
  case "ARCHVIZ":
    modeloFrase = "A Professional architectural rendering of";
    break;
  case "PRISTINE":
    modeloFrase = "A Pristine photograph of";
    break;
}

const anStyles = [
  "arabic", "art deco", "art nouveau", "asian fusion", "eclectic", "english",
  "industrial", "japandi", "oriental", "urban modern"
];
const style = userChoices["style"] || '';

// Se o estilo não for selecionado, styleFrase será uma string vazia.
let styleFrase = '';
if (style) {
  styleFrase = anStyles.includes(style) ? `an ${style}` : `a ${style}`;
}

let whereExterior = userChoices["whereExterior"] || '';
let time = userChoices["time"] || '';
let what = userChoices["what"] || '';
let Style = styleFrase;
let Modes = `${modeloFrase}` || '';
let weather = userChoices["weather"] || '';

if (what === "Square") {
  what = "a public plaza";
}

// Construa o prompt apenas com os parâmetros fornecidos
const promptParts = [
  Modes,
  Style,
  what ? `${what},` : '',  // Adiciona vírgula após what
  time ? `${time},` : '',    // Adiciona vírgula após time
  whereExterior ? `${whereExterior},` : '',
  weather
].filter(Boolean).map(part => part.trim()); // Remove valores falsos e trim

// Junta os elementos em uma única string
const promptText = promptParts.join(' ').replace(/\s{2,}/g, ' ').replace(/,\s*,/g, ',').replace(/,\s*$/, '');

// Atualiza o texto no nó 20
prompt_default["20"]["inputs"]["text"] = promptText.trim();

const shapeStrengthMap = {
  1: { "CN1": 0.3, "CN2": 0.3 },
  2: { "CN1": 0.6, "CN2": 0.8 },
  3: { "CN1": 0.8, "CN2": 1 }
};

const shapeExposureMap = {
  1: { "CN1": 0.2, "CN2": 0.5 },
  2: { "CN1": 0.5, "CN2": 0.8 },
  3: { "CN1": 0.8, "CN2": 1 }
};

// Recupera as escolhas do usuário para ShapeStrength e ShapeExposure
const userShapeStrengthChoice = userChoices["ShapeStrengthRange"];
const userShapeExposureChoice = userChoices["ShapeExposureRange"];

// Define os valores de acordo com a escolha do usuário
const selectedShapeStrength = shapeStrengthMap[userShapeStrengthChoice];
const selectedShapeExposure = shapeExposureMap[userShapeExposureChoice];

// Aplica os valores ao prompt_default para CN1 (88) e CN2 (102)
prompt_default["88"]["inputs"]["strength"] = selectedShapeStrength["CN1"];
prompt_default["102"]["inputs"]["strength"] = selectedShapeStrength["CN2"];

prompt_default["88"]["inputs"]["end_percent"] = selectedShapeExposure["CN1"];
prompt_default["102"]["inputs"]["end_percent"] = selectedShapeExposure["CN2"];

prompt_default["88"]["inputs"]["start_percent"] = 0;
prompt_default["102"]["inputs"]["start_percent"] = 0;

const aiImaginationMap = {
  5: 2.1,
  4: 1.8,
  3: 1.5,
  2: 1.25,
  1: 1.0
};

// Recupera a escolha do usuário para AI Imagination Range
const userAIImaginationChoice = userChoices["AIImmaginationRange"];

// Define o valor de cfg de acordo com a escolha do usuário
const selectedAIImaginationValue = aiImaginationMap[userAIImaginationChoice];

// Aplica o valor ao prompt_default para cfg
prompt_default["22"]["inputs"]["cfg"] = selectedAIImaginationValue;

prompt_default["22"]["inputs"]["denoise"] = userChoices["OriginalityRange"];


//------------------------------------ip weight-----------------------------------
const inputIpWeightMap = {
  1: 0.25,
  2: 0.5,
  3: 0.8,
  4: 1,
  5: 1.2,
  6: 1.5,
  7: 2
};

// Recupera a escolha do usuário para Input Depth Range
const userInputIpWeightChoice = userChoices["StyleTransferRange"];

// Define o valor de CN1 de acordo com a escolha do usuário
const selectedInputIpWeightValue = inputIpWeightMap[userInputIpWeightChoice];

// Aplica o valor ao prompt_default para CN1 (assumindo que "88" corresponde a CN1 para Input Depth)
prompt_default["375"]["inputs"]["weight"] = selectedInputIpWeightValue;

const inputIpWeightFinalMap = {
  1: { "start": 0.4, "end": 0.55 },
  2: { "start": 0.25, "end": 0.6 },
  3: { "start": 0.1, "end": 0.8 },
  4: { "start": 0.0, "end": 0.8 },
  5: { "start": 0.0, "end": 1 }
};
    
    // Recupera a escolha do usuário para Input Depth Range
    const userInputIpWeightFinalChoice = userChoices["StyleExposureRange"];
    
    // Define o valor de CN1 de acordo com a escolha do usuário
    const selectedInputIpWeightFinalValue = inputIpWeightFinalMap[userInputIpWeightFinalChoice];
    
    // Aplica os valores ao prompt_default para CN1 (assumindo que "375" corresponde a CN1 para Input Depth)
    prompt_default["375"]["inputs"]["start_at"] = selectedInputIpWeightFinalValue.start;
    prompt_default["375"]["inputs"]["end_at"] = selectedInputIpWeightFinalValue.end;

  
  prompt_default["22"]["inputs"]["seed"] = `${userChoices["seed"]}`;
  
  prompt_default["358"]["inputs"]["image"] = `${nomeImage1}`;

  if (nomeImage2) {
    prompt_default["369"]["inputs"]["image"] = `${nomeImage2}`;
  }
  
  prompt_default["407"]["inputs"]["filename_prefix"] = `${user}`;


  const prompt = prompt_default;
  //console.log(prompt);
  return prompt;
}
