import React, { useState, useContext, useEffect } from "react";
import { IoMdMenu, IoMdPerson } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate } from 'react-router-dom';
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode"; 
import Lottie from 'lottie-react'; // Import Lottie
import animationData from '../assets/Coin_animation.json'; // Replace with your JSON file path
import axios from 'axios';
import { add_Back } from "../address.js";
import { Context } from "../App.js";

export default function NavbarButtons({ showPoints, pontos }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const [pontos, setPontos] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [dropdownValue, setDropdownValue] = useState('');
  const [complaintLevel, setComplaintLevel] = useState(1);
  const [otherText, setOtherText] = useState('');
  const [complaintDescription, setComplaintDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [warningMessage, setWarningMessage] = useContext(Context);
  const [moedas, setMoedas] = useState(0);
  const [showLottie, setShowLottie] = useState(true);

  // Duration in milliseconds
  const lottieDuration = 5000; // tempo que a animação aparecerá
  const textDuration = 2000; // Tempo que o Free vai aparecer

  useEffect(() => {
    const toggleVisibility = () => {
      setShowLottie((prevShowLottie) => !prevShowLottie);
    };

    let interval;
    if (showLottie) {
      // If Lottie is showing, set interval to Lottie duration
      interval = setTimeout(toggleVisibility, lottieDuration);
    } else {
      // If text is showing, set interval to text duration
      interval = setTimeout(toggleVisibility, textDuration);
    }

    return () => clearTimeout(interval); // Clean up interval on component unmount
  }, [showLottie]);

  const handleButtonClick = () => {
    // Add scale animation to the button
    const button = document.getElementById('animated-button');
    button.style.transform = 'scale(0.9)';
    setTimeout(() => {
      button.style.transform = 'scale(1)';
    }, 200);
  
    // Set isModalOpen to true to open the survey modal
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);

    if (isMenuOpen){ 
      document.body.style.overflow = 'auto';
    }
    else{
      document.body.style.overflow = 'auto';// era pra ser hidden mas se coloco ta bloquea o scroll quando abre alguma pagina ate abrir o navbar
    }
  };

  const handleMoedasUpdate = (newMoedas) => {
    setMoedas(newMoedas);
    localStorage.setItem("moedas_total", newMoedas);
  };

  const verificarToken = () => {
    const token = Cookies.get('token');
    if (!token) {
      return false;
    }
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp >= currentTime;
    } catch (error) {
      return false;
    }
  };

  const handleSubmitSurvey = async (e) => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {
      // Get the token from cookies
      const token = Cookies.get("token");
      let clientId = null;

      if (token) {
        // Decode the token to extract user information
        const decodedToken = jwtDecode(token);
        clientId = decodedToken.clienteId; // Extracting client ID from the decoded token
      }

      // Form data for survey submission
      const formData = {
        selectedOption,
        dropdownValue,
        complaintLevel,
        complaintDescription,
        userId: clientId, // Include the user ID
      };

      // Make the request and get the response
      const response = await axios.post(`${add_Back}/enviarEmailSurvey`, formData, {
        headers: {
          Authorization: `${token}`, // Include token in the header for authorization
          "Content-Type": "application/json",
        },
      });

      // Update moedas based on the response
      if (response.status === 200 && response.data && response.data.moedas_total !== undefined) {
        handleMoedasUpdate(response.data.moedas_total); // Update moedas with the new value
        setTimeout(() => {
          window.location.reload(); // Force the page to reload after a delay
        }, 2300); // 2300 milliseconds delay (2.3 seconds)
      }

      setWarningMessage(t("Thank you for helping us improve our services!"));
    } catch (error) {
      console.error("Error while sending e-mail:", error);
      setWarningMessage(t("Apologies, an error has occurred. Please try again."));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLinkClick = (path) => {
    if (!verificarToken()) {
      navigate('/Login');
    } else {
      navigate(path);
    }
  };

  const styleOpacity = {
    opacity: 0.7,
  };

  const removeToken = () => {
    Cookies.remove("clientId");
    Cookies.remove("token");
    localStorage.removeItem("moeda_total");
    localStorage.removeItem("plano");
    localStorage.removeItem("telefone");
  };

  const isIphone = () => {
    return /iPhone/i.test(navigator.userAgent);
  };

  const plansSection = {
    "Free": {
      link: "/PlansPage/BEGINNER",
    },
    "Enthusiast": {
      link: "/PlansPage/ENTHUSIAST",
    },
    "Professional": {
      link: "/PlansPage/PROFESSIONAL",
    },
    "Enterprise": {
      link: "/PlansPage/ENTERPRISE",
    },
  };

  const inStudioSection = {
    "Install in your studio": {
      link: "/InstallYourStudio",
    },
    "Make your AI model": {
      link: "/MakeYourAi",
    },
  };

  const infoSection = {
    "Documentation": {
      link: "http://doc.arcseedai.com/",
    },
    "Blog": {
      link: "",
    },
    "FAQ": {
      link: "/Dashboard/FAQ",
    },
    "Community Gallery": {
      link: "/CommunityGallery",
    },
    "Contact": {
      link: "/footer",
    },
    "Dashboard": {
      link: "/Dashboard/initial",
    },
  };

  const renderSectionLinks = {
    "Brainstorm Gen ": {
      link: "/BrainstormGen",
    },
    "Sketch 2 Render ": {
      link: "/Sketch2Render",
    },
    "Canvas Alchemy ": {
      link: "/CanvasAlchemy",
    },
    "Re-render ": {
      link: "/ReRender",
    },
    "Re-decorate (beta) ": {
      link: "/Decorate",
    },
  };

  return (
    <div id="buttons-flex" className="flex justify-end w-fit items-right overflow-hidden relative">
      <div>
        {/* Mostrar o botão só logado */}
        {verificarToken() && (
          <div
            id="animated-button"
            className="flex items-center justify-center border-transparent animate-once animate-fade-down animate-duration-700 p-2 rounded-[100%] ml-2 cursor-pointer min-h-[50px] min-w-[50px] h-[3vw] w-[3vw] max-h-[90px] max-w-[90px] relative overflow-hidden"
            style={{
              backgroundColor: '#000000',
              opacity: 0.7,
              animation: `hueRotate 6s infinite linear, shakeCycle 3.5s infinite ease-in-out`,
              borderRadius: '50%',
            }}
            onClick={handleButtonClick}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                transition: 'opacity 0.2s ease-in-out',
                opacity: showLottie ? 1 : 0,
              }}
            >
              <Lottie
                animationData={animationData}
                loop={true}
                style={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
            </div>
  
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: '1.3rem',
                fontWeight: 'bold',
                opacity: showLottie ? 0 : 1,
                transition: 'opacity 0.6s ease-in-out',
                animation: 'colorShift 3s infinite ease-in-out', // Color animation
              }}
            >
              FREE
            </div>
  
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                pointerEvents: 'none',
                mixBlendMode: 'saturation',
                borderRadius: '50%',
              }}
            />
          </div>
        )}
  
        {isModalOpen && (
          <div
            className="fixed inset-0 z-30 flex items-center justify-center overflow-y-auto px-4"
            style={{
              animation: 'fadeInBackground 0.5s ease-in-out forwards',
            }}
          >
            {/* Bg overlay */}
            <div
              className="absolute inset-0 bg-black"
              style={{
                animation: 'fadeInOverlay 0.5s ease-in-out forwards',
                opacity: 0.5,
              }}
            ></div>
  

    {/* Modal content */}
    <div
      className="bg-white p-6 w-full max-w-[90%] md:max-w-[600px]  shadow-lg relative overflow-y-auto"
      style={{
        animation: 'slideInModal 0.5s ease-in-out forwards',
        maxHeight: '90vh', // Limita a altura pra 90% da tela
      }}
    >
      <button
        onClick={handleCloseModal}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
      >
        ✕
      </button>

      <h2 className="text-2xl font-bold mb-4">{t("MAKE ARCSEED BETTER AND EARN RPs.")}</h2>
      <p className="mb-4">
      {t("Help us make")} <span className="font-bold text-gray-500">{t("ARCSEED BETTER")}</span>, {t("Earn")} <span className="font-bold text-red-700">{t("10 RPs")}</span> {t("sharing")} <span className="font-bold text-gray-500">{t("criticism, idea, bug report or anything you want to say")}.</span><br />
       {t("Thank you so much and we hope these RPs to be useful to you!")}<br />
        {t("Please be aware that you can only get the gift")} <span className="font-bold text-red-700">{t("THREE")}</span> {t("times per month.")}<br />
        {t("Do not spam fake reports, your account can be blocked if you do, but if you have anything to say to make us improve,")} <span className="font-bold text-red-700">{t("go ahead and Thank you!")}</span>
      </p>
      <h3 className="text-lg font-semibold mb-2">{t("Choose what will you do below")}</h3>
      <ul className="mb-4">
        {['Complaints', 'Constructive Criticism', 'Bug or problem report', 'Ideas or tips', 'Other'].map(option => (
          <li key={option}>
            <button
              className={`block w-full text-left py-2 px-4 rounded ${
                selectedOption === option ? 'bg-gray-200' : 'hover:bg-gray-100'
              }`}
              onClick={() => {
                setSelectedOption(option);
              }}
            >
              {t(option)}
            </button>
                  </li>
                ))}
                <style>
                  {`
                    @keyframes fadeInBackground {
                      0% {
                        opacity: 0;
                      }
                      100% {
                        opacity: 1.0; /* Final opacity of background overlay */
                      }
                    }

                    @keyframes fadeInOverlay {
                      0% {
                        opacity: 0;
                      }
                      100% {
                        opacity: 0.7;
                      }
                    }

                    @keyframes slideInModal {
                      0% {
                        transform: scale(0.8);
                        opacity: 0;
                      }
                      100% {
                        transform: scale(1);
                        opacity: 1;
                      }
                    }
                  `}
                </style>
              </ul>

              {selectedOption && (
                <>
                  <h3 className="text-lg font-semibold mb-2">
                  {t("Choose in what area we should improve or you want to talk about.")}
                  </h3>
                  <select
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                    value={dropdownValue}
                    onChange={(e) => setDropdownValue(e.target.value)}
                  >
                    <option value="">{t("Select an area")}...</option>
                    {['Dashboard', 'Documentation', 'Render pages', 'Home page', 'Translations', 'Registration', 'Customer service', 'Render results', 'Other'].map((value) => (
                      <option key={value} value={value}>
                        {t(value)}
                      </option>
                    ))}
                  </select>
                </>
              )}

              {dropdownValue && (
                <>
                  <h3 className="text-lg font-semibold mb-2">
                  {t("How much does this issue or missing function make you dissatisfied?")}
                  </h3>
                  <input
                    type="range"
                    min="1"
                    max="10"
                    value={complaintLevel || ''}
                    onChange={(e) => setComplaintLevel(Number(e.target.value))}
                    className="w-full mb-4"
                  />
                  <p>{t("Insatisfaction Level:")} {t(complaintLevel)}</p>
                </>
              )}

              {dropdownValue && complaintLevel !== null && complaintLevel !== '' && (
                <>
                  <h3 className="text-lg font-semibold mb-2">{t("Describe in at least 100 characters your")} {t(selectedOption.toLowerCase())}</h3>
                  <textarea
                    value={complaintDescription}
                    onChange={(e) => setComplaintDescription(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                    placeholder={t(`Please describe your ${selectedOption.toLowerCase()}.`)}
                  />
                </>
              )}

              {complaintDescription.length >= 100 && (
                <button
                  onClick={handleSubmitSurvey}
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                  disabled={isSubmitting}
                >
                  {t("Submit")}
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      {showPoints && (
        <div
          className="border-transparent animate-once animate-fade-down animate-duration-700 min-h-[50px] min-w-[50px] h-[3vw] w-[3vw] max-h-[90px] max-w-[90px] rounded-[100%] ml-2 opacity-65 flex flex-col justify-center items-center text-sm bg-[#444444]"
        >
          <p className="font-title font-bold text-white text-[100%]">RP</p>
          <div className="w-[90%] h-[1px] bg-white" />
          <p className="font-title text-sm font-bold text-white text-[100%]">
            {pontos === null ? "0" : pontos}
          </p>
        </div>
      )}

      <Link className="z-29" style={styleOpacity} to={`${!verificarToken() ? "/Login" : "/Dashboard/initial"}`}>
        <IoMdPerson
          className="border-transparent animate-once animate-fade-down animate-duration-700 p-2 bg-black rounded-[100%] ml-2 opacity-70 min-h-[50px] min-w-[50px] h-[3vw] w-[3vw] max-h-[90px] max-w-[90px]"
          color="white"
        />
      </Link>

      <div style={styleOpacity}>
        <IoMdMenu
          className="border-transparent -translate-y-12 animate-once animate-fade-down animate-duration-700 p-2 bg-black rounded-[100%] ml-2 opacity-70 cursor-pointer min-h-[50px] min-w-[50px] h-[3vw] w-[3vw] max-h-[90px] max-w-[90px]"
          color="white"
          onClick={handleMenuToggle}
          style={styleOpacity}
        />
      </div>

      <div className={`menu-container relative`}>
        <div id="main-flex-col" className={`menu z-50 fixed lg:w-[50%] flex-col text-sm md:text-lg w-full lg:my-0 lg:mx-0 h-full lg:p-7 bg-white top-0 shadow-md transition-transform duration-300 ease-in-out transform ${isMenuOpen ? 'translate-x-0 right-0' : 'translate-x-full right-[-5%]'}`}>
          <div id="slide-buttons" className="">
            <div className="absolute items-center font-title z-50 left-[4vw] top-[5%] lg:top-[4vh] flex lg:left-[2vw]">
              <LanguageSelector />
            </div>

            <div className="absolute items-center w-auto z-50 right-[4vw] flex lg:right-[2vw] top-[5%] lg:top-[4vh]">
              <Link className="z-30" to="/">
                <button className="rounded-[2rem] lg:text-base hover:animate-pulse mr-4 text-[3vw] font-bold font-title text-white lg:py-2 lg:px-3 py-[2vw] px-[2vw] bg-black">
                  {t("HOME".toLowerCase())}
                </button>
              </Link>
              {!verificarToken() ? (
                <div>
                  <Link className="z-30" to="/Login">
                    <button className="rounded-[2rem] lg:text-base hover:animate-pulse text-[3vw] font-bold font-title text-white lg:py-2 lg:px-3 py-[2vw] z-50 px-[2vw] bg-black">
                      {t("LOGIN")}
                    </button>
                  </Link>
                  <Link className="z-30" to="/Register">
                    <button className="rounded-[2rem] lg:text-base hover:animate-pulse ml-4 text-[3vw] font-bold font-title text-white z-50 lg:py-2 lg:px-3 py-[2vw] px-[2vw] bg-black">
                      {t("REGISTER".toLowerCase())}
                    </button>
                  </Link>
                </div>
              ) : (
                <Link to="/" className="z-30">
                  <button
                    onClick={removeToken}
                    className="rounded-[2rem] lg:text-base hover:animate-pulse text-[3vw] font-bold font-title text-white lg:py-2 lg:px-3 py-[2vw] px-[2vw] bg-black"
                  >
                    {t("LOGOUT".toLowerCase())}
                  </button>
                </Link>
              )}
              <Link onClick={handleMenuToggle} className="cursor-pointer ml-4 hover:animate-pulse z-50">
                <AiOutlineClose className="bg-black p-[0.7vw] rounded-[100%] lg:w-10 lg:h-10 min-w-[40px] min-h-[40px] w-[7.5vw] h-[7.5vw] lg:p-2" size={10} color="white" />
              </Link>
            </div>
          </div>

          {/* MENU LATERAL REESCRITO E SIMPLIFICADO */}
          <div className={`absolute w-full h-full flex overflow-scroll md:overflow-hidden left-1/2 -translate-x-1/2 justify-center p-[20%]`}>
            <div className="w-full h-full grid md:grid-cols-2 grid-cols-1 gap-8">
              <div className="text-left">
                <h1 className="font-bold font-title sm:text-xl md:text-4xl text-2xl lg:text-3xl leading-[5vh] lg:text-4xl">{t("render")}</h1>
                <ul className="leading-[5vh] sm:text-lg md:text-2xl text-xl">
                  {Object.entries(renderSectionLinks).map(([index]) => (
                    <li className="text-[110%] lg:text-2xl font-paragraph -mt-1">
                      <div className="group relative">
                        <span
                          onClick={() => handleLinkClick(renderSectionLinks[index].link)}
                          className="text-black relative cursor-pointer"
                        >
                          {t(index)}
                          <span className="ease-in-out duration-500 absolute left-0 -bottom-[4px] bg-black h-0.5 w-0 group-hover:w-full" />
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="text-left h-fit">
              <h1 className="font-bold font-title sm:text-xl md:text-4xl text-2xl lg:text-3xl leading-[5vh] lg:text-4xl">{t("IN STUDIO")}</h1>
                <ul className="leading-[5vh] sm:text-lg md:text-2xl text-xl">
                  {Object.entries(inStudioSection).map(([index]) => (
                    <li className="text-[110%] lg:text-2xl font-paragraph -mt-1">
                      <Link to={inStudioSection[index].link} className="group text-black transition duration-300 relative">
                        {t(index)}
                        <span className="ease-in-out duration-500 absolute left-0 -bottom-[4px] bg-black h-0.5 w-0 group-hover:w-full" />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="text-left">
              <h1 className="font-bold font-title sm:text-xl md:text-4xl text-2xl lg:text-3xl leading-[5vh] lg:text-4xl">{t("PLANS")}</h1>
                <ul className="leading-[5vh] sm:text-lg md:text-2xl text-xl">
                  {Object.entries(plansSection).map(([index]) => (
                    <li className='text-[110%] lg:text-2xl font-paragraph -mt-1'>
                      <Link to={plansSection[index].link} className="group text-black transition duration-300 relative">
                        {t(index)}
                        <span className="ease-in-out duration-500 absolute left-0 -bottom-[4px] bg-black h-0.5 w-0 group-hover:w-full" />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="text-left z-30" >
              <h1 className="font-bold font-title sm:text-xl md:text-4xl text-2xl lg:text-3xl leading-[5vh] lg:text-4xl">INFO</h1>
              <ul className="leading-[5vh] sm:text-lg md:text-2xl text-xl">
                  {Object.entries(infoSection).map(([index]) => (
                    <li className='text-[110%] lg:text-2xl font-paragraph -mt-1'>
                      <Link
                        target={index === t("Documentation" ? "_isblank" : "")}
                        to={infoSection[index].link}
                        className="group text-black transition duration-300 relative"
                      >
                        {t(index)}
                        <span className="ease-in-out duration-500 absolute left-0 -bottom-[4px] bg-black h-0.5 w-0 group-hover:w-full" />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}