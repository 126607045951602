import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import NavbarButtons from "../NavbarButtons";

export default function Terms() {
  return (
    <>
      <main>
        <div className="relative pt-16 pb-32 min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://www.studiomies.com.br/galeria/news/G/121_01_vienna_garden_vila_formosa_projeto_arquitetura_residencial_apartamento_design_interiores.jpg",
            }}
          >
            <div
              id="blackOverlay"
              className="w-full h-full absolute top-0 left-0 opacity-75 bg-black"
              style={{ zIndex: 10 }}
            ></div>
          </div>
          <div id="buttons-flex" className="absolute top-0 right-0 m-4 z-50">
            <NavbarButtons />
          </div>
          <div className="container relative mx-auto z-20">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                  <h1 className="text-white font-title font-semibold text-5xl">
                    termos e condições de Uso
                  </h1>
                  <p className="font-paragraph mt-4 text-lg text-white">
                    Estes termos regem o seu uso dos nossos serviços e
                    plataformas. Ao acessar ou utilizar nossos serviços, você
                    concorda em cumprir estes termos. Aqui, detalhamos suas
                    responsabilidades, direitos e as regras que regem nossa
                    relação. Recomendamos que leia atentamente antes de
                    prosseguir. Se tiver dúvidas, entre em contato conosco.
                    Obrigado por escolher nossos serviços!
                  </p>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>
        <div className="container mx-auto mt-40">
          <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
            <div className="w-full lg:w-8/12">
              <h3 className="font-semibold font-title text-3xl text-center mb-8">
                Termos e Condições de Uso
              </h3>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Seja muito bem-vindo(a) ao nosso Site. Ele pertence à ARCSEED
                AI, pessoa jurídica de direito privado, inscrita no CNPJ sob o
                n°. 52.455.369/0001-92, com sede à Rua Dom Pedro II, n°. 763,
                Bairro Jardim Ponta Grossa – Apucarana/Paraná – CEP 86805-260.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <strong className="font-semibold">
                  Qual a finalidade do Site?
                </strong>{" "}
                Proporcionar a arquitetos, designers e profissionais da
                construção civil ferramentas inovadoras baseadas em inteligência
                artificial para transformar suas ideias criativas em projetos
                concretos de forma mais eficaz e inspiradora.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <strong className="font-semibold">Nossa Missão</strong>{" "}
                Proporcionar ferramentas de inteligência artificial avançadas
                que não apenas simplificam e agilizam o processo de design e
                visualização arquitetônica, mas também elevam a qualidade e a
                sustentabilidade de projetos, impulsionando o progresso e a
                excelência na indústria da arquitetura. Ao fazê-lo, buscamos não
                apenas atender às necessidades de nossos clientes, mas também
                contribuir para a criação de um ambiente construído mais
                inteligente, belo e ecologicamente responsável para as gerações
                futuras.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                É de extrema importância que você leia atentamente os “Termos e
                Condições de Uso” abaixo descritos, a fim de navegar em nossa
                plataforma e desfrutar de todo conteúdo disponível.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Ao acessar o Site, você concorda em cumprir esses Termos e
                Condições de Uso, bem como todas as leis locais aplicáveis. Se
                acaso discordar de qualquer dispositivo, fica expressamente
                proibido seu acesso ao Site.
              </p>

              <h4 className=" font-title font-semibold font-title text-2xl">
                Sumário
              </h4>
              <ul className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <li className="font-paragraph">
                  <strong className="font-semibold">I. </strong>Definições
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">II. </strong>Sobre o Site
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">III. </strong>Websites de
                  Terceiros
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">IV. </strong>Do Pagamento,
                  da Monetização e Publicidade
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">V. </strong>Uso de Licença e
                  Propriedade Intelectual
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">VI. </strong>Isenção e
                  Limitações de Responsabilidade
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">VII. </strong>Atendimento ao
                  Usuário
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">VIII. </strong>Proteção de
                  Dados
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">IX. </strong>Política de
                  Privacidade
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">X. </strong>Modificações
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">XI. </strong>Disposições
                  Ilegais, Inválidas ou Inexequíveis
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">XII. </strong>Do
                  descumprimento das regras expostas neste documento
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">XIII. </strong>Lei Aplicável
                </li>
              </ul>
              <div className="text-center mt-16"></div>

              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                I. Definições
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Quando as expressões abaixo indicadas são utilizadas nesses
                Termos e Condições de Uso, elas têm os seguintes significados:
              </p>
              <ul className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <li className="font-paragraph">
                  <strong className="font-semibold">a) </strong>“Conteúdo” são
                  todas as notícias, textos informativos, imagens e demais
                  publicações feitas no Site pela{" "}
                  <strong className="font-semibold">ARCSEED AI</strong>;
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">b) </strong>De acordo com a
                  definição da Lei nº 13.709 de 14 de agosto de 2018 e sua
                  atualização, “dados pessoais” são todas as informações
                  relativas a uma pessoa natural identificada ou identificável;
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">c) </strong>“
                  <strong className="font-semibold">ARCSEED AI</strong>” ou
                  “Nós” é a Empresa de pessoa jurídica de direito privado,
                  inscrita no CNPJ sob o n°. 52.455.369/0001-92, com sede à Rua
                  Dom Pedro II, n°. 763, Bairro Jardim Ponta Grossa –
                  Apucarana/Paraná – CEP 86805-260;
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">d) </strong>“USUÁRIO” ou
                  “Você” são as pessoas que de qualquer forma interagem, acessam
                  ou utilizam o Site da{" "}
                  <strong className="font-semibold">ARCSEED AI</strong>;
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">e) </strong>A “Política de
                  Privacidade” é o documento elaborado e publicado pela{" "}
                  <strong className="font-semibold">ARCSEED AI</strong> e
                  determina como processar seus dados pessoais de maneira
                  transparente;
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">f) </strong>“Termos e
                  Condições de Uso” é o presente documento, que estabelece as
                  regras e diretrizes para uso dos conteúdos da{" "}
                  <strong className="font-semibold">ARCSEED AI</strong>, além de
                  delimitar os direitos e responsabilidades dos USUÁRIOS.
                </li>
              </ul>

              <br></br>
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                II. Sobre o Site
              </h4>

              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Esse Site pode apresentar diversos materiais, entre eles
                destacam-se: textos, dados, inteligência artificial junto de
                redes neurais e imagens para a apresentação e criação de
                projetos arquitetônicos.
              </p>

              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Embora a <strong className="font-semibold">ARCSEED AI</strong>{" "}
                trabalhe incansavelmente para garantir a precisão e atualização
                das informações contidas no Site, os materiais exibidos podem
                incluir erros técnicos, tipográficos ou fotográficos. A{" "}
                <strong className="font-semibold">ARCSEED AI</strong> não
                garante que qualquer material em seu Site seja preciso, completo
                ou atual.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Assim, a <strong className="font-semibold">ARCSEED AI</strong>{" "}
                não se responsabiliza por qualquer perda indireta ou qualquer
                perda causada ou relacionada ao uso, dados ou perda de lucro
                (seja contrato, ato ou qualquer outro meio) causada pelo uso do
                Site.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Nosso Site tem como finalidade criar imagens por inteligência
                artificial para facilitar a apresentação e criação de projetos
                arquitetônicos. Este serviço é disponibilizado no Site quando o
                USUÁRIO se conecta e utiliza uma plataforma de geração de
                imagens por inteligência artificial, a partir de inputs mínimos
                ele cria imagens que são armazenadas em sua galeria pessoal.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Ao realizar o cadastro na plataforma, você pode optar por um
                plano grátis ou um plano pago. No plano grátis, a experiência do
                USUÁRIO acontece de modo limitado, não sendo possível obter
                todos os benefícios oferecidos pela{" "}
                <strong className="font-semibold">ARCSEED AI</strong>, já no
                plano pago você consegue desfrutar da melhor experiência
                disponível na plataforma.
              </p>

              <br></br>
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                III. WebSite de Terceiros
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                A <strong className="font-semibold">ARCSEED AI</strong> poderá
                fornecer links que levam o USUÁRIO a outros Sites ou serviços da
                Internet. No entanto, não temos controles sobre eventuais Sites
                ou serviços, uma vez que pertencem a terceiros e, portanto, não
                nos responsabilizamos por qualquer conteúdo neles
                disponibilizado. A inclusão de qualquer link não implica endosso
                da <strong className="font-semibold">ARCSEED AI</strong> de seu
                conteúdo.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                O USUÁRIO expressamente reconhece que a{" "}
                <strong className="font-semibold">ARCSEED AI</strong> não será
                responsável, direta ou indiretamente, por quaisquer perdas e
                danos que sejam efetiva ou alegadamente causados por, ou em
                conexão, pela confiança depositada em tal conteúdo disponível a
                partir de Sites de terceiros.
              </p>

              <br></br>
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                IV. Do Pagamento, da Monetização e Publicidade
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Os pagamentos referentes ao plano pago oferecido pela{" "}
                <strong className="font-semibold">ARCSEED AI</strong> são
                realizados por meio do software “Stripe”, dessa forma existem
                diversas formas de pagamento disponíveis para os USUÁRIOS, como:
                Pix, crédito, débito e boleto bancário.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                A <strong className="font-semibold">ARCSEED AI</strong> pode
                vender ou alugar espaços publicitários em seu Site. Nós não
                possuímos nenhuma responsabilidade acerca destas publicidades,
                sendo o USUÁRIO o único responsável por ações referentes às
                empresas anunciantes na plataforma.
              </p>

              <br></br>
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                V. Uso de Licença e Propriedade Intelectual
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                A <strong className="font-semibold">ARCSEED AI</strong> é
                detentora dos direitos autorais de toda a informação disponível
                no Site, assim como de todos os direitos de Propriedade
                Intelectual e direitos conexos. Salvo as imagens geradas por
                inteligência artificial, visto que tais imagens são denominadas
                como domínio público, logo o USUÁRIO pode utilizá-las para fins
                relacionados à criação de vídeos, posts em redes sociais,
                marketing, entre outras possibilidades.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Exceto se você tiver a permissão expressa e por escrito da{" "}
                <strong className="font-semibold">ARCSEED AI</strong>, você não
                deverá utilizar o nome, marca ou logotipo pertencente a esta.
              </p>

              <br></br>
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                VI. Isenção e Limitações de Responsabilidade
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                A <strong className="font-semibold">ARCSEED AI</strong> não
                garante que este site funcionará livre de perdas, erros,
                defeitos, ataques, vírus, trojans, malwares, worms, bots,
                backdoors, spywares, rootkit, interferências, atividades de
                hackers ou falhas de segurança, e você expressamente renuncia a
                qualquer direito que possa ter nesse sentido.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Outrossim, não somos nem seremos responsáveis:
              </p>
              <ul className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <li className="font-paragraph">
                  <strong className="font-semibold">a) </strong>Pela qualidade
                  da conexão do computador e/ou dispositivo móvel do USUÁRIO à
                  internet em qualquer circunstância;
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">b) </strong>Pela
                  disponibilidade ou não dos serviços de acesso à Internet
                  prestados por terceiros;
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">c) </strong>Pela
                  impossibilidade de utilização de quaisquer informações acima
                  mencionadas em razão da incompatibilidade da configuração
                  técnica do computador e/ou dispositivo móvel do USUÁRIO, por
                  quaisquer danos sofridos pelo USUÁRIO em razão da utilização
                  do aplicativo;
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">d) </strong>Pela reparação
                  de danos de qualquer natureza causados pela quebra de
                  privacidade ou segurança de dados dos USUÁRIOS durante a
                  conexão de seu dispositivo móvel à Internet através de
                  terceiros;
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">e) </strong>Pela qualidade
                  final das imagens geradas, dado a aleatoriedade do
                  funcionamento das inteligências artificiais;
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">f) </strong>Pela garantia de
                  que as ferramentas funcionarão para toda a gama de
                  possibilidades de gerações de imagens; e
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">g) </strong>Nós não
                  concedemos os direitos autorais aos USUÁRIOS, visto que todas
                  as imagens geradas no site são de domínio público.
                </li>
              </ul>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                A<strong className="font-semibold"> ARCSEED AI</strong> se
                responsabiliza em fornecer um servidor funcional e um suporte ao
                USUÁRIO.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                O USUÁRIO tem a responsabilidade de agir de maneira respeitosa
                dentro da comunidade, portanto é proibida a criação de imagens
                com conteúdo NSFW e de preconceito etário, religioso, sexual, de
                gênero, racial, social, xenofóbico, capacitista, entre outros.
                Caso tais imagens sejam compartilhadas na galeria pública do
                Site, o USUÁRIO será banido. Caso tais imagens desrespeitosas
                vierem a ofender outrem e o USUÁRIO ofendido decidir tomar ações
                legais, a <strong className="font-semibold">ARCSEED AI</strong>{" "}
                se compromete a auxiliar no processo com as informações
                necessárias. Ademais, a{" "}
                <strong className="font-semibold">ARCSEED AI</strong> não possui
                responsabilidade pelo modo que o USUÁRIO utiliza a plataforma,
                dessa forma nos isentamos de qualquer responsabilidade,
                inclusive civil e criminal, sendo a responsabilidade de
                utilização única do USUÁRIO.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                O USUÁRIO não possui o direito de tomar proveito de brechas ou
                fragilidades da plataforma para benefício próprio, do mesmo modo
                que o USUÁRIO não deve acessar áreas do site que não são
                liberadas para a sua conta.
              </p>

              <br></br>
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                VII. Atendimento ao Usuário
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Caso o USUÁRIO tenha qualquer dúvida, dica ou sugestão e queira
                entrar em contato com nosso suporte, deverá acessar a aba
                “Contato” e preencher as informações abaixo indicadas:
              </p>
              <ul className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <li className="font-paragraph">
                  <strong className="font-semibold">a) </strong>Nome;
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">b) </strong>Telefone;
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">c) </strong>E-mail para
                  contato;
                </li>
                <li className="font-paragraph">
                  <strong className="font-semibold">d) </strong>Mensagem.
                </li>
              </ul>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Além disso, o USUÁRIO poderá entrar em contato com nossa equipe
                pelo endereço de{" "}
                <strong className="font-semibold">e-mail</strong> (contato@arcseedai.com.br) ou
                endereço telefone +55 34 9842-8688, por meio do aplicativo
                WhatsApp.
              </p>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                A <strong className="font-semibold">ARCSEED AI</strong>{" "}
                compromete-se a responder a quaisquer dúvidas ou reclamações
                apresentadas pelos USUÁRIOS em um prazo máximo de 30 (trinta)
                dias a partir do recebimento da correspondência. Esta resposta
                será fornecida de forma clara e objetiva, visando solucionar da
                melhor maneira possível as questões levantadas. Ressaltamos
                nosso empenho em garantir a satisfação e a transparência no
                relacionamento com nossos USUÁRIOS.
                <br />
                <br />
                Caso deseje, o USUÁRIO poderá solicitar seu descadastramento do
                Site a qualquer tempo, sem quaisquer ônus.
              </p>

              <br></br>
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                VIII. Proteção de Dados
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Estamos empenhados em salvaguardar a sua privacidade ao utilizar
                nosso Site. Os Termos de Condições de Uso aqui descritos têm a
                finalidade de deixar o mais claro possível a nossa política de
                coleta e compartilhamento de dados, informando sobre os dados e
                como os utilizamos. Todos os dados coletados estão em
                conformidade com a Lei Geral de Proteção de Dados Pessoais de
                2018, sendo a legislação brasileira que regula as atividades de
                tratamento de dados pessoais. Para maiores informações sobre
                como utilizamos, coletamos e compartilhamos dados pessoais, por
                favor, acesse nossa{" "}
                <Link
                  to="/Privacity"
                  className="font-semibold"
                  style={{ textDecoration: "underline" }}
                >
                  Política de Privacidade
                </Link>
                .
              </p>

              <br></br>
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                IX. Política de Privacidade
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                <Link
                  to="/Privacity"
                  className="font-semibold"
                  style={{ textDecoration: "underline" }}
                >
                  Clique aqui
                </Link>{" "}
                para ler a Política de Privacidade da{" "}
                <strong className="font-semibold">ARCSEED AI</strong>.
              </p>

              <br></br>
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                X. Modificações
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Podemos revisar e alterar esses “Termos e Condições de Uso” da
                plataforma a qualquer momento, sem aviso prévio. Nesse caso, se
                forem realizadas alterações, iremos notificá-lo via e-mail,
                detalhando as mudanças ocorridas. Ao utilizar esse Site, você
                concorda em permanecer vinculado à versão atual dos Termos.
              </p>

              <br></br>
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                XI. Disposições Ilegais, Inválidas ou Inexequíveis
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Se estes Termos e Condições de Uso ou qualquer parte deles forem
                considerados ilegais, inválidos ou inexequíveis sob as leis de
                qualquer estado ou país em que estes Termos de Uso se destinem a
                ser efetivos, então, na medida em que as disposições sejam
                ilegais, inválidas ou inexequíveis, tais disposições devem ser
                tratadas como apartadas e excluídas destes Termos de Uso e as
                demais disposições devem ser mantidas, permanecer em pleno vigor
                e efeito e continuar a ser vinculativa e exequível nesse estado
                ou país.
              </p>

              <br></br>
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                XII. Do descumprimento das regras expostas neste documento
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Qualquer USUÁRIO que pratique qualquer ato que desrespeite ou
                atente alguma cláusula ou obrigação explicitada neste termos e
                condições de Uso está sujeito a ser penalizado, bem como ser
                banido indefinidamente do site.
              </p>

              <br></br>
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                XIII. Lei Aplicável
              </h4>
              <p className="font-paragraph text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Por fim, esses termos e condições são regidos e interpretados de
                acordo com as leis brasileiras, portanto, o USUÁRIO se submete
                irrevogavelmente à jurisdição dos tribunais brasileiros.
                <br></br>
                Para dirimir quaisquer controvérsias oriundas do presente
                contrato de tempo indeterminado, as partes elegem o foro de
                (XXXXXXX).
              </p>

              <br></br>
              <h4 className=" font-title font-semibold text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Última atualização em: 12/02/2024.
              </h4>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}
