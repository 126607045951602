// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContainerCard_container__8nCLb {\n    position: relative;\n    width: 100%;\n    max-width: 1600px;\n}", "",{"version":3,"sources":["webpack://./src/components/Gallery/Components/ContainerCard/ContainerCard.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".container {\n    position: relative;\n    width: 100%;\n    max-width: 1600px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ContainerCard_container__8nCLb"
};
export default ___CSS_LOADER_EXPORT___;
