// TestingGround.js
import React from 'react';
import MorphingImageCanvas from './MorphingImageCanvas';
import MovingImageBgen from './MorphingImageBgen';
import MorphingImageSkt2render from './MorphingImageSkt2render';
import MorphingImageRedecorate from './MorphingImageRedecorate';
import MorphingImagesRefining from './MorphingImagesRefining';



const TestingGround = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Testing Ground for canvas</h1>
      <MorphingImageCanvas />
      <h1>Testing Ground for bgen</h1>
      <MorphingImagesRefining />
      <h1>Testing Ground for bgen</h1>
      <MorphingImageRedecorate />
      <h1>Testing Ground for skt2render</h1>
      <MorphingImageSkt2render />
      <h1>Testing Ground for bgen</h1>
      <MovingImageBgen />
    </div>

    
    
  );
};

export default TestingGround;
