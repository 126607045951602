
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";
import { Trans } from 'react-i18next';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-scroll';
import BrainstormGen from './imgAnimations/MorphingImageBgen.js';
import CanvasAlchemy from './imgAnimations/MorphingImageCanvas.js';
import Redecor from './imgAnimations/MorphingImageRedecorate.jsx';
import Rerender from './imgAnimations/MorphingImagesRefining.jsx';
import Sketch2Ren from './imgAnimations/MorphingImageSkt2render.jsx';

function RenderSlides() {
  const [currentSlideId, setCurrentSlideId] = useState("BrainstormGen");
  const slideNames = ["BrainstormGen", "Sketch2Render", "CanvasAlchemy", "ReRender", "Decorate", "FloorPlanner"];
  const [numberSlideId, setNumberSlideId] = useState(0);
  useEffect(() => {
    setCurrentSlideId(slideNames.at(numberSlideId));
    console.log(slideNames.at(numberSlideId));
  }, [numberSlideId]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const verificarToken = () => {
    const token = Cookies.get('token');
    if (!token) {
      return false;
    }
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp >= currentTime;
    } catch (error) {
      return false;
    }
  };

  const handleButtonClick = () => {
    if (!verificarToken()) {
      navigate('/Login');
    } else {
      navigate(`/${currentSlideId}`);
    }
  };

  const slidesData = {
    BrainstormGen: {
      imageUrl: (
        <div className='w-full h-full'>
            <BrainstormGen/> 
        </div>
    )
      ,
      title: "BRAINSTORM GEN",
      paragraph1: (
        <Trans i18nKey="GENERATE INFINITE VARIATIONS OF IMAGES CHOOSING ALL POSSIBLE ARCHITECTURE STYLES OR INTERIOR DESIGNS WITH AN EASY TO USE INTERFACE IN SECONDS.">
          GENERATE 
          <b className='font-title'> INFINITE </b> 
          VARIATIONS OF IMAGES CHOOSING ALL POSSIBLE ARCHITECTURE STYLES OR INTERIOR DESIGNS WITH AN EASY TO USE INTERFACE IN
          <b className='lg:text-[120%] font-title'> SECONDS</b>.
        </Trans>
      ),
      paragraph2: (
        <Trans i18nKey="USE IT TO PRESENT IDEAS OF PROJECTS AT THE BEGINNING OF THE CREATIVE PROCESS, AND ALL THAT CRAFTED BY YOUR IMAGINATION">
          USE IT TO PRESENT IDEAS OF PROJECTS AT THE BEGINNING OF THE PROCESS, AND ALL THAT CRAFTED BY 
          <b className='lg:text-[120%] font-title'> YOUR IMAGINATION </b>
          .
        </Trans>
      ),
      buttonText: t("START NOW FOR FREE"),
      buttonColor: `#ebffb3`,
    },
     Sketch2Render: {
      imageUrl: (
        <div className='w-full h-full'>
            <Sketch2Ren/> 
        </div>
    ),
       title: "SKETCH 2 RENDER",
       paragraph1: (
         <Trans i18nKey="WITH A SIMPLE PAPER SKETCH OF AN IDEA OR SCREENSHOT OF YOUR FAVORITE 3D SOFTWARE CREATE PHOTOREALISTIC RENDERINGS WITHIN SECONDS.">
           WITH A SIMPLE PAPER 
           <b className='lg:text-[120%] font-title'> SKETCH </b> 
           OF AN IDEA OR A
           <b className='lg:text-[120%] font-title'> SCREENSHOT </b> 
           OF YOUR FAVORITE 3D SOFTWARE CREATE PHOTOREALISTIC RENDERINGS WITHIN SECONDS.
         </Trans>
       ),
       paragraph2: (
         <Trans i18nKey="USE OUR TECHNOLOGY TO PRESENT EARLY IDEAS FOR YOUR CLIENTS, MAKE MOODBOARDS OR RENDER SEVERAL POSSIBILITIES OF THE SAME PROJECT AND GET TO THE FINAL VERSION OF THE PROJECT UP TO 70% FASTER.">
           USE OUR TECHNOLOGY TO PRESENT EARLY IDEAS FOR YOUR CLIENTS, MAKE MOODBOARDS OR RENDER SEVERAL POSSIBILITIES OF THE SAME PROJECT AND GET TO THE FINAL VERSION OF THE PROJECT UP TO
           <b className='lg:text-[120%] font-title'> 70% FASTER </b>.
         </Trans>
       ),
       buttonText: t("START NOW FOR FREE"),
       buttonColor: `#ffad7ed7`,
     },
     CanvasAlchemy: {
      imageUrl: (
        <div className='w-full h-full'>
            <CanvasAlchemy/> 
        </div>
    ),
       title: "CANVAS ALCHEMY",
       paragraph1: (
         <Trans i18nKey="CANVAS ALCHEMY ALLOWS YOU TO COMPLETELY CHANGE A RENDERING, PHOTOGRAPH OR SKETCH WHILE KEEPING ITS ESSENCE INTACT TO TURN IT INTO A MASTERPIECE BEAUTIFUL ENOUGH TO BE ON LOUVRE.">
           CANVAS ALCHEMY ALLOWS YOU TO COMPLETELY CHANGE A RENDERING, PHOTOGRAPH OR SKETCH AND TURN IT INTO A
           <b className='lg:text-[120%] font-title'> MASTERPIECE </b>
           BEAUTIFUL ENOUGH TO BE ON LOUVRE.
         </Trans>
       ),
       paragraph2: (
         <Trans i18nKey="THE PROCESS IS PERFECT IF YOU WANT TO CREATE A UNIQUE AND COMPLETE NEW LOOK, EXPLORE DIFFERENT STYLES OR CREATE THE MOST AMAZING SOCIAL MEDIA MATERIAL, THE BEST IS THAT YOU CAN CREATE NEW DIFFERENT IMAGES WITHOUT SPENDING HOURS IN RENDERINGS.">
           THE PROCESS IS PERFECT IF YOU WANT TO CREATE A COMPLETE
           <b className='lg:text-[120%] font-title'> NEW LOOK </b>
           , EXPLORE DIFFERENT STYLES OR CREATE THE MOST AMAZING
           <b className='lg:text-[120%] font-title'> SOCIAL MEDIA </b> 
           MATERIAL. THE BEST IS THAT YOU CAN CREATE NEW DIFFERENT IMAGES WITHOUT SPENDING HOURS IN RENDERINGS.
         </Trans>
       ),
       buttonText: t("START NOW FOR FREE"),
       buttonColor: `#f88cefa4`,
     },
     ReRender: {
      imageUrl: (
        <div className='w-full h-full'>
            <Rerender/> 
        </div>
    ),
       title: "RE-RENDER",
       paragraph1: (
         <Trans i18nKey="UTILIZE OUR MOST ADVANCED NEURAL NETWORKS TO DECORATE INTERIOR SPACES WITH A FEW CLICKS AND A SINGLE REFERENCE IMAGE.">
           UTILIZE OUR MOST ADVANCED 
           <b className='lg:text-[120%] font-title'> NEURAL NETWORKS </b>
           TO DECORATE INTERIOR SPACES WITH A FEW CLICKS AND A SINGLE REFERENCE IMAGE.
         </Trans>
       ),
       paragraph2: (
         <Trans i18nKey="IF YOU HAVE AMAZING PROJECTS BUT THE RENDERINGS WERE NOT GREAT, YOU CAN GIVE THEM A NEW TOUCH WITH THIS TOOL, YOU COULD ALSO USE IT ON REAL PHOTOGRAPHS AND THE AI WILL MAKE IT LOOK INCREDIBLE.">
           IF YOU HAVE AMAZING PROJECTS BUT THE RENDERINGS WERE NOT GREAT, YOU CAN GIVE THEM A NEW TOUCH WITH THIS TOOL, YOU COULD ALSO USE IT ON REAL PHOTOGRAPHS AND THE AI WILL MAKE IT LOOK
           <b className='lg:text-[120%] font-title'> INCREDIBLE </b>.
         </Trans>
       ),
       buttonText: t("START NOW FOR FREE"),
       buttonColor: `#8b7ffa9f`,
     },
     Decorate: {
      imageUrl: (
        <div className='w-full h-full'>
            <Redecor/> 
        </div>
    ),
       title: "DECORATE",
       paragraph1: (
         <Trans i18nKey="UTILIZE OUR MOST ADVANCED NEURAL NETWORKS TO DECORATE INTERIOR SPACES WITH A FEW CLICKS AND A SINGLE REFERENCE IMAGE.">
           UTILIZE OUR MOST ADVANCED 
           <b className='lg:text-[120%] font-title'> NEURAL NETWORKS </b>
           TO DECORATE INTERIOR SPACES WITH A FEW CLICKS AND A SINGLE REFERENCE IMAGE.
         </Trans>
       ),
       paragraph2: (
         <Trans i18nKey="THERE ARE NO LIMITS TO THE POSSIBILITIES, CHANGE COLORS, STYLES AND LOOKS, WHAT WOULD TAKE HOURS NOW CAN TAKE LITERALLY SECONDS.">
           THERE ARE 
           <b className='lg:text-[120%] font-title'> NO LIMITS </b>
           TO THE POSSIBILITIES, CHANGE COLORS, STYLES AND LOOKS, WHAT WOULD TAKE HOURS NOW CAN TAKE LITERALLY
           <b className='lg:text-[120%] font-title'> SECONDS </b>.
         </Trans>
       ),
       buttonText: t("START NOW FOR FREE"),
       buttonColor: `#ffb3b3`,
     },
  };
  
  const [animationFadeIn, setAnimationFadeIn] = useState(true);
  const [buttonLock, setButtonLock] = useState(false);

  const handleNextPageButton = (argButton) => {
    // evita spam de cliques no botão e evita a animação de ficar zoada
    if(buttonLock) {
      return;
    }
    setButtonLock(true);
    setAnimationFadeIn(false);
    const timer = setTimeout(() => {
      setAnimationFadeIn(true);
      if(argButton === "next") {
        setNumberSlideId(numberSlideId >= 4 ? 0 : numberSlideId + 1);
      }
      else if(argButton === "previous") {
        setNumberSlideId(numberSlideId <= 0 ? 4 : numberSlideId - 1);
      }
      setTimeout(() => {
        setButtonLock(false);
      }, 1300);
    }, 500);
    return () => clearTimeout(timer);
  }

  return (
    <div className={`flex w-screen lg:px-0 overflow-hidden h-screen max-h-screen relative`}>
      <div className="flex flex-col md:flex-row w-screen max-w-screen items-center">
        <div className="flex flex-col p-[5%] md:px-[8vw] items-center md:h-screen h-full max-h-screen lg:w-1/2 justify-between md:py-[10vh]">
          <div className='text-center lg:text-right'>
            <h1 className="font-title md:text-[200%] text-[120%] lg:text-6xl p-1">{t("SOLUTIONS")}:</h1>
            <h3 className={`font-title`}>
              <b
            className={`md:text-[200%] text-[130%] font-extrabold ${animationFadeIn ? "animate-fade-in-with-delay" : "animate-fade-out"}`}>{slidesData[currentSlideId]?.title}</b></h3>
          </div>
          <div 
          className={`w-[90%] h-[40%] object-cover overflow-hidden md:hidden
            ${ animationFadeIn ? "animate-fade-in-with-translate" : "animate-fade-out-with-translate" }`}>
          {slidesData[currentSlideId]?.imageUrl}
          </div>
          <div className={`flex flex-col md:justify-center md:items-center ${animationFadeIn ? "animate-fade-in-with-blur" : "animate-fade-out" } md:overflow-auto overflow-y-scroll md:h-full h-[30%] custom-scrollbar md:p-0 px-[4%]`}>
            <p className="font-paragraph mb-6 text-justify items-center leading-7 md:text-[120%] text-[100%]">{slidesData[currentSlideId]?.paragraph1}</p>
            <p className="font-paragraph text-justify md:text-[120%] text-[100%] leading-7">{slidesData[currentSlideId]?.paragraph2}</p>
          </div>
          <button
          style={{
            backgroundColor: slidesData[currentSlideId]?.buttonColor
          }}
            className={`font-title mb-0 md:mb-[3.5vh] px-4 py-2 text-black font-bold rounded-full transition-all 
              ease-in-out duration-300 hover:scale-105`}
            onClick={handleButtonClick}
          >
            {slidesData[currentSlideId]?.buttonText}
          </button>
        </div>
        <div className='w-[0.06rem] hidden lg:inline bg-black h-[70vh]' />
        <div className="w-1/2 hidden md:flex flex-col h-full px-[3%] justify-center items-center">
          <div 
          className={`size-100
            ${ animationFadeIn ? "animate-fade-in-with-translate" : "animate-fade-out-with-translate" }`}>
          {slidesData[currentSlideId]?.imageUrl}
          </div>
        </div>
        <div className='flex md:absolute bottom-2 w-full justify-between'>
          <FaArrowLeft 
          size={55} 
          color="black" 
          className="cursor-pointer ml-2" 
          onClick={() => handleNextPageButton("previous")}/>
            <Link
            to={"default-footer"}
            smooth={true}
          offset={0}
          duration={750}>
              <FaArrowLeft 
              size={55} 
              color="black" 
              className="cursor-pointer -rotate-90"/> 
            </Link>
      <FaArrowRight
        size={55}
        color="black"
        className="cursor-pointer mr-2"
        onClick={() => handleNextPageButton("next")}
      />
        </div>
      </div>
    </div>
  );
}

export default RenderSlides;