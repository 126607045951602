import { useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';
import { useTranslation } from 'react-i18next';
import InfoBox from '../InfoBox';

function Visibility({ updateUserChoices }) {
  const [visibility, setVisibility] = useState('PUBLIC');
  const { t } = useTranslation();

  const handleUpdateVisibility = (visibility) => {
    setVisibility(visibility);
    updateUserChoices("visibility", visibility);
  };

  useEffect(() => {
    updateUserChoices("visibility", visibility);
  }, [])

  return (
    <div className='bg-gray-200 py-4'>
      <div className='flex justify-center items-center'>
        <h1 className='flex mb-4 justify-center items-center font-title font-bold text-2xl'>
          {t("VISIBILITY")}
          <div className='w-[1px] h-[1px] relative'>
            <InfoBox
              text={t("Choose between Private or Public images, the Private images will only appear in your personal gallery while the Public images will be available on the community Gallery to everyone to see.")}
              customClass={"absolute top-1/2 -translate-y-1/2"} />
          </div>
        </h1>
      </div>
      <div className="flex flex-col sm:flex-row justify-center items-center">
        <button
          className={`lg:w-[33vw] w-full font-title font-bold lg:mx-2 py-2 my-2 sm:my-0 mr-0 sm:mr-3 font-large 
            ${visibility === 'PRIVATE'
              ? 'bg-[#6ce9ff] text-black border-2 border-black'
              : 'bg-[#b3f1fc] text-black'
            } hover:bg-[#6ce9ff] hover:text-black transition-colors duration-300`}
          onClick={() => handleUpdateVisibility('PRIVATE')}
        >
          {t("PRIVATE")}
        </button>
        <button
          className={`lg:w-[33vw] w-full font-title font-bold lg:mx-2 py-2 my-2 sm:my-0 mr-0 sm:mr-3 font-large 
            ${visibility === 'PUBLIC'
              ? 'bg-[#4affb4] text-black border-2 border-black'
              : 'bg-[#bcf8df] text-black'
            } hover:bg-[#4affb4] hover:text-black transition-colors duration-300`}
          onClick={() => handleUpdateVisibility('PUBLIC')}
        >
          {t("PUBLIC")}
        </button>
      </div>
    </div>
  );
}

export default Visibility;
